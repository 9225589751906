<form #disclaimersForm="ngForm" class="editForm" novalidate>
    <div class="form-horizontal">
        <div style="padding: 15px 15px 25px; border: 1px solid #bbb;">
            <h4 class="head text-center">{{"DISCLAIMERS" | translate}}</h4>
            <br />
            <div style="margin-left: 20px; padding: 0px;">
                <label class="disclaimer-heading">{{ "DISC_DESC" | translate }}</label>
                <ol>
                    <li>{{ "DISC_FIN_1" | translate}}</li>
                    <li>{{ "DISC_FIN_2" | translate}}</li>
                    <li>{{ "DISC_FIN_3" | translate}}</li>
                </ol>
                <div class="form-group" style="margin: 0px;">
                    <div class="checkbox disclaimer-checkbox-parent">
                        <label class="form-group-checkbox">
                            <input class="desclaimer-checkbox" id="onOwnBehalf" (change)="checkDisclaimers()" type="checkbox"
                                #onOwnBehalf="ngModel" required name="onOwnBehalf"
                                [(ngModel)]="disclaimers.onOwnBehalf" value="onOwnBehalf">
                            <ng-container *ngIf="!customOnboarding"><div [innerHTML]="disclaimerWithLinks"></div></ng-container>
                            <ng-container *ngIf="customOnboarding"> {{ "DISCLAIMERS_MAIN" | translate}}
                            </ng-container>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<style>

.disclaimer-checkbox-parent {
    position: relative;
}

.desclaimer-checkbox {
    right: unset;
    left: 0px;
    margin-left: 0px !important;
    width: fit-content !important;
}

.form-group-checkbox {
    display: flex;
    align-items: center;
}

.form-group-checkbox > input {
    position: relative;
    margin-left: 0px;
    margin-top: 0px;
    width: fit-content;
}

.form-group-checkbox > div {
    margin-left: 6px;
}

.form-horizontal > .tab-pane > .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

@media (min-width: 768px){
    .checkbox {
        width: fit-content;
        margin: 0 auto;
    }
    ol {
        width: fit-content;
        margin: 0 auto;
    }
    .disclaimer-heading {
        display: flex;
        justify-content: center;
    }
}
</style>
