import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PiechartComponent } from './piechart.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as organization from 'highcharts/modules/organization.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';


@NgModule({
  declarations: [
    PiechartComponent
  ],
  imports: [
    CommonModule,
    ChartModule
  ],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        networkgraph,
        sankey,
        organization,
        exporting,
        serieslabel,
      ],
    },
  ],
  exports: [
    PiechartComponent
  ]
})
export class PieChartModule { }
