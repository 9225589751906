<form #createAdminForm="ngForm" class="editForm" novalidate *ngIf="brandingSetup">
    <h3 class="text-center">Customise branding</h3><br>
    <div class="form-group">
        <label class="control-label" for="email"><b>Company Name:</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.companyName" name="companyName">
    </div>
    <div class="form-group">
        <label class="control-label" for="email"><b>Company Website URL:</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.companyUrl" name="companyUrl">
    </div>
    <div class="form-group">
        <label class="control-label" for="email"><b>LinkedIn URL:</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.linkedinUrl" name="linkedinUrl">
    </div>
    <div class="form-group">
        <label class="control-label" for="mainColor"><b>Main Color:</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.mainColor"
            [(colorPicker)]="brandingSetup.mainColor" name="mainColor">
    </div>
    <div class="form-group">
        <label class="control-label" for="email"><b>Report Logo</b></label>
        <input class="control-label" type="file" name="file" accept="image/*"
            (change)="handleInputChangeReport($event)">
    </div>
    <div class="form-group">
        <label class="control-label" for="supportEmail"><b>Support Email</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.supportEmail" name="supportEmail">
    </div>
    <div class="form-group">
        <label class="control-label" for="telegramUrl"><b>Telegram URL</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.telegramUrl" name="telegramUrl">
    </div>
    <div class="form-group">
        <label class="control-label" for="twitterUrl"><b>Twitter URL</b></label>
        <input class="form-control input-md" [(ngModel)]="brandingSetup.twitterUrl" name="twitterUrl">
    </div>
    <div class="form-group">
        <label class="control-label" for="file"><b>Website Logo</b></label>
        <input class="control-label" type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-primary" [disabled]="!createAdminForm.valid"
            [style.background-color]="mainColor" (click)="updateBranding()"> Update Branding </button>
    </div>
</form>
