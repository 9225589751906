import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WebCallsService, WebDataService } from '../../../_services/index';
import { CreditAlertComponent } from '../creditAlert/creditAlert.component';
import { CommentComponent } from './comment/comment.component';
import { ControlsComponent } from './controls';
import { FullSearchRiskComponent } from './fullSearchRisk';
import { InformationComponent } from './information';
import { NewSearchComponent } from './newSearch/newSearch.component';
import { ReportComponent } from './report';
import { ReportPreviewComponent } from './report-preview';
import { ResultsComponent } from './results';
import { RiskComponent } from './risk';
import { RiskAssessmentComponent } from './risk-assessment/risk-assessment.component';
import { RiskLevelComponent } from './risk-level/risk-level.component';

@Component({
  standalone: true,
  imports: [
    CommentComponent,
    ControlsComponent,
    FullSearchRiskComponent,
    InformationComponent,
    NewSearchComponent,
    ReportComponent,
    ReportPreviewComponent,
    ResultsComponent,
    RiskComponent,
    RiskAssessmentComponent,
    RiskLevelComponent
  ],
  templateUrl: 'fullReport.component.html',
  styleUrls: [ './fullReport.component.css' ]
})

export class FullReportComponent implements OnInit {

  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  encodedQuery = '';
  searchQuery = '';
  count = 0;
  results: any = {};
  isFullSearch = false;
  step: any;
  credits: any;
  bsModalRef: BsModalRef;

  constructor (
    private modalService: BsModalService,
    private webCallsService: WebCallsService,
    private webDataService: WebDataService,
  ) {}

  ngOnInit() {
    this.webCallsService.getCreditsForUser().subscribe(
      data => {
        this.credits = data;
        this.credits = this.credits.credits;

        if (this.credits <= 20) {
          this.webCallsService.addToAutopilotJourney(localStorage.getItem('username'), '0014').subscribe(
            error => {}
          );
          this.showAlert(this.credits);
        }
      },
      error => {}
    );

    this.webDataService.isFullSearch.subscribe(
      message => this.isFullSearch = message
    );
  }

  showAlert(credits: any) {
    const initialState = {
      credits: credits,
      class: 'modal-custom'
    };

    this.bsModalRef = this.modalService.show(CreditAlertComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
