<div class="col-md-12">
    <div style="height: 20px"></div>
    <button class="bbtn btn-success btn-add-new" (click)="addNew(addTemplate)"
        style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus"
            aria-hidden="true"></i></button>
    <div style="height: 15px"></div>
    <table class="table-documents table table-responsive table-hover" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Type</th>
                <th>Allowed mime types</th>
                <th>Description</th>
                <th>Expiration</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let documentType of documentTypes">
                <td>{{documentType.documentTypeName}}</td>
                <td>
                    <ul style="padding-left: 0px; display: flex; width: fit-content; margin: 0 auto;">
                        <li style="display: flex; align-items: center; width: fit-content;" *ngFor="let individualMimeType of (documentType.allowableMimeTypes | mimeTypePipe); let i = index" [ngStyle]="{'margin-left': i === 0 ? '0px': '20px'}">
                                <img *ngIf="individualMimeType === 'image/png' || individualMimeType === 'PNG'" src="assets/images/icons/Document_Tab_Mime_Icons/PNG.png" alt="image placeholder" title="PNG" style="width: 25px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'image/jpeg' || individualMimeType === 'JPEG'" src="assets/images/icons/Document_Tab_Mime_Icons/JPG.png" alt="image placeholder" title="JPG" style="width: 25px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'custom/form' || individualMimeType === 'FORM'" src="assets/images/icons/Document_Tab_Mime_Icons/FORM.png" alt="form placeholder" title="FORM" style="width: 23px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'application/pdf' || individualMimeType === 'PDF'" src="assets/images/icons/Document_Tab_Mime_Icons/PDF.png" alt="pdf placeholder" title="PDF" style="width: 25px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'video/mpeg' || individualMimeType === 'MPEG'" src="assets/images/icons/Document_Tab_Mime_Icons/MPG.png" alt="video placeholder" title="MPEG" style="width: 25px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'video/webm' || individualMimeType === 'WEBM'" src="assets/images/icons/Document_Tab_Mime_Icons/WEBM.png" alt="video placeholder" title="WEBM" style="width: 25px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'text/plain' || individualMimeType === 'TEXT'" src="assets/images/icons/Document_Tab_Mime_Icons/TEXT.png" alt="text placeholder" title="PLAIN TEXT" style="width: 28px; object-fit: contain;">
                                <img *ngIf="individualMimeType === 'application/json' || individualMimeType === 'JSON'" src="assets/images/icons/Document_Tab_Mime_Icons/JSON.png" alt="text placeholder" title="JSON" style="width: 25px; object-fit: contain;">
                        </li>
                    </ul>
                </td>
                <td>{{documentType.description}}</td>
                <td *ngIf="documentType.documentExpiration == null">NOT SET</td>
                <td *ngIf="documentType.documentExpiration != null">
                    {{documentType.documentExpiration | secondsToDays}} days - {{documentType.expirationType}}</td>
                <td><button class="btn btn-warning btn-yellow" style="padding: 6px;" (click)="edit(editTemplate,documentType)">Edit
                        &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Document Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label> Name: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.documentTypeName" />
        <div class="alert alert-danger" id="validationName" [hidden]="currentDocumentType.documentTypeName">Name is required</div>
        <div style="height: 10px;"></div>
        <label> Allowable MIME types: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-multiselect-dropdown [placeholder]="'Select types'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
        <div class="alert alert-danger" id="validationMimeType" [hidden]="selectedItems.length">MIME types are required</div>
        <div style="height: 10px;"></div>
        <label> Format: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-select class="custom" [items]="formats" [(ngModel)]="currentDocumentType.docForm" style="flex-wrap: wrap;">
        </ng-select>
        <div class="alert alert-danger" id="validationFormat" [hidden]="currentDocumentType.docForm">Format is required</div>
        <div style="height: 10px;"></div>
        <label> Description: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.description" />
        <div class="alert alert-danger" id="validationDescription" [hidden]="currentDocumentType.description">Description is required</div>
        <div style="height: 10px;"></div>
        <label> Expiration: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentDocumentType.expirationDuration">
        &nbsp;&nbsp;
        <select
            style="color: black;height: 26px;" id="expirationUnit" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationUnit">
            <option value="YEARS">YEARS</option>
            <option value="MONTHS">MONTHS</option>
            <option value="DAYS">DAYS</option>
        </select>
        &nbsp;&nbsp;
        <select
            style="color: black;height: 26px;" id="expirationType" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationType">
            <option value="HARD">HARD</option>
            <option value="SOFT">SOFT</option>
        </select>
        <div class="alert alert-danger" id="validationExpiration"
            [hidden]="currentDocumentType.expirationUnit && currentDocumentType.expirationDuration && currentDocumentType.expirationType">
            Expiration is required
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information" (click)="save()">Save</button>
    </div>
</ng-template>
<ng-template #addTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Document Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label> Name: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.documentTypeName" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.documentTypeName">Name is required</div>
        <div style="height: 10px;"></div>
        <label> Allowable MIME types: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-multiselect-dropdown [placeholder]="'Select types'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
        <div class="alert alert-danger" [hidden]="selectedItems.length">MIME types are required</div>
        <div style="height: 10px;"></div>
        <label> Format: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-select class="custom" [items]="formats" [(ngModel)]="currentDocumentType.docForm" style="flex-wrap: wrap;">
        </ng-select>
        <div class="alert alert-danger" [hidden]="currentDocumentType.docForm">Format is required</div>
        <div style="height: 10px;"></div>
        <label> Description: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.description" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.description">Description is required</div>
        <div style="height: 10px;"></div>
        <label> Expiration: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentDocumentType.expirationDuration">
        &nbsp;&nbsp;
        <select
            style="color: black;height: 26px;" id="expirationUnit" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationUnit">
            <option value="YEARS">YEARS</option>
            <option value="MONTHS">MONTHS</option>
            <option value="DAYS">DAYS</option>
        </select>
        &nbsp;&nbsp;
        <select
            style="color: black;height: 26px;" id="expirationType" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationType">
            <option value="HARD">HARD</option>
            <option value="SOFT">SOFT</option>
        </select>
        <div class="alert alert-danger"
            [hidden]="currentDocumentType.expirationUnit && currentDocumentType.expirationDuration && currentDocumentType.expirationType">
            Expiration is required
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information" (click)="add()">Add</button>
    </div>
</ng-template>
