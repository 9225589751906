import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebDataService {

    public searchQuery = new BehaviorSubject<any>(null);
    public searchfirstName = new BehaviorSubject<any>(null);
    public searchlastName = new BehaviorSubject<any>(null);
    public searchDob = new BehaviorSubject<any>(null);
    public searchCountry = new BehaviorSubject<any>(null);
    public countryRisk = new BehaviorSubject<any>(null);
    public results = new BehaviorSubject(null);
    public pepHits = new BehaviorSubject(null);
    public pepHitsNo = new BehaviorSubject(null);
    public sanctionHitsNo = new BehaviorSubject(null);
    public sanctionHits = new BehaviorSubject(null);
    public reportResults = new BehaviorSubject(null);
    private initialResults = new BehaviorSubject(null);
    private preDiscardResults = new BehaviorSubject(null);
    private hitsNo = new BehaviorSubject<any>(null);
    private isFS = new BehaviorSubject<boolean>(null);
    public languages = new BehaviorSubject(null);
    private isF = new BehaviorSubject(null);
    public filterOption = new BehaviorSubject(null);
    public sortOption = new BehaviorSubject(null);
    public truePEPSanction = new BehaviorSubject(null);
    public pieChartArticles = new BehaviorSubject(null);
    public topicCounts = new BehaviorSubject(null);
    public screenerData = new BehaviorSubject(null);
    public screenerResults = new BehaviorSubject(null);
    public encoding = new BehaviorSubject(null);
    public pepSanctionFilter = new BehaviorSubject(null);
    public start = new BehaviorSubject(null);
    public end = new BehaviorSubject(null);
    public pageIndex = new BehaviorSubject(null);
    public languageFilter = new BehaviorSubject(null);

    currentQuery = this.searchQuery.asObservable();
    currentfirstName = this.searchfirstName.asObservable();
    currentlastName = this.searchlastName.asObservable();
    currentDob = this.searchDob.asObservable();
    currentCountry = this.searchCountry.asObservable();
    currentResults = this.results.asObservable();
    currentCountryRisk = this.countryRisk.asObservable();
    initial_results = this.initialResults.asObservable();
    pre_discard_results = this.preDiscardResults.asObservable();
    isFullSearch = this.isFS.asObservable();
    count = this.hitsNo.asObservable();
    currentLanguages = this.languages.asObservable();
    isFiltered = this.isF.asObservable();
    filter_option = this.filterOption.asObservable();
    sort_option = this.sortOption.asObservable();
    report_results = this.reportResults.asObservable();
    currentPepHits = this.pepHits.asObservable();
    currentSanctionHits = this.sanctionHits.asObservable();
    truePS = this.truePEPSanction.asObservable();
    currentPieChartArticles = this.pieChartArticles.asObservable();
    noPEPHits = this.pepHitsNo.asObservable();
    noSanctionHits = this.sanctionHits.asObservable();
    currentTopicCounts = this.topicCounts.asObservable();
    screener = this.screenerData.asObservable();
    screener_results = this.screenerResults.asObservable();
    encodingSetting = this.encoding.asObservable();
    isPepSanctionFilter = this.pepSanctionFilter.asObservable();
    startTimestamp = this.start.asObservable();
    endTimestamp = this.end.asObservable();
    page_index = this.pageIndex.asObservable();
    currentLanguageFilter = this.languageFilter.asObservable();

    setPepSanctionFilter(pepSanctionFilter: any) {
        this.pepSanctionFilter.next(pepSanctionFilter);
    }

    setCurrentLanguageFilter(languageFilter: any) {
        this.languageFilter.next(languageFilter);
    }

    setCurrentStart(start: any) {
        this.start.next(start);
    }


    setCurrentEnd(end: any) {
        this.end.next(end);
    }

    setCurrentPageIndex(pageIndex: any) {
        this.pageIndex.next(pageIndex);
    }


    setEncoding(encoding: any) {
        this.encoding.next(encoding);
    }

    setScreenerResults(screenerResults: any) {
        this.screenerResults.next(screenerResults);
    }

    setScreenerData(data: any) {
        this.screenerData.next(data);
    }

    setTopicCounts(topicCounts: any) {
        this.topicCounts.next(topicCounts);
    }

    setPEPHitsNo(pepHitsNo: any) {
        this.pepHitsNo.next(pepHitsNo);
    }

    setSanctionHitsNo(sanctionHitsNo: any) {
        this.sanctionHitsNo.next(sanctionHitsNo);
    }

    setCurrentPieChartArticles(pieChartArticles: any) {
        this.pieChartArticles.next(pieChartArticles);
    }

    setIsFullSearch(isFullSearch: boolean) {
       this.isFS.next(isFullSearch);
    }

    setCountryRisk(countryRisk: any) {
        this.countryRisk.next(countryRisk);
    }

    setTruePS(truePS: any) {
        this.truePEPSanction.next(truePS);
    }

    setSortOption(sortOption: any) {
        this.sortOption.next(sortOption);
    }

    setFilterOption(filterOption: any) {
        this.filterOption.next(filterOption);
    }

    setIsFiltered(isFiltered: boolean) {
        this.isF.next(isFiltered);
    }

    setfirstName(searchfirstName: any) {
       this.searchfirstName.next(searchfirstName);
    }

    setlastName(searchlastName: any) {
        this.searchlastName.next(searchlastName);
    }

    setCount(count: any) {
        this.hitsNo.next(count);
    }

    setDob(searchDob: any) {
        this.searchDob.next(searchDob);
    }

    setCountry(searchCountry: any) {
        this.searchCountry.next(searchCountry);
    }

    setQuery(searchQuery: any) {
        this.searchQuery.next(searchQuery);
    }

    setCurrentResults(results: any) {
        this.results.next(results);
    }

    setCurrentPEPHits(pepHits: any) {
        this.pepHits.next(pepHits);
    }

    setCurrentSanctionHits(sanctionHits: any) {
        this.sanctionHits.next(sanctionHits);
    }

    setCurrentReportResults(results: any) {
        this.reportResults.next(results);
    }

    setInitialResults(results: any) {
        this.initialResults.next(results);
    }

    setPreDiscardResults(results: any) {
        this.preDiscardResults.next(results);
    }

    setLanguages(languages: any) {
        this.languages.next(languages);
    }
}
