import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

/**
 * Implements service for displaying alerts
 */
@Injectable({
  providedIn: 'root'
})
export class AlertService {

    constructor(
        private router: Router,
        private toastrService: ToastrService
    ) {}

    showSuccess(message) {
        this.toastrService.success(message, 'SUCCESS');
    }

    showError(message) {
        this.toastrService.error(message, 'ERROR');
    }

    showInfo(message) {
        this.toastrService.info(message, 'INFO');
    }

    showWarning(message) {
        this.toastrService.warning(message, 'WARNING');
    }
}
