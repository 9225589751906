<div class="col-md-12">
    <div style="height: 20px"></div>
    <button class="bbtn btn-success btn-add-new" (click)="addNew(addTemplate)"
        style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus"
            aria-hidden="true"></i></button>
    <div style="height: 15px"></div>
    <table class="table table-responsive table-hover" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Type</th>
                <th>Legal Form</th>
                <th>Required Links</th>
                <th>Screening</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let legalPersonType of entityTypes">
                <td>{{legalPersonType?.entityType}}</td>
                <td>{{legalPersonType?.legalPersonType}}</td>
                <td>
                    <ul style="text-align: left;">
                        <li *ngFor="let requiredRole of legalPersonType?.requiredRoles?.requiredE1Roles">
                            {{requiredRole?.operator}} {{requiredRole?.requiredValue}}
                            {{requiredRole?.roleType?.defaultRoleName}}</li>
                    </ul>
                </td>
                <td>{{legalPersonType?.screeningOption}}</td>
                <td><button class="btn btn-warning btn-yellow" style="padding: 6px;"
                        (click)="edit(editTemplate,legalPersonType)">Edit &nbsp;<i class="fa fa-pencil"
                            aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Legal Person Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label> Type: </label><br>
        <div style="height: 10px;"></div>
        <select [(ngModel)]="currentPersonType.entityType" name="entityType">
            <option value=""></option>
            <option *ngFor="let entityType of entityTypeList" [value]="entityType"> {{entityType}} </option>
        </select>
        <div style="height: 10px;"></div>
        <label> Legal Form: </label><br>
        <div style="height: 10px;"></div> <input type="text" [(ngModel)]="currentPersonType.legalPersonType">
        <div style="height: 10px;"></div>
        <label> Required Links: </label><br>
        <div style="height: 10px;"></div>
        <div *ngFor="let link of currentPersonType?.requiredRoles?.requiredE1Roles">{{link.operator}}
            {{link.requiredValue}} {{link.roleType.defaultRoleName}}</div>
        <div style="height: 10px;"></div>
        <form [formGroup]="requiredLinkForm">
            <div formArrayName="links">
                <div *ngFor="let student of links.controls; let i = index" [formGroupName]="i">
                    <table class="table table-responsive table-hover" style="border: none;">
                        <tr>
                            <td>Operator:</td>
                            <td>Required value:</td>
                            <td>Role type:</td>
                            <td>Actions:</td>
                        </tr>
                        <tr>
                            <td>
                                <select formControlName="operator">
                                    <option value=""></option>
                                    <option value="EXACTLY">exactly</option>
                                    <option value="MORE_THAN">more than</option>
                                </select>
                            </td>
                            <td>
                                <input style="width: 100%;" formControlName="requiredValue" placeholder="Value">
                            </td>
                            <td>
                                <select class="select-role-type" formControlName="roleTypeId" placeholder="Role Type ID">
                                    <option value=""></option>
                                    <option *ngFor="let roleType of v4RoleTypes" [value]="roleType.id">
                                        {{roleType.defaultRoleName}} </option>
                                </select>
                            </td>
                            <td>
                                <button class="btn btn-danger btn-delete" (click)="deleteStudent(student.value)">Delete Link&nbsp;
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="height: 20px"></div>
            <button class="btn btn-success btn-save-information" (click)="addStudent()">Add Link&nbsp;<i
                    class="fa fa-plus" aria-hidden="true"></i></button>
        </form>
        <div style="height: 20px;"></div>
        <label> Screening options: </label>
        <div class="radio">
          <label>
              <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="fullScreening">
              Full screening
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="pepAndSanctionsOnly">
            PEP and Sanctions only
          </label>
        </div>
        <div class="radio">
          <label>
          <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="noScreening">
          No screening
          </label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information" (click)="saveEdit()">Save</button>
    </div>
</ng-template>
<ng-template #addTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Legal Person Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        this.currentPersonType.entityType
        <form [formGroup]="disableAddBtn">
            <label> Type: </label><br>
            <div style="height: 10px;"></div>
            <select [(ngModel)]="currentPersonType.entityType" name="entityType" formControlName="entityType">
                <option value=""></option>
                <option *ngFor="let entityType of entityTypeList" [value]="entityType"> {{entityType}} </option>
            </select>
            <div style="height: 10px;"></div>
            <label> Legal Form: </label><br>
            <div style="height: 10px;"></div>
            <input type="text" formControlName="legalFormTitle" [(ngModel)]="currentPersonType.legalForm">
        </form>
        <div style="height: 10px;"></div>
        <label> Required Links: </label><br>
        <div style="height: 10px;"></div>
        <form [formGroup]="requiredLinkForm">
            <div formArrayName="links">
                <div *ngFor="let student of links.controls; let i = index" [formGroupName]="i">
                    <table class="table table-responsive table-hover" style="border: none;">
                        <tr>
                            <td>Operator:</td>
                            <td>Required value:</td>
                            <td>Role type:</td>
                        </tr>
                        <tr>
                            <td>
                                <select formControlName="operator">
                                    <option value=""></option>
                                    <option value="EXACTLY">exactly</option>
                                    <option value="MORE_THAN">more than</option>
                                </select>
                            </td>
                            <td>
                                <input formControlName="requiredValue" placeholder="Value">
                            </td>
                            <td>
                                <select class="select-role-type" formControlName="roleTypeId" placeholder="Role Type ID">
                                    <option value=""></option>
                                    <option *ngFor="let roleType of v4RoleTypes" [value]="roleType.id">
                                        {{roleType.defaultRoleName}} </option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="height: 20px"></div>
            <button class="btn btn-success btn-save-information" (click)="addStudent()">Add Link&nbsp;<i
                    class="fa fa-plus" aria-hidden="true"></i></button>
        </form>
        <div style="height: 20px;"></div>
        <label> Screening options: </label>
        <div class="radio">
          <label>
              <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="fullScreening">
              Full screening
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="pepAndSanctionsOnly">
            PEP and Sanctions only
          </label>
        </div>
        <div class="radio">
          <label>
          <input type="radio" [(ngModel)]="currentPersonType.screeningOption" value="noScreening">
          No screening
          </label>
        </div>
        <div style="height: 10px;"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information" (click)="add()" [disabled]="!disableAddBtn.valid">Add</button>
    </div>
</ng-template>
