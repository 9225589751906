/**
* Model for the registration form
*/
export class FormData {
    firstName = '';
    lastName = '';
    email = '';
    dateOfBirth = '';
    addressStreet = '';
    addressNumber = '';
    addressZIP = '';
    addressCity = '';
    addressCountry = '';
    taxCountry = '';
    nationality = '';
    password = '';
    password_confirm = '';
    walletAddress = '';
    amount = '';
    currencyType = '';
    sourceOfFunds = '';
    passportFront: File = null;
    passportBack: File = null;
    selfie: File = null;
    proofOfResidence: File = null;
    telegramName = '';
    twitterName = '';
    linkedinProfile = '';
    facebookProfile = '';
    onOwnBehalf = false;
    nonUs = false;
    nonChinese = false;
    conditionsAgreement = false;
    fullAndFactual = false;
    exclusionStatement = false;
    nonFATF = false;
    acceptanceOfRiskDisclaimer = false;

    clear() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.dateOfBirth = '';
        this.addressStreet = '';
        this.addressNumber = '';
        this.addressZIP = '';
        this.addressCity = '';
        this.addressCountry = '';
        this.taxCountry = '';
        this.nationality = '';
        this.password = '';
        this.password_confirm = '';
        this.walletAddress = '';
        this.amount = '';
        this.currencyType = '';
        this.sourceOfFunds = '';
        this.passportFront = null;
        this.passportBack = null;
        this.selfie = null;
        this.proofOfResidence = null;
        this.telegramName = '';
        this.twitterName = '';
        this.linkedinProfile = '';
        this.facebookProfile = '';
        this.onOwnBehalf = false;
        this.nonUs = false;
        this.nonChinese = false;
        this.conditionsAgreement = false;
        this.fullAndFactual = false;
        this.exclusionStatement = false;
        this.nonFATF = false;
        this.acceptanceOfRiskDisclaimer = false;
    }
}

/**
* Model for the registration form - personal section
*/
export class Personal {
    email = '';
    password = '';
    password_confirm = '';
}

/**
* Model for the registration form - address section
*/
export class Other {
    firstName = '';
    lastName = '';
    dateOfBirth = '';
    nationality = '';
    addressStreet = '';
    addressNumber = '';
    addressZIP = '';
    addressCity = '';
    addressCountry = '';
    proofOfResidence: File = null;
    taxCountry = '';
    telegramName = '';
    twitterName = '';
    linkedinProfile = '';
    facebookProfile = '';
    walletAddress = '';
    amount = '';
    currencyType = '';
    sourceOfFunds = '';
}

/**
* Model for the registration form - documents section
*/
export class Documents {
    passportFront: File = null;
    passportBack: File = null;
    selfie: File = null;
}

/**
* Model for the registration form - disclaimers section
*/
export class Disclaimers {
    onOwnBehalf = false;
    nonUs = false;
    nonChinese = false;
    conditionsAgreement = false;
    fullAndFactual = false;
    exclusionStatement = false;
    nonFATF = false;
    acceptanceOfRiskDisclaimer = false;
}
