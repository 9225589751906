<div class="col-md-10 col-md-offset-1" xmlns:height="http://www.w3.org/1999/xhtml">
    Please select which tags you would like to hide. Entities with these tags will not be displayed in the system.
    <div style="height: 30px;"></div>
    <ng-select
    class="custom"
    [items]="tags"
    [multiple]="true"
    bindLabel="name"
    [closeOnSelect]="false"
    bindValue="id"
    [clearable]="false"
    [(ngModel)]="selectedTags">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
    </ng-template>
</ng-select>  
<div style="height: 20px;"></div>
<button class="btn btn-primary" (click)="save()">Save</button>      
</div>