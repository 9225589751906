<div class="container report-preview-box" id="report-preview-box">
    <div class="row" style="margin-right: 0;">
        <div style="height: 15px"></div>
        <div class="col-lg-2">
            <img src={{KYClogo}} style="padding-left: 15px;" width="180%">
        </div>
        <div class="col-lg-3"></div>
        <div class="col-lg-7 credentials">
            <div class="row">
                THIS REPORT WAS BUILT BY {{username | uppercase}}
            </div>
            <div class="row">
                DATE/TIME: {{timestamp}}
            </div>
        </div>
    </div>
    <div style="height: 20px;"></div>
    <div class="row" style="border-top: 1px solid #eee;border-bottom: 1px solid #eee;font-size: 11px;padding-left: 30px;">
        <div style="height: 10px"></div>
        <div class="col-lg-6">
            <div style="height: 20px;"></div>
            <table cellpadding="1" width="100%">
                <tr class="info-heading">
                    <td>FIRST NAME</td>
                    <td>LAST NAME</td>
                </tr>
                <tr>
                    <td class="info-text">{{firstName | uppercase}}</td>
                    <td class="info-text">{{lastName | uppercase}}</td>
                </tr>
                <tr>
                    <td>
                        <div style="height: 10px"></div>
                    </td>
                </tr>
                <tr *ngIf="dateOfBirth || country" class="info-heading">
                    <td>DATE OF BIRTH</td>
                    <td>COUNTRY</td>
                </tr>
                <tr *ngIf="dateOfBirth || country">
                    <td class="info-text">{{dateOfBirth}}</td>
                    <td class="info-text">{{country}}</td>
                </tr>
                <tr>
                    <td>
                        <div style="height: 15px;"></div>
                    </td>
                </tr>
                <tr *ngIf="riskAssessment || riskLevel" class="info-heading">
                    <td>RISK ASSESSMENT</td>
                </tr>
                <tr>
                    <td *ngIf="riskAssessment" class="info-text">
                        <div style="height: 15px;"></div>
                        <span [ngSwitch]="riskAssessment">
                            <span *ngSwitchCase="'approved'"><img src="../../../../../assets/images/icons/PNG/approved_circle.png" width="18" tooltip="Approved"/>&nbsp;&nbsp;APPROVED</span>
                            <span *ngSwitchCase="'rejected'"><img src="../../../../../assets/images/icons/PNG/rejected_circle.png" width="18" tooltip="Rejected"/>&nbsp;&nbsp;REJECTED</span>
                            <span *ngSwitchCase="'further'"><img src="../../../../../assets/images/icons/PNG/further_circle.png" width="18" tooltip="Further Analysis"/>&nbsp;&nbsp;FURTHER ANALYSIS</span>
                        </span>
                    </td>
                    <td *ngIf="riskLevel" class="info-text">   
                        <div style="height: 15px;"></div>
                        <span [ngSwitch]="riskLevel">
                            <span *ngSwitchCase="'low'"><img src="../../../../../assets/images/icons/PNG/low_risk.png" width="18" tooltip="Low Risk" />&nbsp;&nbsp;{{riskLevel | uppercase}} RISK</span>
                            <span *ngSwitchCase="'medium'"><img src="../../../../../assets/images/icons/PNG/medium_risk.png" width="18" tooltip="Medium Risk" />&nbsp;&nbsp;{{riskLevel | uppercase}} RISK</span>
                            <span *ngSwitchCase="'high'"><img src="../../../../../assets/images/icons/PNG/high_risk.png" width="18" tooltip="High Risk" />&nbsp;&nbsp;{{riskLevel | uppercase}} RISK</span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style="height: 15px;"></div>
                    </td>
                </tr>
                <tr *ngIf="riskAssessmentNote" class="info-heading">
                    <td>COMMENT</td>
                </tr>
                <tr *ngIf="riskAssessmentNote">
                    <td class="info-text" colspan="2">{{riskAssessmentNote}}</td>
                </tr>
                <tr>
                    <td>
                        <div style="height: 20px;"></div>
                    </td>
                </tr>
                <tr *ngIf="countryRisk || topicCounts?.reputationalRisk > 0 || topicCounts?.corruptionRisk > 0 || topicCounts?.pepRisk > 0 || topicCounts?.moneyLaundering > 0 || topicCounts?.terrorism > 0 || topicCounts?.other > 0" class="info-heading">
                    <td>RISK FACTORS</td>
                </tr>
                <tr *ngIf="countryRisk">
                    <td class="info-text"><b>COUNTRY RISK</b></td>
                    <td class="info-text">{{countryRisk}} out of 10</td>
                </tr>
                <tr *ngIf="topicCounts?.reputationalRisk > 0">
                    <td class="info-text"><b>REPUTATIONAL RISK</b></td>
                    <td class="info-text">{{topicCounts?.reputationalRisk}} total hits</td>
                </tr>
                <tr *ngIf="topicCounts?.corruptionRisk > 0">
                    <td class="info-text"><b>CORRUPTION RISK</b></td>
                    <td class="info-text">{{topicCounts?.corruptionRisk}} total hits</td>
                </tr>
                <tr *ngIf="topicCounts?.pepRisk > 0">
                    <td class="info-text"><b>PEP RISK</b></td>
                    <td class="info-text">{{topicCounts?.pepRisk}} total hits</td>
                </tr>
                <tr *ngIf="topicCounts?.moneyLaundering > 0">
                    <td class="info-text"><b>MONEY LAUNDERING</b></td>
                    <td class="info-text">{{topicCounts?.moneyLaundering}} total hits</td>
                </tr>
                <tr *ngIf="topicCounts?.terrorism > 0">
                    <td class="info-text"><b>TERRORISM</b></td>
                    <td class="info-text">{{topicCounts?.terrorism}} total hits</td>
                </tr>
                <tr *ngIf="topicCounts?.other > 0">
                    <td class="info-text"><b>OTHER</b></td>
                    <td class="info-text">{{topicCounts?.other}} total hits</td>
                </tr>
                <tr>
                    <td>
                        <div style="height: 15px;"></div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-lg-6">
            <piechart #piechart></piechart>
        </div>
    </div>
    <div *ngIf="noResults" class="row">
        <div class="col-lg-12" style="padding-left: 30px;">
            <div style="height: 30px;"></div>
            <div class="results-message text-center">
                There are no results in the report.<br>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="results" style="padding: 15px;">
                <report-results #reportResults [reportResultsEvent]="reportResultsSubject.asObservable()" [riskEvent]="riskSubject.asObservable()" (reportChangedEvent)="piechart.receiveEvents($event)" (articlesChangedEvent)="piechart.receiveArticlesChangedEvent($event)"></report-results>
            </div>
        </div>
    </div>
</div>
    