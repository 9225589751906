<div class="col-md-12">
    <div style="height: 20px"></div>
    <h3 class="text-center">Risk Analysis Settings</h3><br>
    <div style="height: 10px;"></div>
    <span style="font-size: 16px;">Current settings:</span>
        <div style="height: 5px;"></div>
        <ul>
            <li><b>Medium risk threshold:</b> {{currentSettings?.mediumRiskThreshold}}</li>
            <li><b>High risk threshold:</b> {{currentSettings?.highRiskThreshold}}</li>
            <li><b>Unacceptable risk threshold:</b> {{currentSettings?.unacceptableRiskThreshold}}</li>
            <li><b>Low Risk Refresh Period:</b> {{lowRiskConfig.value}}</li>
            <li><b>Medium Risk Refresh Period:</b> {{mediumRiskConfig.value}}</li>
            <li><b>High Risk Refresh Period:</b> {{highRiskConfig.value}}</li>
        </ul>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="mediumRiskThreshold"><b>Medium risk threshold:</b> {{currentSettings?.mediumRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="mediumRiskThreshold" class="ng-control" #mediumRiskThreshold name="mediumRiskThreshold" [(ngModel)]="currentSettings.mediumRiskThreshold" value="mediumRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.mediumRiskThreshold < 0 || currentSettings.mediumRiskThreshold > 9999 || currentSettings.mediumRiskThreshold == ''">Please enter a value between 0 and 9999.</div>
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="highRiskThreshold"><b>High risk threshold:</b> {{currentSettings?.highRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="highRiskThreshold" class="ng-control" #highRiskThreshold name="highRiskThreshold" [(ngModel)]="currentSettings.highRiskThreshold" value="highRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.highRiskThreshold < 0 || currentSettings.highRiskThreshold > 9999 || currentSettings.highRiskThreshold == ''">Please enter a value between 0 and 9999.</div>
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="unacceptableRiskThreshold"><b>Unacceptable risk threshold:</b> {{currentSettings?.unacceptableRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="unacceptableRiskThreshold" #unacceptableRiskThreshold class="ng-control" name="unacceptableRiskThreshold" [(ngModel)]="currentSettings.unacceptableRiskThreshold" value="unacceptableRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.unacceptableRiskThreshold < 0 || currentSettings.unacceptableRiskThreshold > 9999 || currentSettings.unacceptableRiskThreshold == ''">Please enter a value between 0 and 9999.</div>
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="lowRiskConfig"><b>Low Risk Refresh Period:</b> {{lowRiskConfig.value}} days</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="lowRiskConfig" class="ng-control" name="lowRiskConfig" [(ngModel)]="lowRiskConfig.value" value="lowRiskConfig">
        <div class="alert alert-danger" *ngIf="lowRiskConfig.value < 1 || lowRiskConfig.value > 9999 || lowRiskConfig.value == ''">Please enter a value between 1 and 9999.</div> 
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="mediumRiskConfig"><b>Medium Risk Refresh Period:</b> {{mediumRiskConfig.value}} days</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="mediumRiskConfig" class="ng-control" name="mediumRiskConfig" [(ngModel)]="mediumRiskConfig.value" value="mediumRiskConfig">
        <div class="alert alert-danger" *ngIf="mediumRiskConfig.value < 1 || mediumRiskConfig.value > 9999 || mediumRiskConfig.value == ''">Please enter a value between 1 and 9999.</div>
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="highRiskConfig"><b>High Risk Refresh Period:</b> {{highRiskConfig.value}} days</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="highRiskConfig" class="ng-control" name="highRiskConfig" [(ngModel)]="highRiskConfig.value" value="highRiskConfig">
        <div class="alert alert-danger" *ngIf="highRiskConfig.value < 1 || highRiskConfig.value > 9999 || highRiskConfig.value == ''">Please enter a value between 1 and 9999.</div>
    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="saveSettings()" [disabled]="currentSettings.lowRiskThreshold < 0 || currentSettings.lowRiskThreshold > 9999 || currentSettings.mediumRiskThreshold < 0 || currentSettings.mediumRiskThreshold > 9999 || currentSettings.highRiskThreshold < 0 || currentSettings.highRiskThreshold > 9999 || currentSettings.unacceptableRiskThreshold < 0 || currentSettings.unacceptableRiskThreshold > 9999 || currentSettings.lowRiskThreshold == '' || currentSettings.mediumRiskThreshold == '' || currentSettings.highRiskThreshold == '' || currentSettings.unacceptableRiskThreshold == '' || lowRiskConfig.value < 1 || lowRiskConfig.value > 9999 || lowRiskConfig.value == '' || mediumRiskConfig.value < 1 || mediumRiskConfig.value > 9999 || mediumRiskConfig.value == '' || highRiskConfig.value < 1 || highRiskConfig.value > 9999 || highRiskConfig.value == ''">Save Settings</button>
    </div>
</div>
