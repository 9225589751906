import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';
import { config } from '../../../../assets/configuration';
import { FormsModule } from '@angular/forms';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-create-admin',
    standalone: true,
    imports: [ FormsModule ],
    templateUrl: 'createAdmin.component.html',
    styleUrls: [ './createAdmin.component.css' ]
})

export class CreateAdminComponent {

  userName = '';
  email = '';
  response: any = {};
  mainColor: any = config.mainColor;

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

  /**
  * Function used for changing the password
  */
  async createAdmin(form: any) {
    this.callsService.createAdmin(this.email).subscribe(
      data => {
        if (data !== null) {
          this.response = data;

          if (this.response.data.password !== null) {
            this.alertService.showSuccess('Admin created successfully. Password is <b>' + this.response.data.password + '</b>');
            this.userName = '';
            this.email = '';
          } else {
            this.alertService.showError('Something went wrong.');
          }
        }
      },
      error => {
        this.alertService.showError(error.error.message);
      }
    );
  }
}
