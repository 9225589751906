<div class="modal-header">
        <span style="color: white;" (click)="cancel()" class="glyphicon glyphicon-arrow-left"></span>
</div>
<div class="modal-body">
        <div *ngIf="permissionDenied">

                <h4 style="color: white; text-align: center;">{{CAMERA_MIC_ACCESS_BLOCKED}}</h4>

                </div>
    <div *ngIf="!permissionDenied">

        <div *ngIf="captureEnabled">
            

                <h4 style="color: white; text-align: center;">{{imageText}}</h4>

                <div class="img-responsive">
                        <img id="overlay-image" [src]="imageScr" width="100%"
                                style="opacity: 0.6;z-index: 2;position:absolute;" />
                        <webcam [height]="height" [width]="width" [trigger]="triggerObservable"
                                (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions" [imageQuality]="1"
                                (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                        </webcam>
                </div>
                <button type="button" class="button" style="transform: translate(10%, 150%);" (click)="triggerSnapshot()"></button>
        </div>
        <div *ngIf="!captureEnabled">
                <h4 style="color: white; text-align: center;">{{imageText}}</h4>
                <div class="img-responsive">
                        <div *ngIf="this.documentType == 'passport'">
                                <img width="95%" [src]="passportCapture" />
                        </div>
                        <div *ngIf="this.documentType != 'passport'">
                                        <img width="95%" [src]="identityDocument" />
                        </div>
                <div class="button-retake-use">
                        <button type="button" class=" btn btn-outline-rounded btn-info no-margin-bottom"
                                (click)="retake()">{{ "RETAKE" | translate }}
                        </button>
                        <button type="button" class=" btn btn-outline-rounded btn-info no-margin-bottom"
                                (click)="use()">{{ "USE" | translate }}
                        </button>
                </div>
        </div>
        </div>
</div>