import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, AfterViewInit } from '@angular/core';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DocumentManagerComponent } from '../documentManager/documentManager.component';
import { IdentityDocumentComponent } from '../identityDocument/identityDocument.component';
import { VideoComponent } from '../video/video.component';

@Component({
  selector: 'app-step-page-wrapper',
  standalone: true,
  template: '<ng-template #dynamicComponent></ng-template>',
})
export class StepPageWrapperComponent implements OnInit, AfterViewInit {

  @Input() item?: any;
  onboardingComnponents: {name: string, type: Type<any> }[] = [
    { name: 'document-manager', type: DocumentManagerComponent },
    { name: 'identity-document', type: IdentityDocumentComponent },
    { name: 'video-onboarding', type: VideoComponent },
    { name: 'disclaimers', type: DisclaimersComponent },
  ];

  @ViewChild('dynamicComponent', {
    read: ViewContainerRef,
    static: false
  }) myRef;

  constructor() {}

  ngAfterViewInit() {
    const currentComp = this.onboardingComnponents.find(x => x.name === this.item.name);
    this.myRef.createComponent(currentComp.type);
  }

  ngOnInit() {
    if (this.item === undefined) {
      console.error('Item undefined');
      return;
    }
  }
}
