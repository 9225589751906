<div class="modal-header">
    <h4 class='modal-title pull-left'>Close Notification with ID {{ notification.id }}</h4>
    <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
        <span aria-hidden='true'>&times;</span>
    </button>
</div>
<div class="modal-body text-center">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-12">
                <label>Note:&nbsp;</label>
                <input formControlName="note" required>
            </div>
        </div>
        <div style="height: 10px;"></div>
        <button class="btn btn-success" type="submit" [disabled]="!form.valid">Close Notification</button>
    </form>
</div>
