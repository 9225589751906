<div class="modal-header">
    <h4 class="modal-title pull-left">Load State</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
            <div style="height: 20px"></div>
            <img class="text-center" src="../../../../../assets/images/icons/PNG/load_circle.png" width="100" />
            <div style="height: 15px"></div>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
        <form #loadForm="ngForm" novalidate>
            <div class="form-group text-center">
            <br>
            <select class="form-control" id="state" style="border: none; border-radius: 0px;background-color: #F8F8F8" name="state" [(ngModel)]="state" #statee="ngModel">
                    <option value="" disabled selected> Select state to load </option>
                    <option *ngFor="let state of savedStates" value="{{state.name}}">{{state.name | prettyState}}</option>
            </select>
            </div>
        </form>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-lg-12 text-center">
            <button class="btn btn-success" style="border-radius: 0px; background-color: #F8CC20; color: white;border: none;" (click)="loadState()" [disabled]="!loadForm.valid">LOAD</button><button class="btn btn-danger" style="border: none;color:white;background-color: #98999A;border-radius: 0px;" (click)="bsModalRef.hide()">CANCEL</button>
        </div>
    </div>
</div>