import { Component, OnInit, TemplateRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AlertService, KYBService } from '../../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Link } from '../../../../_models/link.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

/**
* Implements password changing directive
*/
@Component({
  selector: 'app-legal-persons-settings',
  standalone: true,
  imports: [ CommonModule, FormsModule, ReactiveFormsModule ],
  templateUrl: 'legalPersonsSettings.component.html',
  styleUrls: ['./legalPersonsSettings.component.css']
})

export class LegalPersonsSettingsComponent implements OnInit, OnChanges {

  @Input() requiredLink: RequiredLink;
  requiredLinkForm: UntypedFormGroup;

  dataTable: any;
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  currentPersonType: any = {};
  disableAddBtn: any = '';
  entityTypeList: any = [];
  legalPersonTypeList: any = [];
  entityTypesResponse: any = {};
  entityTypes: any = [];

  @Input() v4RoleTypes: any = [];

  get links(): UntypedFormArray {
    return this.requiredLinkForm.get('links') as UntypedFormArray;
  }

  constructor(
    private alertService: AlertService,
    private kybService: KYBService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.requiredLinkForm = this.formBuilder.group({
      'links': this.formBuilder.array([])
    });
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  rebuildForm() {
    this.requiredLinkForm.reset({});
    if (this.requiredLink) {
      this.setStudents(this.requiredLink.links);
    }
  }

  setStudents(students: Link[]) {
    const studentsFA = this.formBuilder.group(students);

    this.requiredLinkForm.setControl('links', studentsFA);
  }

  addStudent() {
    this.links.push(this.formBuilder.group(new Link(null, null, null)));
  }

  deleteStudent(student) {
    this.links.controls.forEach((link, index, object) => {
      if (link.value === student) {
        object.splice(index, 1);
      }
    });

    this.links.value.forEach((link, index, object) => {
      if (link === student) {
        object.splice(index, 1);
      }
    });

    this.currentPersonType.requiredRoles.requiredE1Roles.forEach((role, index, object) => {
      if (role.roleType.id === student.roleTypeId) {
        object.splice(index, 1);
      }
    });
  }

  addDefinedStudent(requiredE1Roles) {
    for (let i = 0; i < requiredE1Roles.length; i++) {
      if (requiredE1Roles[i].roleType.self === true) {
        requiredE1Roles.splice(i, 1);
      }
    }
    this.requiredLinkForm = this.formBuilder.group({
      'links': this.formBuilder.array([])
    });

    requiredE1Roles.forEach(role => {
      this.links.push(this.formBuilder.group(new Link(role.operator, role.requiredValue, role.roleType.id)));
    });
  }

  ngOnInit() {
    this.v4RoleTypes = this.v4RoleTypes.filter(x => x.self !== true);

    this.kybService.getEntityTypes().subscribe(
      data => {
        this.entityTypesResponse = data;
        this.entityTypes = this.entityTypesResponse.data;

        for (let i = 0; i < this.entityTypes.length; i++) {
          if (this.entityTypes[i].eligibleForMediaScreening === true && this.entityTypes[i].excludedFromScreening === false) {
            this.entityTypes[i].screeningOption = 'Full screening';
          } else if (this.entityTypes[i].eligibleForMediaScreening === false &&
            this.entityTypes[i].excludedFromScreening === false) {
            this.entityTypes[i].screeningOption = 'PEP and Sanctions only';
          } else {
            this.entityTypes[i].screeningOption = 'No screening';
          }
    
          this.entityTypeList.push(this.entityTypes[i].entityType);
    
          if (this.entityTypes[i].requiredRoles !== null) {
            for (let j = 0; j < this.entityTypes[i].requiredRoles.requiredE1Roles.length; j++) {
              if (this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                this.entityTypes[i].requiredRoles.requiredE1Roles.splice(j, 1);
              }
            }
          }
        }
    
        this.entityTypeList.push('PERSON');
        this.entityTypeList.push('LEGAL');
        this.entityTypeList.push('STATE');
        this.entityTypeList.push('TREATY');
        this.entityTypeList.push('GEO');
        this.entityTypeList.push('GROUP');
        this.entityTypeList = this.entityTypeList.filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });
      }
    );

    this.initTable();
  }

  initTable() {
    if (this.entityTypes.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      if (empty.length > 0) {
        empty[0].setAttribute('style', 'display: none;');
      } 
    }
  }

  edit(editTemplate: TemplateRef<any>, legalPersonType: any) {
    this.v4RoleTypes = this.v4RoleTypes.filter(x => x.self !== true);

    this.currentPersonType = legalPersonType;

    if (this.currentPersonType && this.currentPersonType.requiredRoles) {
      this.addDefinedStudent(this.currentPersonType.requiredRoles.requiredE1Roles);
    }

    if (this.currentPersonType.eligibleForMediaScreening === true && this.currentPersonType.excludedFromScreening === false) {
      this.currentPersonType.screeningOption = 'fullScreening';
    } else if (this.currentPersonType.eligibleForMediaScreening === false && this.currentPersonType.excludedFromScreening === false) {
      this.currentPersonType.screeningOption = 'pepAndSanctionsOnly';
    } else {
      this.currentPersonType.screeningOption = 'noScreening';
    }

    this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
  }

  save() {
    if (this.currentPersonType.screeningOption === 'fullScreening') {
      this.currentPersonType.eligibleForMediaScreening = true;
      this.currentPersonType.excludedFromScreening = false;
    } else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = false;
    } else {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = true;
    }

    this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
    this.currentPersonType.requiredRolesRequest = {};
    this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;
    this.currentPersonType.requiredRolesRequest.id = this.currentPersonType.requiredRoles.id;

    // Legal person type is old version of legal form and needs to be remapped while sending
    this.currentPersonType.legalForm = this.currentPersonType.legalPersonType;

    this.kybService.updateEntityType(this.currentPersonType).subscribe(
      data => {
        this.editModalRef.hide();

        this.alertService.showSuccess('Type edited successfully.');
        this.currentPersonType = {};
        this.ngOnInit();

      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }

  saveEdit() {
    this.save();
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.disableAddBtn = new UntypedFormGroup({
      entityType: new UntypedFormControl(null, Validators.required),
      legalFormTitle: new UntypedFormControl(null, Validators.required),
    });
    this.currentPersonType = {};
    this.links.clear();
    this.v4RoleTypes = this.v4RoleTypes.filter(x => x.self !== true);
    this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
  }

  add() {
    if (this.currentPersonType.screeningOption === 'fullScreening') {
      this.currentPersonType.eligibleForMediaScreening = true;
      this.currentPersonType.excludedFromScreening = false;
    } else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = false;
    } else {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = true;
    }

    this.currentPersonType.entityDetailForm = '';
    this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
    this.currentPersonType.requiredRolesRequest = {};
    this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;

    this.kybService.createEntityType(this.currentPersonType).subscribe(
      data => {
        this.addModalRef.hide();
        this.alertService.showSuccess('Type added successfully.');
        this.currentPersonType = {};
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }
}

export class RequiredLink {
  public links: Link[];
}
