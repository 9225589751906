<div style="height: 10px"></div>
<tabset type="pills" #staticTabs class="main-topic-tabs">
  <tab heading="REPUTATIONAL RISK">
      <ng-container *ngFor="let result of results; index as i">
        <ng-container *ngIf="result.masterTopicLabel == 'Reputational Risk'">
          <div style="height: 10px"></div>
          <table cellpadding="5">
            <tr>
                <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
            </tr>
            <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
            <div *ngIf="tabs[i] == true" style="height: 10px"></div>
            <tr *ngIf="tabs[i] == true" colspan="3">
                <td><input type="text" name="topicNote" class="note" style="width: 171%; background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
            </tr>
          </table>
          <br>
          <div *ngIf="tabs[i] == true" style="height: 10px"></div>
          <div *ngIf="tabs[i] == true">
              <div class="results-panel">
                  <accordion>
                  <ng-container *ngFor="let medium of result?.media; index as j">
                        <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                              <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                              <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="30px" tooltip="Add article to report" (click)="addToReport(medium,result.topicLabel)" /></span>
                            </button>
                            <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                            <span class="medium-text">{{medium.text}}...</span><br>
                            <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                            <div class="row">
                              <div class="col-lg-12" style="padding-left: 30px;">
                                <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                              </div>
                            </div>
                        </accordion-group>
                        <div style="height: 20px"></div>
                    </ng-container>
                  </accordion>
                </div>
          </div>
        </ng-container>
      </ng-container>
  </tab>
  <tab heading="CORRUPTION RISK">
      <ng-container *ngFor="let result of results; index as i">
          <ng-container *ngIf="result.masterTopicLabel == 'Corruption Risk'">
              <div style="height: 10px"></div>
              <table cellpadding="5">
                  <tr>
                      <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                      <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
                  </tr>
                  <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
                  <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                  <tr *ngIf="tabs[i] == true" colspan="3">
                      <td><input type="text" name="topicNote" class="note" style="width: 171%;background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
                  </tr>
                </table>
                <br>
                <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                <div *ngIf="tabs[i] == true">
                <div class="results-panel">
                  <accordion>
                  <ng-container *ngFor="let medium of result?.media">
                        <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                              <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                              <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="32px" tooltip="Add article to report" (click)="addToReport(medium,result.topicLabel)" /></span>
                            </button>
                            <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                            <span class="medium-text">{{medium.text}}...</span><br>
                            <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                            <div class="row">
                              <div class="col-lg-12" style="padding-left: 30px;">
                                <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                              </div>
                            </div>
                        </accordion-group>
                        <div style="height: 20px"></div>
                    </ng-container>
                  </accordion>
                </div>
              </div>
          </ng-container>
        </ng-container>
  </tab>
  <tab heading="PEP RISK">
      <ng-container *ngFor="let result of results; index as i">
          <ng-container *ngIf="result.masterTopicLabel == 'PEP Risk'">
              <div style="height: 10px"></div>
              <table cellpadding="5">
                  <tr>
                      <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                      <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
                  </tr>
                  <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
                  <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                  <tr *ngIf="tabs[i] == true" colspan="3">
                      <td><input type="text" name="topicNote" class="note" style="width: 171%;background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
                  </tr>
                </table>
                <br>
                <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                <div *ngIf="tabs[i] == true">
                <div class="results-panel">
                  <accordion>
                  <ng-container *ngFor="let medium of result?.media">
                        <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                              <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                              <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="32px" tooltip="Add article to report" (click)="addToReport(medium,result.topicLabel)" /></span>
                            </button>
                            <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                            <span class="medium-text">{{medium.text}}...</span><br>
                            <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                            <div class="row">
                              <div class="col-lg-12" style="padding-left: 30px;">
                                <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                              </div>
                            </div>
                        </accordion-group>
                        <div style="height: 20px"></div>
                    </ng-container>
                  </accordion>
                </div>
              </div>
          </ng-container>
        </ng-container>
  </tab>
  <tab heading="MONEY LAUNDERING">
    <ng-container *ngFor="let result of results; index as i">
        <ng-container *ngIf="result.masterTopicLabel == 'Money Laundering'">
            <div style="height: 10px"></div>
            <table cellpadding="5">
                <tr>
                    <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                    <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
                </tr>
                <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
                <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                <tr *ngIf="tabs[i] == true" colspan="3">
                    <td><input type="text" name="topicNote" class="note" style="width: 171%;background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
                </tr>
              </table>
              <br>
              <div *ngIf="tabs[i] == true" style="height: 10px"></div>
              <div *ngIf="tabs[i] == true">
              <div class="results-panel">
                <accordion>
                <ng-container *ngFor="let medium of result?.media">
                      <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                          <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                            <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                            <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="32px" tooltip="Add article to report" (click)="addToReport(medium,result.topicLabel)" /></span>
                          </button>
                          <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                          <span class="medium-text">{{medium.text}}...</span><br>
                          <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                          <div class="row">
                            <div class="col-lg-12" style="padding-left: 30px;">
                              <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                            </div>
                          </div>
                      </accordion-group>
                      <div style="height: 20px"></div>
                  </ng-container>
                </accordion>
              </div>
            </div>
        </ng-container>
      </ng-container>
  </tab>
  <tab heading="TERRORISM">
    <ng-container *ngFor="let result of results; index as i">
        <ng-container *ngIf="result.masterTopicLabel == 'Terrorism'">
            <div style="height: 10px"></div>
            <table cellpadding="5">
                <tr>
                    <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                    <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
                </tr>
                <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
                <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                <tr *ngIf="tabs[i] == true" colspan="3">
                    <td><input type="text" name="topicNote" class="note" style="width: 171%;background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
                </tr>
              </table>
              <br>
              <div *ngIf="tabs[i] == true" style="height: 10px"></div>
              <div *ngIf="tabs[i] == true">
              <div class="results-panel">
                <accordion>
                <ng-container *ngFor="let medium of result?.media">
                      <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                          <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                            <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                            <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="32px" tooltip="Add article to report" (click)="addToReport(medium,result.topicLabel)" /></span>
                          </button>
                          <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                          <span class="medium-text">{{medium.text}}...</span><br>
                          <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                          <div class="row">
                            <div class="col-lg-12" style="padding-left: 30px;">
                              <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                            </div>
                          </div>
                      </accordion-group>
                      <div style="height: 20px"></div>
                  </ng-container>
                </accordion>
              </div>
            </div>
        </ng-container>
      </ng-container>
  </tab>
  <tab heading="OTHER">
    <ng-container *ngFor="let result of results; index as i">
        <ng-container *ngIf="result.masterTopicLabel == 'Other'">
            <div style="height: 10px"></div>
            <table cellpadding="5">
                <tr>
                    <td><h4><b><span style="color: #0377B8;">Topic:</span></b>&nbsp;&nbsp;{{result.topicLabel | replaceSpace}}</h4></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="allFromTopicToReport(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #0377B8;color: white;" class="btn-warning"> ALL TO REPORT</button></td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="ignoreAllFromTopic(result.topicLabel)" style="font-size: 11px;border-radius: 19px;background-color: #828282;color: white;" class="btn-warning"> IGNORE ALL</button></td>
                    <td><i class="fas fa-caret-down" tooltip="Expand/Collapse" (click)="openClose(i)" style="padding-left: 7px; font-size: 20px; color: #828282"></i></td>
                </tr>
                <tr *ngIf="tabs[i] == true"><td><h5>Showing <span style="color: #0377B8;">{{result.mediaCount}}</span> of <span style="color: #0377B8;">{{result.count}}</span> total hits</h5></td></tr>
                <div *ngIf="tabs[i] == true" style="height: 10px"></div>
                <tr *ngIf="tabs[i] == true" colspan="3">
                    <td><input type="text" name="topicNote" class="note" style="width: 171%;background-color:white;" [(ngModel)]="topicNote" placeholder="Note for this topic" (ngModelChange)="addTopicNote(result.topicLabel,topicNote)"></td>
                </tr>
              </table>
              <br>
              <div *ngIf="tabs[i] == true" style="height: 10px"></div>
              <div *ngIf="tabs[i] == true">
              <div class="results-panel">
                <accordion>
                <ng-container *ngFor="let medium of result?.media">
                      <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;box-shadow: 0 4px 8px 0 rgba(78, 77, 77, 0.2), 0 6px 20px 0 rgba(78, 77, 77, 0.19);">
                          <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 2px solid #F1F0F0;color: rgb(148, 148, 148);" accordion-heading>
                            <div class="pull-left float-left">{{ medium.publicationDate | date }}</div>
                            <span class="float-right pull-right"><img src="../../../../../assets/images/icons/PNG/add_news.png" width="32px" (click)="addToReport(medium,result.topicLabel)" tooltip="Add article to report" /></span>
                          </button>
                          <span class="medium-headline">{{medium.headline | truncatetext:80}}</span><br>
                          <span class="medium-text">{{medium.text}}...</span><br>
                          <span class="medium-link"><a href="{{medium.url}}" target="_blank">read more</a></span><br/>
                          <div class="row">
                            <div class="col-lg-12" style="padding-left: 30px;">
                              <input type="text" name="note" class="note" placeholder="Note" [(ngModel)]="medium.note" (ngModelChange)="addNote(medium, medium.note)" style="width: 100%">
                            </div>
                          </div>
                      </accordion-group>
                      <div style="height: 20px"></div>
                  </ng-container>
                </accordion>
              </div>
            </div>
        </ng-container>
      </ng-container>
  </tab>
</tabset>

<div class="loading-graphic text-center" *ngIf="loading == true">
  <div style="height: 40px"></div>
  <img src="{{loadingImg}}" width="100px" />
</div>

<div class="text-center" *ngIf="!noResults && !noResultsLeft" style="padding-right: 15px;">
    <div style="height: 200px;"></div>
</div>

<div class="text-center" *ngIf="noResults" style="padding-right: 15px;">
    <div style="height: 50px;"></div>
    <div class="results-message text-center">
        No results found.<br>
    </div>
    <div style="height: 200px;"></div>
</div>
<div class="text-center" *ngIf="noResultsLeft" style="padding-right: 15px;">
    <div style="height: 50px;"></div>
    <div class="results-message text-center">
        No results left.<br>
    </div>
    <div style="height: 200px;"></div>
</div>
