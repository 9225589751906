<div class="row">
    <div class="col-md-3">
        <div style="height: 5px;"></div>
        <div class="label">
            <span>MARK AS:</span>
        </div>
    </div>
    <div class="col-md-3">
        <button type="button" class="btn btn-success btn-save-information" (click)="addRiskAssessment('approved')">Approved</button>
    </div>
    <div class="col-md-4">
        <button type="button" class="btn btn-warning btn-yellow" (click)="addRiskAssessment('further')">Further Analysis</button>
    </div>
    <div class="col-md-2">
        <button type="button" class="btn btn-danger btn-delete" (click)="addRiskAssessment('rejected')">Rejected&nbsp;</button>
    </div>
</div>
