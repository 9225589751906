import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertService, DataService, KYBService } from '../../_services';

@Component({
  selector: 'app-close-notification',
  standalone: true,
  imports: [ ReactiveFormsModule ],
  templateUrl: './closeNotification.component.html',
  styleUrls: ['./closeNotification.component.css']
})
export class CloseNotificationComponent implements OnInit {

  notification: any;

  public form: UntypedFormGroup;

  constructor(
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      note: new UntypedFormControl()
    });
  }

  close() {
    this.dataService.triggerCloseModalEvent(1);
  }

  onSubmit() {
    this.kybService.closeSomeNotification(this.notification.id, this.form.get('note').value).subscribe(
      data => {
        this.alertService.showSuccess('Notification closed successfully.');
        this.dataService.triggerCloseModalEvent(this.form.value);
      },
      error => {
        this.alertService.showError(error.message);
        this.dataService.triggerCloseModalEvent(this.form.value);
      }
    );

  }
}
