import { Component, OnInit} from '@angular/core';
import { SearchService, WebAlertService, WebDataService } from '../../../../_services/index';
import { config } from '../../../../../assets/config/configuration';
import * as FileSaver from 'file-saver';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'report',
    standalone: true,
    templateUrl: 'report.component.html',
    styleUrls: [ './report.component.css' ]
})

export class ReportComponent implements OnInit {

  reportLogo: any = config.reportLogo;

  approvedIcon: any = config.approvedIcon;
  furtherAnalysisIcon: any = config.furtherAnalysisIcon;
  rejectedIcon: any = config.rejectedIcon;

  lowRiskIcon: any = config.lowRiskIcon;
  mediumRiskIcon: any = config.mediumRiskIcon;
  highRiskIcon: any = config.highRiskIcon;

  username: string;
  currentDate: any;
  reportResults: any = {};
  result: any = {};
  htmlInput = '';
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  countryRisk = '';
  topics: any = [];
  mainTopics: any = [];
  searchQuery = '';
  pieChart = '';
  pepHitsNo = 0;
  sanctionHitsNo = 0;
  reportPepHitsNo = 0;
  reportSanctionHitsNo = 0;
  topicCounts: any;

  testResults: any = {};

  constructor (
    private alertService: WebAlertService,
    private dataService: WebDataService,
    private searchService: SearchService
  ) {}

  ngOnInit() {}

  timestampConverter(timestamp: any) {
    const date = new Date(timestamp);
    return date.toDateString();
  }

  generatePrompt() {
    if (confirm('Are you sure you want to generate report?')) {
      this.generateReport();
    }
  }

  generateReport() {
    this.reportSanctionHitsNo = 0;
    this.pepHitsNo = 0;
    this.sanctionHitsNo = 0;
    this.reportPepHitsNo = 0;
    this.pieChart = '';

    this.pepHitsNo = this.dataService.pepHitsNo.getValue();
    this.sanctionHitsNo = this.dataService.sanctionHitsNo.getValue();

    this.reportResults = this.dataService.reportResults.getValue();

    this.dataService.report_results.subscribe(
      message => {
        this.testResults = message;
      }
    );

    this.reportPepHitsNo = this.reportResults.pepHits.length;

    if (this.reportResults.sanctionHits !== undefined) {
      this.reportSanctionHitsNo = this.reportResults.sanctionHits.length;
    }

    if (this.sanctionHitsNo === null) {
      this.sanctionHitsNo = 0;
    }

    if (this.reportSanctionHitsNo === null) {
      this.reportSanctionHitsNo = 0;
    }

    if (this.reportPepHitsNo === null) {
      this.reportPepHitsNo = 0;
    }

    if (this.pepHitsNo === null) {
      this.pepHitsNo = 0;
    }

    if ((this.pepHitsNo === this.reportPepHitsNo) && (this.sanctionHitsNo === this.reportSanctionHitsNo)) {
      if (this.reportResults.riskAssessment !== null && this.reportResults.riskLevel !== null) {
        this.firstName = this.dataService.searchfirstName.getValue();
        this.lastName = this.dataService.searchlastName.getValue();
        this.dateOfBirth = this.dataService.searchDob.getValue();
        this.dataService.currentCountry.subscribe(
          message => {
            this.country = message;

            if (this.country === null) {
              this.countryRisk = null;
            } else {
              this.countryRisk = this.dataService.countryRisk.getValue();
            }
          }
        );

        this.searchQuery = this.dataService.searchQuery.getValue();
        this.topicCounts = this.dataService.topicCounts.getValue();

        if (this.reportResults.articles.length === 0 && this.reportPepHitsNo === 0 && this.reportSanctionHitsNo === 0) {
          this.noResults();
        } else {
          this.hasResults();
        }
      } else {
        window.alert('You need to assess the risk level and make your final decision before generating a report.');
        this.alertService.error('You need to assess the risk level and make your final decision before generating a report.');
      }
    } else {
      window.alert('The number of PEP/Sanction hits in the report must match the number of PEP/Sanction hits found. All hits must be evaluated.');
      this.alertService.error('The number of PEP/Sanction hits in the report must match the number of PEP/Sanction hits found. All hits must be evaluated.');
    }
  }

  hasResults() {
    this.htmlInput = "<html><head><style>table{font-family: 'Arial';font-size: 14px;}h4{font-family: 'Arial';font-size: 16px;color: #848484;}h5{font-family: 'Arial';font-size: 15px;color: #6b6a6a;}a{font-family: 'Arial';color: #848484;}.comment-text{font-family: 'Arial';font-size: 14px;color: #848484;}.pep-hit{font-family: 'Arial';color: #cc0000;font-size: 15px;}.pep-info{font-family: 'Arial';color: #848484;font-size: 14px;}.news-article{font-family: 'Arial';color: #0377B8;font-size: 15px;}.news-info{font-family: 'Arial';color: #848484;font-size: 14px;}.main-topic{font-family: 'Arial';color: #848484;font-size: 15px;text-decoration: underline;}.topic{font-family: 'Arial';color: #848484;font-size: 14px;}.credentials{font-family: 'Arial';text-align: right;color: #848484;font-size: 12px;}.search-info-title{font-family: 'Arial';color: #0377B8;}.search-info{font-family: 'Arial';color: #848484;}</style></head><body><table width=\"100%\"><tr><td colspan=\"3\"><img src=\"" + this.reportLogo + "\" style=\"width: 150px;\"/></td><td></td><td class=\"credentials\">THIS REPORT WAS BUILT BY " + localStorage.getItem('username').toUpperCase() + "<br/>DATE/TIME " + new Date().toLocaleString() + "</td></tr><tr><td colspan=\"5\" style=\"border-bottom: 1px solid #eee;\"><div style=\"height: 20px;\"></div></td></tr>";

    if (this.reportResults.articles === undefined) {
      this.pieChart = '';
    } else {
      this.pieChart = sessionStorage.getItem('pieChart');
    }

    this.htmlInput += "<tr><td><div style=\"20px;\"></div></td></tr><tr><td colspan=\"3\"><table width=\"100%\">";

    if (this.firstName !== null && this.lastName !== null) {
      this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr><tr><td class=\"search-info-title\">FIRST NAME</td><td class=\"search-info-title\">LAST NAME</td></tr><tr><td class=\"search-info\">" + this.firstName.toUpperCase() + "</td><td class=\"search-info\">" + this.lastName.toUpperCase() + "</td></tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    if (this.dateOfBirth !== null || this.country !== null) {
      this.htmlInput += "<tr>";

      if (this.dateOfBirth !== null) {
        this.htmlInput += "<td class=\"search-info-title\">DATE OF BIRTH</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      if (this.country !== null) {
        this.htmlInput += "<td class=\"search-info-title\">COUNTRY</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      this.htmlInput += "</tr><tr>";

      if (this.dateOfBirth !== null) {
        this.htmlInput += "<td class=\"search-info\">" + this.dateOfBirth  + "</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      if (this.country !== null) {
        this.htmlInput += "<td class=\"search-info\">" + this.country + "</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      this.htmlInput += "</tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    if (this.reportResults.riskAssessment !== null) {
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"2\">RISK ASSESSMENT</td></tr>";

      if (this.reportResults.riskAssessment === 'rejected') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.rejectedIcon + "\" width=\"17px\" />&nbsp;&nbsp;REJECTED</td>";
      }

      if (this.reportResults.riskAssessment === 'further') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.furtherAnalysisIcon + "\" width=\"17px\" />&nbsp;&nbsp;FURTHER ANALYSIS</td>";
      }

      if (this.reportResults.riskAssessment === 'approved') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.approvedIcon + "\" width=\"17px\" />&nbsp;&nbsp;APPROVED</td>";
      }
    }

    if (this.reportResults.riskLevel !== null) {
      if (this.reportResults.riskLevel === 'low') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.lowRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;LOW RISK</td>";
      }

      if (this.reportResults.riskLevel === 'medium') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.mediumRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;MEDIUM RISK</td>";
      }

      if (this.reportResults.riskLevel === 'high') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.highRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;HIGH RISK</td>";
      }
    }

    this.htmlInput += "</tr>";

    if (this.reportResults.riskAssessmentNote !== null && this.reportResults.riskAssesmentNote !== '') {
      this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"3\">COMMENT</td></tr>";
      this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";
      this.htmlInput += "<tr><td class=\"search-info\" colspan=\"3\">" + this.reportResults.riskAssessmentNote + "</td></tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    // tslint:disable-next-line:max-line-length
    if (this.topicCounts.reputationalRisk > 0 || this.topicCounts.corruptionRisk > 0 || this.topicCounts.pepRisk > 0 || this.topicCounts.moneyLaundering > 0 || this.topicCounts.terrorism > 0 || this.topicCounts.other > 0 || this.countryRisk !== null) {
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"2\">RISK FACTORS</td></tr>";

      if (this.countryRisk !== null) {
        this.htmlInput += "<tr><td class=\"search-info\">COUNTRY RISK</td><td class=\"search-info\">" + this.countryRisk + " out of 10</td></tr>";
      }

      if (this.topicCounts.reputationalRisk > 0) {
        // tslint:disable-next-line:max-line-length
        this.htmlInput += "<tr><td class=\"search-info\">REPUTATIONAL RISK</td><td class=\"search-info\">" + this.topicCounts.reputationalRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.corruptionRisk > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">CORRUPTION RISK</td><td class=\"search-info\">" + this.topicCounts.corruptionRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.pepRisk > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">PEP RISK</td><td class=\"search-info\">" + this.topicCounts.pepRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.moneyLaundering > 0) {
        // tslint:disable-next-line:max-line-length
        this.htmlInput += "<tr><td class=\"search-info\">MONEY LAUNDERING</td><td class=\"search-info\">" + this.topicCounts.moneyLaundering + " total hits</td></tr>";
      }

      if (this.topicCounts.terrorism > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">TERRORISM</td><td class=\"search-info\">" + this.topicCounts.terrorism + " total hits</td></tr>";
      }

      if (this.topicCounts.other > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">OTHER</td><td class=\"search-info\">" + this.topicCounts.other + " total hits</td></tr>";
      }
    }

    this.htmlInput += "</table></td>";

    this.htmlInput += "<td colspan=\"3\" style=\"text-align: center;\"><img src=\"" + this.pieChart + "\" width=\"250px\"/></td>";

    this.htmlInput += "</tr><tr><td><div style=\" height: 20px;\"></div></td></tr>";

    // tslint:disable-next-line:max-line-length
    this.htmlInput += "<tr><td><div style=\" height: 10px;\"></div></td></tr><tr><td colspan=\"5\" style=\"border-bottom: 1px solid #eee;\"></td></tr><tr><td><div style=\"height: 10px;\"></div></td></tr>";

    if (this.reportResults.pepHits !== undefined) {
      if (this.reportResults.pepHits.length !== 0) {
        this.htmlInput += "<tr><td><h4>PEP Hits</h4></td></tr>";

        for (let i = 0; i < this.reportResults.pepHits.length; i++) {
          this.htmlInput += "<tr><td colspan=\"5\"><table width=\"100%\"><tr><td class=\"pep-hit\" style=\"border-bottom: 3px solid #cc0000;padding-bottom: 10px;\"><b>" + this.reportResults.pepHits[i].entityName + " (" + this.reportResults.pepHits[i].source + ") - " + this.reportResults.pepHits[i].status + "</b></td></tr><tr><td class=\"pep-info\"><div style=\"height: 15px;\"></div><b>Birth date:</b> " + this.reportResults.pepHits[i].birthDates + "<br/><b>Description:</b> " + this.reportResults.pepHits[i].description + "<br/><b>AKA names:</b> " + this.reportResults.pepHits[i].akaNames + "<br/><b>First seen:</b> " + this.timestampConverter(this.reportResults.pepHits[i].firstSeen) + "<br/><b>Last seen:</b> " + this.timestampConverter(this.reportResults.pepHits[i].lastSeen) + "<br/>";

          if (this.reportResults.pepHits[i].note !== null) {
            this.htmlInput += "<b>Note:</b> " + this.reportResults.pepHits[i].note + "<br/>";
          }

          this.htmlInput += "</td></tr><tr><td><div style=\"height: 20px;\"></div></td></tr></table></td></tr>";
        }
      }
    }

    if (this.reportResults.sanctionHits !== undefined) {
      if (this.reportResults.sanctionHits.length !== 0) {
        this.htmlInput += "<tr><td><h4>Sanction Hits</h4></td></tr>";

        for (let i = 0; i < this.reportResults.sanctionHits.length; i++) {
          this.htmlInput += "<tr><td colspan=\"5\"><table width=\"100%\"><tr><td class=\"pep-hit\" style=\"border-bottom: 3px solid #cc0000;padding-bottom: 10px;\"><b>" + this.reportResults.sanctionHits[i].entityName + " (" + this.reportResults.sanctionHits[i].source + ") - " + this.reportResults.sanctionHits[i].status + "</b></td></tr><tr><td class=\"pep-info\"><div style=\"height: 15px;\"></div><b>Birth date:</b> " + this.reportResults.sanctionHits[i].birthDates + "<br/><b>Description:</b> " + this.reportResults.sanctionHits[i].description + "<br/><b>AKA names:</b> " + this.reportResults.sanctionHits[i].akaNames + "<br/><b>First seen:</b> " + this.timestampConverter(this.reportResults.sanctionHits[i].firstSeen) + "<br/><b>Last seen:</b> " + this.timestampConverter(this.reportResults.sanctionHits[i].lastSeen) + "<br/>";

          if (this.reportResults.sanctionHits[i].note !== null) {
            this.htmlInput += "<b>Note:</b> " + this.reportResults.sanctionHits[i].note + "<br/>";
          }

          this.htmlInput += "</td></tr><tr><td><div style=\"height: 20px;\"></div></td></tr></table></td></tr>";
        }
      }
    }

    this.htmlInput += "<tr><td><div style=\"height: 10px;\"></div></td></tr>";

    this.topics = [];
    if (this.reportResults.articles !== undefined) {
      if (this.reportResults.articles.length > 0) {
        this.htmlInput += "<tr><td colspan=\"5\"><h4>News Articles</h4></td></tr>";

        for (let k = 0; k < this.reportResults.articles.length; k++) {
          this.topics.push({"topic": this.reportResults.articles[k].topic, "topicNote": this.reportResults.articles[k].topicNote});
        }

        this.topics = this.removeDuplicates(this.topics);

        for (let l = 0; l < this.topics.length; l++) {
          const topic = this.topics[l].topic.replace(':', ': ');

          if (this.topics[l].topicNote !== null) {
            // tslint:disable-next-line:max-line-length
            this.htmlInput += "<tr><td colspan=\"5\"><table width=\"100%\"><tr><td class=\"topic\">" + topic.toUpperCase() + " - <b>" + this.topics[l].topicNote + "</b></td></tr>";
          } else {
            this.htmlInput += "<tr><td colspan=\"5\"><table width=\"100%\"><tr><td class=\"topic\">" + topic.toUpperCase() + "</td></tr>";
          }

          this.htmlInput += "<tr><td><div style=\"height: 10px;\"></div></td></tr>";

          for (let j = 0; j < this.reportResults.articles.length; j++) {
            if (this.topics[l].topic === this.reportResults.articles[j].topic) {
              // tslint:disable-next-line:max-line-length
              this.htmlInput += "<tr><td class=\"news-article\" style=\"border-bottom: 3px solid #0377B8;padding-bottom: 10px;\"><b>" + this.reportResults.articles[j].headline + "</b></td></tr><tr><td class=\"news-info\"><div style=\"height: 15px;\"></div><b>Published:</b> " + this.timestampConverter(this.reportResults.articles[j].publicationDate) + "<br/>" + this.reportResults.articles[j].text + "...<br/><a href=\"" + this.reportResults.articles[j].url + "\">read more</a>";

              if (this.reportResults.articles[j].note !== null) {
                this.htmlInput += "<br/><div style=\"height: 5px;\"></div><b>Note:</b> " + this.reportResults.articles[j].note + "<br/></td></tr><tr><td><div style=\"height: 20px;\"></div></td></tr>";
              } else {
                this.htmlInput += "</td></tr><tr><td><div style=\"height: 20px;\"></div></td></tr>";
              }
            }
          }

          this.htmlInput += "</table></td></tr><tr><td><div style=\"height: 20px;\"></div></td></tr>";
        }
      }
      this.htmlInput += "</table></body></html>";
    }

    this.searchService.getPdfReport(this.htmlInput).subscribe(
      data => {
        this.dataService.setCountry(null);
      },
      error => {
        const binaryImg = atob(error.error.text);
        const length = binaryImg.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);
        const today = new Date();
        const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        for (let i = 0; i < length; i++) {
          uintArray[i] = binaryImg.charCodeAt(i);
        }

        const file = new Blob([uintArray], { type: 'application/pdf' });
        const pdf = URL.createObjectURL(file);
        FileSaver.saveAs(file, this.searchQuery + "-" + date + "-report.pdf");
      }
    );
  }

  noResults() {
    this.htmlInput = "<html><head><style>table{font-family: 'Arial';font-size: 14px;}h4{font-family: 'Arial';font-size: 16px;color: #848484;}h5{font-family: 'Arial';font-size: 15px;color: #6b6a6a;}a{font-family: 'Arial';color: #848484;}.comment-text{font-family: 'Arial';font-size: 14px;color: #848484;}.pep-hit{font-family: 'Arial';color: #cc0000;font-size: 15px;}.pep-info{font-family: 'Arial';color: #848484;font-size: 14px;}.news-article{font-family: 'Arial';color: #0377B8;font-size: 15px;}.news-info{font-family: 'Arial';color: #848484;font-size: 14px;}.main-topic{font-family: 'Arial';color: #848484;font-size: 15px;text-decoration: underline;}.topic{font-family: 'Arial';color: #848484;font-size: 14px;}.credentials{font-family: 'Arial';text-align: right;color: #848484;font-size: 12px;}.search-info-title{font-family: 'Arial';color: #0377B8;}.search-info{font-family: 'Arial';color: #848484;}</style></head><body><table width=\"100%\"><tr><td colspan=\"3\"><img src=\"" + this.reportLogo + "\" style=\"width: 150px;\"/></td><td></td><td class=\"credentials\">THIS REPORT WAS BUILT BY " + localStorage.getItem('username').toUpperCase() + "<br/>DATE/TIME " + new Date().toLocaleString() + "</td></tr><tr><td colspan=\"5\" style=\"border-bottom: 1px solid #eee;\"><div style=\"height: 20px;\"></div></td></tr>";

    this.htmlInput += "<tr><td><div style=\"height:20px;\"></div></td></tr><tr><td colspan=\"4\"><table width=\"120%\">";

    if (this.firstName !== null && this.lastName !== null) {
      this.htmlInput += "<tr><td colspan=\"4\"><div style=\"height: 15px;\"></div></td></tr><tr><td class=\"search-info-title\">FIRST NAME</td><td class=\"search-info-title\">LAST NAME</td></tr><tr><td class=\"search-info\">" + this.firstName.toUpperCase() + "</td><td class=\"search-info\">" + this.lastName.toUpperCase() + "</td></tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    if (this.dateOfBirth !== null || (this.country !== null && this.country !== '')) {
      this.htmlInput += "<tr>";

      if (this.dateOfBirth !== null) {
        this.htmlInput += "<td class=\"search-info-title\">DATE OF BIRTH</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      if (this.country !== null) {
        this.htmlInput += "<td class=\"search-info-title\">COUNTRY</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      this.htmlInput += "</tr><tr>";

      if (this.dateOfBirth !== null) {
        this.htmlInput += "<td class=\"search-info\">" + this.dateOfBirth  + "</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      if (this.country !== null) {
        this.htmlInput += "<td class=\"search-info\">" + this.country + "</td>";
      } else {
        this.htmlInput += "<td></td>";
      }

      this.htmlInput += "</tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    if (this.reportResults.riskAssessment !== null) {
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"2\">RISK ASSESSMENT</td></tr>";

      if (this.reportResults.riskAssessment === 'rejected') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.rejectedIcon + "\" width=\"17px\" />&nbsp;&nbsp;REJECTED</td>";
      }

      if (this.reportResults.riskAssessment === 'further') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.furtherAnalysisIcon + "\" width=\"17px\" />&nbsp;&nbsp;FURTHER ANALYSIS</td>";
      }

      if (this.reportResults.riskAssessment === 'approved') {
        this.htmlInput += "<tr><td class=\"search-info\"><img src=\"" + this.approvedIcon + "\" width=\"17px\" />&nbsp;&nbsp;APPROVED</td>";
      }
    }

    if (this.reportResults.riskLevel !== null) {
      if (this.reportResults.riskLevel === 'low') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.lowRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;LOW RISK</td>";
      }

      if (this.reportResults.riskLevel === 'medium') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.mediumRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;MEDIUM RISK</td>";
      }

      if (this.reportResults.riskLevel === 'high') {
        this.htmlInput += "<td class=\"search-info\"><img src=\"" + this.highRiskIcon + "\" width=\"19px;\" />&nbsp;&nbsp;HIGH RISK</td>";
      }
    }

    this.htmlInput += "</tr>";

    if (this.reportResults.riskAssessmentNote !== null && this.reportResults.riskAssesmentNote !== '') {
      this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"4\">COMMENT</td></tr>";
      this.htmlInput += "<tr><td class=\"search-info\" colspan=\"4\">" + this.reportResults.riskAssessmentNote + "</td></tr>";
    }

    this.htmlInput += "<tr><td><div style=\"height: 15px;\"></div></td></tr>";

    // tslint:disable-next-line:max-line-length
    if (this.topicCounts.reputationalRisk > 0 || this.topicCounts.corruptionRisk > 0 || this.topicCounts.pepRisk > 0 || this.topicCounts.moneyLaundering > 0 || this.topicCounts.terrorism > 0 || this.topicCounts.other > 0 || this.countryRisk !== null) {
      this.htmlInput += "<tr><td class=\"search-info-title\" colspan=\"2\">RISK FACTORS</td></tr>";

      if (this.countryRisk !== null) {
        this.htmlInput += "<tr><td class=\"search-info\">COUNTRY RISK</td><td class=\"search-info\">" + this.countryRisk + " out of 10</td></tr>";
      }

      if (this.topicCounts.reputationalRisk > 0) {
        // tslint:disable-next-line:max-line-length
        this.htmlInput += "<tr><td class=\"search-info\">REPUTATIONAL RISK</td><td class=\"search-info\">" + this.topicCounts.reputationalRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.corruptionRisk > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">CORRUPTION RISK</td><td class=\"search-info\">" + this.topicCounts.corruptionRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.pepRisk > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">PEP RISK</td><td class=\"search-info\">" + this.topicCounts.pepRisk + " total hits</td></tr>";
      }

      if (this.topicCounts.moneyLaundering > 0) {
        // tslint:disable-next-line:max-line-length
        this.htmlInput += "<tr><td class=\"search-info\">MONEY LAUNDERING</td><td class=\"search-info\">" + this.topicCounts.moneyLaundering + " total hits</td></tr>";
      }

      if (this.topicCounts.terrorism > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">TERRORISM</td><td class=\"search-info\">" + this.topicCounts.terrorism + " total hits</td></tr>";
      }

      if (this.topicCounts.other > 0) {
        this.htmlInput += "<tr><td class=\"search-info\">OTHER</td><td class=\"search-info\">" + this.topicCounts.other + " total hits</td></tr>";
      }
    }

    this.htmlInput += "</table></td>";

    this.htmlInput += "<td colspan=\"1\" style=\"text-align: center;\"><img src=\"" + this.approvedIcon + "\" width=\"100px\"/></td>";

    this.htmlInput += "</tr><tr><td><div style=\" height: 20px;\"></div></td></tr>";

    this.htmlInput += "<tr><td><div style=\" height: 10px;\"></div></td></tr><tr><td colspan=\"5\" style=\"border-bottom: 1px solid #eee;\"></td></tr><tr><td><div style=\"height: 10px;\"></div></td></tr>";

    this.htmlInput += "<tr style=\"background-color: #d4edda;border: 1px solid #c3e6cb;\"><td colspan=\"5\" style=\"padding: 20px;color: #155724\"><p style=\"text-align: center;\"><br />No PEP hits found for subject.</p></td></tr>";

    this.htmlInput += "<tr><td><div style=\" height: 20px;\"></div></td></tr>";

    this.htmlInput += "<tr style=\"background-color: #d4edda;border: 1px solid #c3e6cb;\"><td colspan=\"5\" style=\"padding: 20px;color: #155724\"><p style=\"text-align: center;\"><br />No Sanction hits found for subject.</p></td></tr>";

    this.htmlInput += "<tr><td><div style=\" height: 20px;\"></div></td></tr>";

    // tslint:disable-next-line:max-line-length
    if (this.topicCounts.reputationalRisk === 0 && this.topicCounts.corruptionRisk === 0 && this.topicCounts.pepRisk === 0 && this.topicCounts.moneyLaundering === 0 && this.topicCounts.terrorism === 0 && this.topicCounts.other === 0) {
      this.htmlInput += "<tr style=\"background-color: #d4edda;border: 1px solid #c3e6cb;\"><td colspan=\"5\" style=\"padding: 20px;color: #155724\"><p style=\"text-align: center;\"><br />No relevant News risk items added into report for subject.</p></td></tr>";

      this.htmlInput += "<tr><td><div style=\" height: 20px;\"></div></td></tr></table></body></html>";
    } else {
      this.htmlInput += "<tr><td><div style=\"height: 10px;\"></div></td></tr></table></body></html>";
    }

    this.searchService.getPdfReport(this.htmlInput).subscribe(
      data => {
        this.dataService.setCountry(null);
      },
      error => {
        const binaryImg = atob(error.error.text);
        const length = binaryImg.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);
        const today = new Date();
        const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        for (let i = 0; i < length; i++) {
          uintArray[i] = binaryImg.charCodeAt(i);
        }

        const file = new Blob([uintArray], { type: 'application/pdf' });
        const pdf = URL.createObjectURL(file);
        FileSaver.saveAs(file, this.searchQuery + "-" + date + "-report.pdf");
      }
    );
  }

  removeDuplicates(arr) {
    return arr.reduce((p, c) => {
      const key = [c.topic, c.topicNote].join('|');
      if (p.temp.indexOf(key) === -1) {
        p.out.push(c);
        p.temp.push(key);
      }
      return p;
    }, { temp: [], out: [] }).out;
  }
}
