import { Component, OnInit, Input } from '@angular/core';
import { AlertService, CallsService, DataService, KYBService } from '../../../../../../../_services';
import { Document, RoleUpdate } from '../../../../../../../_models/kyb';
import { config } from '../../../../../../../../assets/configuration';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MediumTextPipe } from '../../../../../../../mediumText.pipe';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'replace-document',
  standalone: true,
  imports: [ BsDatepickerModule, CommonModule, FormsModule, MediumTextPipe ],
  templateUrl: 'replaceDocument.component.html',
  styleUrls: ['./replaceDocument.component.css']
})

export class ReplaceDocumentComponent implements OnInit {

  @Input() entityId: any;
  @Input() roles: any;
  @Input() forRoleId: any;
  @Input() documentTypeId: any;
  @Input() documentId: any;
  @Input() validTo: any;

  primaryName = '';
  dateOfBirth: any;
  address = '';
  vat = '';
  taxCountry = '';
  nationality = '';
  contactName = '';
  contactPhone = '';
  contactEmail = '';
  personPhone = '';
  personEmail = '';
  docDescription = '';
  roleId = '';
  showForm = false;
  showCompanyForm = false;
  showPersonForm = false;
  multipleDocuments = false;
  delegateName = '';
  delegatecontactEmail = '';
  entity: any = {};
  document: Document;
  documentFile: File;
  documentFile2: File = null;
  currentStack: any;
  currentToDo: any;
  updatedRole: RoleUpdate;
  isocountries: any = config.isocountries;
  nationalities: any = config.nationalities;
  loading = false;
  loadingImg: any = config.loadingImg;
  // boolean to see if the document type has a global validTo value set or not
  validToSet = false;
  allowableMimeTypes = '';
  e1Id: any;
  e2Id: any;

  rolesResponse: any = {};
  documentTypesResponse: any = {};
  documentTypes: any = [];

  documentResponse: any = {};
  documentIds: any = [];
  newDocumentId: any;
  documentType = '';
  description = '';
  getRoleResponse: any = {};
  additionalDocumentIds: any = [];
  requiredDocumentIds: any = [];
  requiredDocumentTypes: any = [];
  documentTypeValidTo: any;
  currentRoleId = '';

  formDataFile: File;
  basicInformation: any = {};

  nationalityFilled = false;
  taxCountryFilled = false;

  fileSelected = false;

  constructor(
    private callService: CallsService,
    private kybService: KYBService,
    private dataService: DataService,
    private alertService: AlertService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.multipleDocuments = false;
    (document.getElementById('role-selector') as HTMLSelectElement).disabled = true;
    (document.getElementById('document-type-selector') as HTMLSelectElement).disabled = true;
    this.allowableMimeTypes = '';
    this.showForm = false;
    this.showCompanyForm = false;
    this.showPersonForm = false;
    this.dataService.stack.subscribe(message => (this.currentStack = message));
    this.dataService.toDo.subscribe(message => (this.currentToDo = message));

    if (this.entityId === '') {
      this.kybService.getAllRoles().subscribe(
        data => {
          this.rolesResponse = data;
          this.roles = this.rolesResponse.data;
        },
        error => {}
      );
    } else {
      this.kybService.getRolesForEntity(this.entityId).subscribe(data => {
        this.rolesResponse = data;
        this.roles = [];

        for (let i = 0; i < this.rolesResponse.data.length; i++) {
          this.roles.push(this.rolesResponse.data[i]);
        }
      });
    }

    if (this.forRoleId !== undefined) {
      this.roleId = this.forRoleId;
      this.dataService.setRoleId(this.roleId);

      this.kybService.getRole(this.roleId).subscribe(
        data => {
          this.getRoleResponse = data;
          this.e1Id = this.getRoleResponse.data.e1Instance.id;

          if (this.getRoleResponse.data.e2Instance !== null) {
            this.e2Id = this.getRoleResponse.data.e2Instance.id;
          } else {
            this.e2Id = null;
          }
        },
        error => {}
      );
    }

    this.kybService.getDocumentTypes().subscribe(
      data => {
        this.documentTypesResponse = data;
        this.documentTypes = this.documentTypesResponse.data;

        if (this.documentTypeId !== undefined) {
          for (let j = 0; j < this.documentTypes.length; j++) {
            if (this.documentTypes[j].documentTypeName === 'jurisdictionDetails') {
              this.documentTypes.splice(j, 1);
            }

            if (this.documentTypes[j].id === this.documentTypeId) {
              this.documentType = this.documentTypeId;

              if (this.documentTypes[j].documentTypeName.indexOf('identityCard') !== -1 || this.documentTypes[j].documentTypeName.indexOf('drivingLicense') !== -1 || this.documentTypes[j].documentTypeName.indexOf('driversLicense') !== -1) {
                this.multipleDocuments = true;
              }

              if (this.documentTypes[j].documentExpiration !== null) {
                this.validToSet = true;
                this.documentTypeValidTo = null;
                const expiresInDays = this.secondsToDays(this.documentTypes[j].documentExpiration);
                this.validTo = new Date();
                this.validTo.setDate(this.validTo.getDate() + expiresInDays);
              } else {
                this.validToSet = false;
              }

              if (this.documentTypes[j].allowableMimeTypes.indexOf('custom/form') !== -1) {
                this.showForm = true;

                // TODO: this is hardcoded, same thing in addDocument
                if (this.documentTypes[j].id === 16) {
                  this.showCompanyForm = true;
                  this.showPersonForm = false;
                } else {
                  this.showPersonForm = true;
                  this.showCompanyForm = false;
                }
              } else {
                this.showForm = false;
                this.showCompanyForm = false;
                this.showPersonForm = false;
              }
            }
          }
        }

        for (let l = 0; l < this.documentTypes.length; l++) {
          if (this.documentTypes[l].documentTypeName === 'jurisdictionDetails') {
            this.documentTypes.splice(l, 1);
          }
        }
      },
      error => {}
    );
  }

  secondsToDays(seconds) {
    const days = Math.round(seconds / 86400);
    return days;
  }

  roleIdChanged() {
    this.dataService.setRoleId(this.roleId);
  }

  upload() {
    if (this.documentFile.size / 1024 / 1024 >= 20) {
      window.alert('The file you selected is larger than 20MB.');
    }

    this.kybService.updateDocumentMetadata(this.documentId, null, 'REPLACED', null, null, null).subscribe(
      data => {
        this.loading = true;
        this.document = new Document();
        this.document.docTypeId = this.documentType;

        for (let u = 0; u < this.documentTypes.length; u++) {
          if (this.documentTypes[u].id === this.documentType) {
            this.allowableMimeTypes = this.documentTypes[u].allowableMimeTypes;
          }
        }

        this.document.retainTo = '2030-10-20T11:52:05.381';
        this.document.validTo = this.datepipe.transform(this.validTo, 'yyyy-MM-dd') + 'T00:00:01';

        if (this.documentFile2 === null) {
          this.document.totalFiles = 1;
          this.document.mimeType = this.documentFile.type;

          if (this.docDescription !== '') {
            this.document.description = this.docDescription;
          }

          if (this.allowableMimeTypes.indexOf(this.document.mimeType) !== -1) {
            this.currentRoleId = this.dataService.roleId.getValue();

            this.kybService.createDocument(this.document, Number(this.entityId), this.currentRoleId, this.e1Id, this.e2Id).subscribe(
              documentData => {
                this.documentResponse = documentData;
                this.newDocumentId = this.documentResponse.data.documentId;

                this.kybService
                  .saveFile(this.documentFile, this.documentResponse.data.documentId, this.documentResponse.data.fileIds[0])
                  .subscribe(
                    () => {
                      for (let i = 0; i < this.documentTypes.length; i++) {
                        if (this.documentTypes[i].id === this.documentType) {
                          this.description = this.documentTypes[i].description;
                        }
                      }

                      this.currentRoleId = this.dataService.roleId.getValue();

                      if (this.currentStack !== undefined) {
                        if (this.currentStack.documents === undefined) {
                          this.currentStack.documents = [];
                        }

                        this.currentStack.documents.push({
                          description: this.description,
                          role: 'Role with ID ' + this.currentRoleId,
                          type: this.document.mimeType
                        });

                        this.dataService.setCurrentStack(this.currentStack);
                      }

                      this.dataService.triggerCloseModalEvent(3);
                      this.dataService.triggerRefreshEvent(3);
                      this.allowableMimeTypes = '';
                      this.alertService.showSuccess('Document replaced.');
                      this.loading = false;
                    },
                    error => {}
                  );

                this.documentResponse = {};
              },
              error => {
              }
            );
        } else {
            window.alert(`The file you selected doesn't have the correct format.`);
          }
        } else {
          this.document.totalFiles = 2;
          this.document.mimeType = this.documentFile.type;

          if (this.docDescription !== '') {
            this.document.description = this.docDescription;
          }

          if (this.allowableMimeTypes.indexOf(this.document.mimeType) !== -1) {
            this.currentRoleId = this.dataService.roleId.getValue();

            this.kybService.createDocument(this.document, Number(this.entityId), this.currentRoleId, this.e1Id, this.e2Id).subscribe(
              documentData => {
                this.documentResponse = documentData;
                this.newDocumentId = this.documentResponse.data.documentId;

                this.kybService
                  .saveFile(this.documentFile, this.documentResponse.data.documentId, this.documentResponse.data.fileIds[0])
                  .subscribe(
                    () => {
                      this.kybService.saveFile(this.documentFile2, this.documentResponse.data.documentId,
                        this.documentResponse.data.fileIds[1]).subscribe(
                        () => {
                          for (let i = 0; i < this.documentTypes.length; i++) {
                            if (this.documentTypes[i].id === this.documentType) {
                              this.description = this.documentTypes[i].description;
                            }
                          }

                          this.currentRoleId = this.dataService.roleId.getValue();

                          if (this.currentStack !== undefined) {
                            if (this.currentStack.documents === undefined) {
                              this.currentStack.documents = [];
                            }

                            this.currentStack.documents.push({
                              description: this.description,
                              role: 'Role with ID ' + this.currentRoleId,
                              type: this.document.mimeType
                            });

                            this.dataService.setCurrentStack(this.currentStack);
                          }

                          this.dataService.triggerCloseModalEvent(3);
                          this.dataService.triggerRefreshEvent(3);
                          this.allowableMimeTypes = '';
                          this.alertService.showSuccess('Document replaced.');
                          this.loading = false;
                          this.documentResponse = {};
                        },
                        error => {}
                      );
                    },
                    error => {}
                  );
              },
              error => {}
            );
        } else {
            window.alert(`The file you selected doesn't have the correct format.`);
          }
        }
      },
      error => {}
    );
  }

  uploadFormData() {
    this.kybService.updateDocumentMetadata(this.documentId, null, 'REPLACED', null, null, null).subscribe(
      data => {
        this.loading = true;
        this.document = new Document();
        this.document.docTypeId = this.documentType;
        this.document.totalFiles = 1;

        if (this.docDescription !== '') {
          this.document.description = this.docDescription;
        }

        for (let u = 0; u < this.documentTypes.length; u++) {
          if (this.documentTypes[u].id === this.documentType) {
            this.allowableMimeTypes = this.documentTypes[u].allowableMimeTypes;
          }
        }

        this.document.retainTo = '2030-10-20T11:52:05.381';

        for (let j = 0; j < this.documentTypes.length; j++) {
          if (this.documentTypes[j].id === this.documentTypeId) {
            this.document.validTo = null;
          }
        }

        this.basicInformation = {};
        this.basicInformation.nationality = this.nationality;
        this.basicInformation.dateOfBirth =
        this.datepipe.transform(this.dateOfBirth, 'yyyy-MM-dd') + 'T00:00:01';
        this.basicInformation.address = this.address;
        this.basicInformation.personPhone = this.personPhone;
        this.basicInformation.personEmail = this.personEmail;
        const file = new Blob([JSON.stringify(this.basicInformation)], {type: 'text/plain'});
        this.formDataFile = this.blobToFile(file, 'personalDetails');
        this.document.mimeType = this.formDataFile.type;

        if (this.showPersonForm === true) {
          this.kybService.createDocument(this.document, Number(this.entityId), this.roleId, this.e1Id, this.e2Id).subscribe(
            documentData => {
              this.documentResponse = documentData;
              this.newDocumentId = this.documentResponse.data.documentId;

              this.kybService
                .saveFile(
                  this.formDataFile,
                  this.documentResponse.data.documentId,
                  this.documentResponse.data.fileIds[0]
                )
                .subscribe(
                  fileData => {
                    for (let i = 0; i < this.documentTypes.length; i++) {
                      if (this.documentTypes[i].id === this.documentType) {
                        this.description = this.documentTypes[i].description;
                      }
                    }

                    if (this.currentStack !== undefined) {
                      if (this.currentStack.documents === undefined) {
                        this.currentStack.documents = [];
                      }

                      this.currentStack.documents.push({
                        description: this.description,
                        role: 'Role with ID ' + this.roleId,
                        type: this.document.mimeType
                      });

                      this.dataService.setCurrentStack(this.currentStack);
                    }

                    this.currentStack.documents.push({
                      description: this.description,
                      role: 'Role with ID ' + this.roleId,
                      type: this.document.mimeType
                    });

                    this.dataService.triggerCloseModalEvent(3);
                    this.dataService.triggerRefreshEvent(3);
                    this.allowableMimeTypes = '';
                    this.alertService.showSuccess('Document replaced.');
                    this.loading = false;
                  },
                  error => {}
                );

              this.documentResponse = {};
            },
            error => {
            }
          );
        } else {
          this.kybService.createDocument(this.document, Number(this.entityId), this.roleId, this.e1Id, this.e2Id).subscribe(
            documentData => {
              this.documentResponse = documentData;
              this.newDocumentId = this.documentResponse.data.documentId;
              this.basicInformation = {};
              this.basicInformation.contactName = this.contactName;
              this.basicInformation.contactEmail = this.contactEmail;
              this.basicInformation.contactPhone = this.contactPhone;
              this.basicInformation.vat = this.vat;
              this.basicInformation.taxCountry = this.taxCountry;
              this.basicInformation.address = this.address;

              const fileData = new Blob([JSON.stringify(this.basicInformation)], {
                type: 'text/plain'
              });

              this.formDataFile = this.blobToFile(fileData, 'companyDetails');

              this.kybService
                .saveFile(
                  this.formDataFile,
                  this.documentResponse.data.documentId,
                  this.documentResponse.data.fileIds[0]
                )
                .subscribe(
                  savedFile => {
                    for (let i = 0; i < this.documentTypes.length; i++) {
                      if (this.documentTypes[i].id === this.documentType) {
                        this.description = this.documentTypes[i].description;
                      }
                    }

                    this.currentStack.documents.push({
                      description: this.description,
                      role: 'Role with ID ' + this.roleId,
                      type: this.document.mimeType
                    });

                    this.dataService.setCurrentStack(this.currentStack);
                    this.dataService.triggerCloseModalEvent(3);
                    this.dataService.triggerRefreshEvent(3);
                    this.allowableMimeTypes = '';
                    this.alertService.showSuccess('Document replaced.');
                    this.loading = false;
                  },
                  error => {}
                );

              this.documentResponse = {};
            },
            error => {
            }
          );
        }
    },
    error => {
    });
  }

  isNationalityFilled() {
    this.nationalityFilled = true;
  }

  isTaxCountryFilled() {
    this.taxCountryFilled = true;
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;

    return theBlob;
  }

  processDocument(files: FileList, fileName: any) {
    if (files.item(0) === null) {
      this.fileSelected = false;
    } else {
      if (fileName === 'file1') {
        this.documentFile = files.item(0);
        this.fileSelected = true;
      }

      if (fileName === 'file2') {
        this.documentFile2 = files.item(0);
        this.fileSelected = true;
      }
    }
  }

  checkValidTo() {
    if (this.validTo === null || this.validTo === undefined) {
      this.validTo = '';
    }
  }

  validatePersonForm() {
    if (
      this.nationality !== '' &&
      this.dateOfBirth !== '' &&
      this.address !== '' &&
      this.personPhone !== '' &&
      this.personEmail !== '' &&
      this.nationalityFilled === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateCompanyForm() {
    if (
      this.taxCountry !== '' &&
      this.vat !== '' &&
      this.address !== '' &&
      this.contactName !== '' &&
      this.contactPhone !== '' &&
      this.contactEmail !== '' &&
      this.taxCountryFilled === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  typeChanged() {
    for (let i = 0; i < this.documentTypes.length; i++) {
      if (this.documentTypes[i].id === this.documentType) {
        if (this.documentTypes[i].documentTypeName.indexOf('identityCard') !== -1 || this.documentTypes[i].documentTypeName.indexOf('drivingLicense') !== -1 || this.documentTypes[i].documentTypeName.indexOf('driversLicense') !== -1) {
          this.multipleDocuments = true;
        }

        if (this.documentTypes[i].documentExpiration !== null) {
          this.validToSet = true;
          this.validTo = null;
          const expiresInDays = this.secondsToDays(this.documentTypes[i].documentExpiration);
          this.validTo = new Date();
          this.validTo.setDate(this.validTo.getDate() + expiresInDays);
        } else {
          this.validToSet = false;
        }

        if (this.documentTypes[i].allowableMimeTypes.indexOf('custom/form') !== -1) {
          this.showForm = true;

          if (this.documentTypes[i].id === 16) {
            this.showCompanyForm = true;
            this.showPersonForm = false;
          } else {
            this.showPersonForm = true;
            this.showCompanyForm = false;
          }
        } else {
          this.showForm = false;
          this.showCompanyForm = false;
          this.showPersonForm = false;
        }
      }
    }
  }
}
