import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertService, KYBService } from '../../_services';

/**
 * Implements the user account page
*/
@Component({
    selector: 'app-hidden-tags',
    standalone: true,
    imports: [ FormsModule, NgSelectModule ],
    templateUrl: 'hiddenTags.component.html',
    styleUrls: [ './hiddenTags.component.css' ]
})

export class HiddenTagsComponent implements OnInit {

  tagsResponse: any = {};
  tags: any = [];
  selectedTags: any = [];
  userResponse: any = {};

  constructor (
    private kybService: KYBService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.kybService.getCurrentUser().subscribe(
      data => {
        this.userResponse = data;
        this.kybService.getAllTags().subscribe(
          tagsResponse => {
            this.selectedTags = [];
            this.tagsResponse = tagsResponse;
            this.tags = this.tagsResponse.data;
            for (let i = 0; i < this.userResponse?.data?.hiddenTags?.length; i++) {
                this.selectedTags.push(this.userResponse.data.hiddenTags[i].id);
            }
          },
          error => {
          }
        );
      },
      error => {}
    );
  }

  save() {
    const userId = localStorage.getItem('identifier');
    this.kybService.updateUser(userId, {'hiddenTags': this.selectedTags}).subscribe(
      data => {
        this.alertService.showSuccess('Hidden tags saved successfully');
        this.ngOnInit();
      },
      error => {
      }
    );
  }
}
