<form #passwordForm="ngForm" class="editForm" novalidate>
  <h3 class="text-center">Search Engine Screener Settings</h3>
  <br />
  <div style="height: 10px;"></div>
  <div style="height: 10px;"></div>
  <button
    class="bbtn btn-success btn-add-new"
    (click)="addNew(addTemplate)"
    style="background-color:#355B89;border-color: #355B89;"
  >
    Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
  </button>
  <div style="height: 10px;"></div>
  <table class="table-search-engine table table-responsive table-hover" width="100%" style="text-align: left;">
    <thead>
      <tr>
        <th style="padding: 1em;"></th>
        <th>Search engine</th>
        <th>Keywords</th>
        <th>Risk Type</th>
        <th>Max. hits</th>
        <th>Affected Entity Types</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="searchQueries.length > 0">
      <tr *ngFor="let searchQuery of searchQueries; let i = index" class="search-engine-screener">
        <td [ngClass]="{'activate-border': toggleHiddenEntries && i === toggleEntriesId}" class="details-control" style="padding: 1em;">
          <i class="fa fa-plus-square" *ngIf="toggleEntriesId === -1 ? !toggleHiddenEntries && searchQuery.affectedEntityTypes.length > 6 : toggleHiddenEntries && toggleEntriesId != i && searchQuery.affectedEntityTypes.length > 6" (click)="showRemaingInformation(i)" style="color: green; cursor: pointer;"></i>
          <i class="fa fa-plus-square" *ngIf="searchQuery.affectedEntityTypes.length <= 6" style="color: grey;" mute></i>
          <i class="fa fa-minus-square" *ngIf="toggleHiddenEntries && i === toggleEntriesId" (click)="hideRemaingInformation()" style="color: red; cursor: pointer;"></i>
        </td>
        <td>{{ searchQuery?.searchEngineType }}</td>
        <td>
          <ul style="padding-left: 16px; text-align: start;">
            <li *ngFor="let queryKeyword of nonEmptyKeywords(searchQuery?.queryKeywords)">
              {{ queryKeyword }}
            </li>
          </ul>
        </td>
        <td>{{ searchQuery?.screeningRisk?.riskType }}</td>
        <td>{{ searchQuery?.maxHits }}</td>
        <td>
          <ul style="text-align: left; padding-left: 16px;">
            <li style="display: list-item;" *ngFor="let entityType of searchQuery.affectedEntityTypes; index as ind" [ngClass]="{'document-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-elements-extra-elements': toggleHiddenEntries && i != toggleEntriesId}" [ngStyle]="{'display': !toggleHiddenEntries ? 'none' : 'list-item'}">
              {{ entityType?.legalForm }}
            </li>
            <li *ngFor="let entityType of searchQuery.affectedEntityTypes; index as ind" [ngClass]="{'document-elements-extra-elements': !toggleHiddenEntries || (toggleHiddenEntries && i === toggleEntriesId && ind < 6), 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && ind < 6}" style="text-align: start;" [ngStyle]="{'display': !toggleHiddenEntries && ind < 6 ? 'list-item' : 'none'}">
              {{ entityType?.legalForm }}
            </li>
            <li [ngClass]="{'document-elements-extra-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && searchQuery.affectedEntityTypes.length > 6}" [ngStyle]="{'display': searchQuery.affectedEntityTypes.length > 6 ? 'list-item' : 'none'}" style="text-align: start;">...</li>
          </ul>
        </td>
        <td>
          <i
            class="fa fa-pencil"
            style="color: #f0ad4e;font-size: 18px;"
            aria-hidden="true"
            title="Edit"
            (click)="edit(editTemplate, searchQuery)"
          ></i>
        </td>
        <td>
          <i
            class="fa fa-trash"
            style="color: #d9534f;font-size: 18px;"
            aria-hidden="true"
            title="Delete"
            (click)="delete(searchQuery.id)"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</form>
<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Search Query</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="editModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label> Search engine: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="searchEngineType"
      class="ng-control"
      name="searchEngineType"
      [(ngModel)]="currentSearchQuery.searchEngineType"
      value="currentSearchQuery.searchEngineType"
    >
      <option value="GOOGLE">Google</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Query keywords (press Enter after each word to confirm): </label
    ><br />
    <div *ngIf="nonEmptyKeywords(currentSearchQuery?.queryKeywords).length > 0" style="height: 10px;"></div>
    <ul *ngIf="currentSearchQuery?.queryKeywords?.length > 0">
      <li *ngFor="let queryKeyword of currentSearchQuery?.queryKeywords; let i = index">
        <span *ngIf="editingIndex !== i">{{ queryKeyword }}</span>
        <input
          *ngIf="editingIndex === i"
          [(ngModel)]="tempKeyword"
          (blur)="saveChanges(i); $event.preventDefault()"
          (keydown.enter)="saveChanges(i); $event.preventDefault()"
        />
        <span *ngIf="errorMessage && editingIndex === i" class="error-message">{{ errorMessage }}</span>
        <i
          class="fa fa-pencil"
          style="margin-left: 5px; cursor: pointer;"
          *ngIf="editingIndex !== i"
          (click)="toggleEdit(i, queryKeyword)"
        ></i>
        <i
          class="fa fa-check"
          style="margin-left: 5px; cursor: pointer;"
          *ngIf="editingIndex === i"
          (click)="saveChanges(i)"
        ></i>
      </li>
    </ul>
    <div style="height: 10px;"></div>
    <textarea
      class="form-control input-grow"
      (keyup.enter)="keywordsChanged(currentSearchQuery.keyword)"
      [(ngModel)]="currentSearchQuery.keyword"
      name="keywords"
      rows="1"
    ></textarea>
    <div style="height: 10px;"></div>
    <label> Risk type: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="screeningRisk"
      class="ng-control"
      name="screeningRisk"
      [(ngModel)]="currentSearchQuery.screeningRisk"
      value="currentSearchQuery.screeningRisk"
    >
      <option *ngFor="let risk of riskTypes" value="{{ risk.id }}">{{
        risk.riskType
      }}</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Maximum hits (enter a number between 1 and 1000): </label><br />
    <div style="height: 10px;"></div>
    <input
      type="number"
      [(ngModel)]="currentSearchQuery.maxHits"
      step="10"
      min="1"
      max="1000"
    />
    <div style="height: 10px;"></div>
    <label> Affected Entity Types: </label><br />
    <div style="height: 10px;"></div>
    <ng-select
      class="custom"
      [items]="entityTypes"
      [multiple]="true"
      bindLabel="legalPersonType"
      [selectableGroup]="false"
      [closeOnSelect]="false"
      bindValue="id"
      [ngModel]="currentSearchQuery?.affectedEntityTypes"
      (ngModelChange)="currentSearchQuery.affectedEntityTypes = $event"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item.legalPersonType }}
      </ng-template>
    </ng-select>
    <div style="height: 10px;"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success btn-save-information" (click)="save()">Save</button>
  </div>
</ng-template>

<ng-template #addTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Search Query</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="addModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="disableAddSearchQueryBtn">
      <label> Search engine: </label><br />
      <div style="height: 10px;"></div>
      <select
        style="color: black;"
        id="searchEngineType"
        class="ng-control"
        name="searchEngineType"
        formControlName="searchEngineDropdown"
        [(ngModel)]="currentSearchQuery.searchEngineType"
        value="currentSearchQuery.searchEngineType"
      >
        <option value="GOOGLE">Google</option>
      </select>
    </form>
      <div style="height: 10px;"></div>
      <label> Query keywords (press Enter after each word to confirm): </label
        ><br />
        <div *ngIf="nonEmptyKeywords(currentSearchQuery?.queryKeywords).length > 0" style="height: 10px;"></div>
        <ul *ngIf="currentSearchQuery?.queryKeywords?.length > 0">
          <li *ngFor="let queryKeyword of currentSearchQuery?.queryKeywords; let i = index">
            <span *ngIf="editingIndex !== i">{{ queryKeyword }}</span>
            <input
              *ngIf="editingIndex === i"
              [(ngModel)]="tempKeyword"
              (blur)="saveChanges(i); $event.preventDefault()"
              (keydown.enter)="saveChanges(i); $event.preventDefault()"
            />
            <span *ngIf="errorMessage && editingIndex === i" class="error-message">{{ errorMessage }}</span>
            <i
              class="fa fa-pencil"
              style="margin-left: 5px; cursor: pointer;"
              *ngIf="editingIndex !== i"
              (click)="toggleEdit(i, queryKeyword)"
            ></i>
            <i
              class="fa fa-check"
              style="margin-left: 5px; cursor: pointer;"
              *ngIf="editingIndex === i"
              (click)="saveChanges(i)"
            ></i>
          </li>
        </ul>
        <div style="height: 10px;"></div>
        <textarea
          class="form-control input-grow"
          (keyup.enter)="keywordsChanged(currentSearchQuery.keyword)"
          [(ngModel)]="currentSearchQuery.keyword"
          name="keywords"
          rows="1"
        ></textarea>
    <form [formGroup]="disableAddSearchQueryBtn">
        <div style="height: 10px;"></div>
        <label> Risk type: </label><br />
        <div style="height: 10px;"></div>
        <select
          style="color: black;"
          id="screeningRisk"
          class="ng-control"
          name="screeningRisk"
          formControlName="riskType"
          [(ngModel)]="currentSearchQuery.screeningRisk"
          value="currentSearchQuery.screeningRisk"
        >
        <option *ngFor="let risk of riskTypes" value="{{ risk.id }}">{{
          risk.riskType
        }}</option>
      </select>
      <div style="height: 10px;"></div>
      <label> Maximum hits (enter a number between 1 and 1000): </label><br />
      <div style="height: 10px;"></div>
      <input
        type="number"
        formControlName="maximumHits"
        [(ngModel)]="currentSearchQuery.maxHits"
        step="10"
        min="1"
        max="1000"
      />
    </form>
    <div style="height: 10px;"></div>
    <label> Affected Entity Types: </label><br />
    <div style="height: 10px;"></div>
    <ng-select
      class="custom"
      [items]="entityTypes"
      [multiple]="true"
      bindLabel="legalPersonType"
      [selectableGroup]="false"
      [closeOnSelect]="false"
      bindValue="id"
      [ngModel]="currentSearchQuery?.affectedEntityTypes"
      (ngModelChange)="currentSearchQuery.affectedEntityTypes = $event"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
          {{ item.legalPersonType }}
      </ng-template>
    </ng-select>
    <div style="height: 10px;"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success btn-save-information" (click)="add()" [disabled]="!disableAddSearchQueryBtn.valid">Add</button>
  </div>
</ng-template>
<style>
  .document-elements{ 
    display: list-item !important;
  }
  .document-elements-extra-elements{ 
    display: none !important;
  }
  .document-rest-elements{
    display: list-item !important;
  }
  .search-engine-screener:has(.activate-border){
    border-top: none;
    border-bottom: none;
    border-right: none;
  }
  .search-engine-screener:has(.activate-border) > td{
    border-top: 4px double #ddd;
    border-bottom: 4px double #ddd;
  }
  .search-engine-screener:has(.activate-border) > td:nth-child(1){
    border-left: 4px double #ddd;
  }
  .search-engine-screener:has(.activate-border) > td:nth-last-child(1){
    border-right: 4px double #ddd;
  }
</style>
<!-- searchEngineType - required, only "GOOGLE" is available now
queryKeywords - required, must be an empty array if there're no keywords
maxHits - required, inclusive range 1-1000
screeningRiskId - optional, expecting existing screningRisk ID
affectedEntityTypes - required must be an empty array or array of existing entity type IDs -->
