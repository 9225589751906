import { Component, TemplateRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AlertService, DataService, KYBService } from '../../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Entity } from '../../../../_models';
import { DocumentEditorComponent } from '../../../../user_management/document-editor/documentEditor.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-geo-entities-settings',
    standalone: true,
    imports: [ CommonModule, DocumentEditorComponent, FormsModule ],
    templateUrl: 'geoEntitiesSettings.component.html',
    styleUrls: [ './geoEntitiesSettings.component.css' ]
})

export class GeoEntitiesSettingsComponent implements OnChanges {

  documentTypes: any = [];
  dataTable: any;
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  currentGEOEntity: any = {};
  documentTypeId: any;
  entityTypeId: any;
  roleTypeId: any;
  documentsOfTypeResponse: any = {};
  documentContentResponse: any = {};
  rolesResponse: any = {};
  textContent: any = {};
  geoEntities: any = [];
  entityIds: any = [];
  editEntitiesResponse: any = {};
  editRolesResponse: any = {};
  editEntities: any = [];
  editDocumentId: any;
  editEntityId: any;
  editRoleId: any;
  editE1Id: any;
  editE2Id: any;
  geoEntityFlag = false;
  editEntityTypeId: any;

  newEntity: Entity;
  document: any;
  documentResponse: any = {};
  entityResponse: any = {};
  file: File;
  entityByNameResponse: any = {};
  documentFieldId: any = '';

  @Input() documentTypesResponse: any = [];
  @Input() v4RoleTypesResponse: any = [];
  @Input() entityTypesResponse: any = [];
  @Input() entities: any = [];
  @Output() reloadGeoEntitiesList = new EventEmitter<any>();

  constructor (
    private alertService: AlertService,
    private kybService: KYBService,
    private modalService: BsModalService,
    private dataService: DataService,
  ) {
    this.dataService.closeDocumentEditModalEvent.subscribe(
      message => {
        if (message === 3) {
          if (this.editModalRef) {
            this.editModalRef.hide();
          }
          this.initTable();
          this.dataService.triggerCloseEditDocumentModalEvent(null);
        }
      }
    );
  }

  ngOnChanges() {
    this.geoEntities = [];
    for (let i = 0; i < this.documentTypesResponse.data.length; i++) {
      if (this.documentTypesResponse.data[i].documentTypeName === 'jurisdictionDetails') {
        this.documentTypeId = this.documentTypesResponse.data[i].id;
      }
    }

    if (this.v4RoleTypesResponse) {
      if (this.v4RoleTypesResponse.data) {
        for (let j = 0; j < this.v4RoleTypesResponse.data.length; j++) {
          if (this.v4RoleTypesResponse.data[j].defaultRoleName === 'Identity:Jurisdiction') {
            this.roleTypeId = this.v4RoleTypesResponse.data[j].id;
          }
        }
      }
    }

    for (let k = 0; k < this.entityTypesResponse.data.length; k++) {
      if (this.entityTypesResponse.data[k].legalPersonType === 'Jurisdiction') {
        this.entityTypeId = this.entityTypesResponse.data[k].id;
      }
    }

    this.initTable();
  }

  emitCallForParent(reload: boolean) {
    this.reloadGeoEntitiesList.emit(reload);
  }

  initTable() {
    this.entityIds = [];
    this.geoEntities = [];

    for (let i = 0; i < this.entities.length; i++) {
      this.entityIds.push(this.entities[i].id);
      this.geoEntities.push({ 'id': this.entities[i].jurisdictionDocumentId, 'name': this.entities[i].primaryName,
        'type': this.entities[i].jurisdictionDetails.type, 'ISO2Code': this.entities[i].jurisdictionDetails.ISO2Code,
        'ISO3Code': this.entities[i].jurisdictionDetails.ISO3Code, /* 'FIPS104Code': '', 'NUTSCode': '', */
        'overallRisk': this.entities[i].jurisdictionDetails.overallRisk, 'flagUrl': this.entities[i].jurisdictionDetails.flagUrl});
    }

    this.geoEntities.sort((a, b) => a.name.localeCompare(b.name));

    if (this.geoEntities.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      if (empty[0]) {
        empty[0].setAttribute('style', 'display: none;');
      }
    }
  }

  edit(editTemplate: TemplateRef<any>, entityName: any, id: any) {
    this.kybService.getEntityByName(entityName).subscribe(
      data => {
        this.editEntitiesResponse = data;
        this.editEntityId = this.editEntitiesResponse.data[0].id;
        this.editEntityTypeId = this.editEntitiesResponse.data[0].entityType.id;
        this.editDocumentId = id;

        this.kybService.getRolesForEntity(this.editEntityId).subscribe(
          editRolesResponse => {
            this.editRolesResponse = editRolesResponse;
            for (let i = 0; i < this.editRolesResponse.data.length; i++) {
              if (this.editRolesResponse.data[i].roleName === "Identity:Jurisdiction") {
                for (let j = 0; j < this.editRolesResponse.data[i].requiredDocuments.length; j++) {
                  if (this.editRolesResponse.data[i].requiredDocuments[j].documentStatus === "UNEVALUATED") {
                    this.documentFieldId = this.editRolesResponse.data[i].requiredDocuments[j].id;
                    this.editEntityTypeId = this.editRolesResponse.data[i].e1Instance.entityType.id;
                  }
                }
              }
            }
            this.editRoleId = this.editRolesResponse.data[0].id;
            this.editE1Id = this.editRolesResponse.data[0].e1Id;
            this.editE2Id = this.editRolesResponse.data[0].e2Id;
            this.geoEntityFlag = true;
            this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
            this.dataService.closeModalEvent.subscribe(
              message => {
                this.editModalRef.hide();
              }
            );
          }
        );
      }, error => {}
      );
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.currentGEOEntity = {};
    this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
  }

  add() {
    this.currentGEOEntity.flagUrl = 'https://flagpedia.net/data/flags/w702/' + this.currentGEOEntity.ISO2Code.toLowerCase() + '.png';
    this.newEntity = new Entity();
    this.newEntity.primaryName = this.currentGEOEntity.name;
    this.newEntity.entityTypeId = this.entityTypeId;

    this.kybService.getEntityByName(this.newEntity.primaryName).subscribe(
      entity => {
          this.entityByNameResponse = entity;
          if (this.entityByNameResponse.data.length > 0) {
              if (window.confirm('A GEO entity with the same name already exists. Are you sure you want to create a duplicate?')) {
                  this.kybService.createEntity(this.newEntity).subscribe(
                    entityData => {
                      this.entityResponse = entityData;
                      this.document = new Document();
                      this.document.docTypeId = this.documentTypeId;
                      this.document.mimeType = 'application/json';
                      this.document.retainTo = '2030-10-20T11:52:05.381';
                      this.document.validTo = '2030-10-20T11:52:05.381';
                      this.document.totalFiles = 1;

                      this.kybService.getRolesForEntity(this.entityResponse.data.entityId).subscribe(data => {
                        this.rolesResponse = data;

                        this.kybService.createDocument(this.document, this.entityResponse.data.entityId,
                          this.rolesResponse.data[0].id, this.entityResponse.data.entityId, null).subscribe(
                            documentResponse => {
                                this.documentResponse = documentResponse;
                                const file = new Blob([JSON.stringify(this.currentGEOEntity)], { type: 'application/json' });
                                this.file = this.blobToFile(file, 'jurisdictionDetails');
                                this.kybService.saveFile(this.file, this.documentResponse.data.documentId,
                                  this.documentResponse.data.fileIds[0]).subscribe(
                                    () => {
                                          this.addModalRef.hide();
                                          this.alertService.showSuccess('Entity added successfully.');
                                          this.currentGEOEntity = {};
                                          this.ngOnChanges();
                                    }
                                );
                            }, error => {}
                          );
                      });
                    }, error => {}
                  );
              } else {
                  this.addModalRef.hide();
                  this.currentGEOEntity = {};
              }
          } else {
            this.kybService.createEntity(this.newEntity).subscribe(
              entityResponse => {
                this.entityResponse = entityResponse;
                this.document = new Document();
                this.document.docTypeId = this.documentTypeId;
                this.document.mimeType = 'application/json';
                this.document.retainTo = '2030-10-20T11:52:05.381';
                this.document.validTo = '2030-10-20T11:52:05.381';
                this.document.totalFiles = 1;

                this.kybService.getRolesForEntity(this.entityResponse.data.entityId).subscribe(data => {
                  this.rolesResponse = data;

                  this.kybService.createDocument(this.document, this.entityResponse.data.entityId,
                    this.rolesResponse.data[0].id, this.entityResponse.data.entityId, null).subscribe(
                      res => {
                          this.documentResponse = res;
                          const file = new Blob([JSON.stringify(this.currentGEOEntity)], { type: 'application/json' });
                          this.file = this.blobToFile(file, 'jurisdictionDetails');
                          this.kybService.saveFile(this.file, this.documentResponse.data.documentId,
                            this.documentResponse.data.fileIds[0]).subscribe(
                              () => {
                                    this.addModalRef.hide();
                                    this.alertService.showSuccess('Entity added successfully.');
                                    this.currentGEOEntity = {};
                                    this.ngOnChanges();
                              }
                          );
                      }, error => {}
                    );
                });
              }, error => {}
            );
          }
      },
      error => {
          window.scrollTo(0, 0);
          this.alertService.showError('Something went wrong while checking for duplicates.');
      }
    );
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
}
