import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mediumText', standalone: true})
export class MediumTextPipe implements PipeTransform {
  transform(value: any): string {
    let result = '';

    if (value.length > 80) {
      result = value.substring(0, 80) + '...';

      return result;
    } else {
      return value;
    }
  }
}
