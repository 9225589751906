<div class="report-risk-hits" *ngIf="reportResults?.pepHits?.length > 0">
  <ng-container *ngIf="reportResults?.pepHits?.length > 0">
    <div class="pull-left float-left"><img src="../../../../../assets/images/icons/PNG/remove_general.png" width="28px" style="margin-top: 7px;" tooltip="Remove all PEP entries from report" (click)="removeFromReportAllPEP()" />&nbsp;&nbsp;</div>
    <div class="pull-left float-left"><h4>PEP Hits</h4></div>
    <div style="height: 15px;"></div>
    <ng-container *ngFor="let result of reportResults?.pepHits">
      <div style="height: 15px;"></div>
      <div class="results-panel">
        <accordion>
              <accordion-group [isOpen]="true">
                  <button class="btn btn-block clearfix" style="background-color: transparent;color: #2A79B9;" accordion-heading>
                    <div class="pull-left float-left headline"><img src="../../../../../assets/images/icons/PNG/remove_general.png" width="28px" tooltip="Remove PEP entry from report" (click)="removeFromReportPEP(result)" />&nbsp;&nbsp;{{result?.entityName}} ({{result?.source}}) - {{result?.status}}</div>
                  </button>
                  <span class="birth-date"><b>Birth date:</b> {{result?.birthDates}} </span><br>
                  <span class="description"><b>Description:</b> {{result?.description}}</span><br>
                  <span class="aka-names"><b>AKA names:</b> {{result?.akaNames | json}}</span><br>
                  <span class="first-seen"><b>First seen:</b> {{result?.firstSeen | date}}</span><br>
                  <span class="last-seen"><b>Last seen:</b> {{result?.lastSeen | date}}</span><br>
                  <ng-container *ngIf="result.note">
                    <div style="height: 5px;"></div>
                    <div class="note">
                        {{result.note}}
                    </div>
                  </ng-container>
              </accordion-group>
              <div style="height: 5px"></div>
        </accordion>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="report-risk-hits" *ngIf="reportResults?.sanctionHits?.length > 0">
  <ng-container *ngIf="reportResults?.sanctionHits?.length > 0">
    <div class="pull-left float-left"><img src="../../../../../assets/images/icons/PNG/remove_general.png" width="28px" style="margin-top: 7px;" (click)="removeFromReportAllSanction()" tooltip="Remove all sanction entries from report" />&nbsp;&nbsp;</div>
    <div class="pull-left float-left"><h4>Sanction Hits</h4></div>
    <div style="height: 15px;"></div>
    <ng-container *ngFor="let result of reportResults?.sanctionHits">
      <div style="height: 15px;"></div>
      <div class="results-panel">
        <accordion>
            <accordion-group [isOpen]="true">
              <button class="btn btn-block clearfix" style="background-color: transparent;color: #2A79B9;" accordion-heading>
                <div class="pull-left float-left headline"><img src="../../../../../assets/images/icons/PNG/remove_general.png" width="28px" tooltip="Remove sanction entry from report" (click)="removeFromReportSanction(result)" />&nbsp;&nbsp;{{result?.entityName}} ({{result?.source}}) - {{result?.status}}</div>
              </button>
              <span class="birth-date"><b>Birth date:</b> {{result?.birthDates}} </span><br>
              <span class="description"><b>Description:</b> {{result?.description}}</span><br>
              <span class="aka-names"><b>AKA names:</b> {{result?.akaNames | json}}</span><br>
              <span class="first-seen"><b>First seen:</b> {{result?.firstSeen | date}}</span><br>
              <span class="last-seen"><b>Last seen:</b> {{result?.lastSeen | date}}</span><br>
              <ng-container *ngIf="result.note">
                <div style="height: 5px;"></div>
                <div class="note">
                    {{result.note}}
                </div>
              </ng-container>
            </accordion-group>
          <div style="height: 5px"></div>
        </accordion>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="report-articles" *ngIf="reportResults?.articles?.length > 0">  
  <ng-container *ngIf="reportResults?.articles?.length > 0">
    <h4>News Articles</h4>
    <ng-container *ngFor="let masterTopic of masterTopics">
      <h5>{{masterTopic}}</h5>
      <ng-container *ngFor="let topic of topics">
        <ng-container *ngIf="topic.masterTopicLabel == masterTopic">
            <div class="pull-left float-left"><img src="../../../../../assets/images/icons/PNG/remove_news.png" width="28px" style="margin-top: 7px;" (click)="removeTopicFromReport(topic.topic,topic.masterTopicLabel)" tooltip="Remove all articles in topic from report" />&nbsp;&nbsp;</div>
            <div class="pull-left float-left"><h6>{{topic?.topic | replaceSpace | uppercase}}<ng-container *ngIf="topic?.topicNote"> - <div class="note">{{topic?.topicNote}}</div></ng-container></h6></div>
            <div style="height: 15px;"></div>
        <ng-container *ngFor="let result of reportResults?.articles">
              <ng-container *ngIf="result.topic == topic.topic">
                <div style="height: 15px;"></div>
                <div class="results-panel">
                  <accordion>
                        <accordion-group [isOpen]="true" style="border-radius: 0px;background-color: white;border: white;">
                            <button class="btn btn-link btn-block clearfix" style="background-color: transparent;color: #2A79B9;" accordion-heading>
                              <div class="pull-left float-left headline"><img src="../../../../../assets/images/icons/PNG/remove_news.png" width="28px" tooltip="Remove article from report" (click)="removeFromReport(result)" />&nbsp;&nbsp;{{result.headline | truncatetext:80}}</div>
                            </button>
                            <span class="medium-text"><b>Published:</b> {{result.publicationDate | date}}</span><br>
                            <span class="medium-text">{{result.text}}...</span><br>
                            <span class="medium-link"><a href="{{result.url}}" target="_blank">read more</a></span><br/>
                            <ng-container *ngIf="result.note">
                              <div style="height: 5px"></div>
                              <div class="note">{{result.note}}</div>
                            </ng-container>
                        </accordion-group>
                        <div style="height: 5px"></div>
                  </accordion>
                </div>
              </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    </ng-container>
  </ng-container>
</div>
