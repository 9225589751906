<form #personalForm="ngForm" class="editForm" novalidate>
        <div style="border: 1px solid #bbb; padding: 15px 15px 25px;">
                <h4 class="head text-center">{{ "IDENTITY_DOC_PHOTO" | translate }}</h4>
                <br />
                <div class='row'>
                        <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                <div class="row mobile-block" style="font-size: 16px;"> <a style="margin-left: 20px;" tooltip="{{ 'WHY_TOOLTIP' | translate }}">{{"WHY" |translate}}</a>
                                        <div style="height: 15px;">
                                        </div>
                                        <div class="radio" id="radio-parent-element1">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="passport" (change)="processType('passport')">
                                                        <div>
                                                                {{ "PASSPORT" | translate }}
                                                        </div>
                                                </label>
                                        </div>
                                        <div class="radio" id="radio-parent-element2">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="id" (change)="processType('id')"> 
                                                        <div>
                                                                {{ "ID_CARD" | translate }}
                                                        </div>
                                                </label>
                                        </div>
                                        <div class="radio" id="radio-parent-element3">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="driversLicense"
                                                                (change)="processType('driversLicense')"> 
                                                        <div>
                                                                {{ "DRIVERS_LICENSE" | translate }}
                                                        </div>
                                                </label>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'id'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol>
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                        <div *ngIf="captures.length >= 1">
                                                                <div *ngFor="let c of captures">
                                                                        <img src="{{c}}" width="100%" height="auto" />
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'driversLicense'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol >
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'passport'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol>
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div class="form-group text-center">
                                        <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
                                </div>
                        </div>
                </div>
        </div>
</form>
<style>
        .btn-success:active, .btn-success:focus, .btn-success:hover {
                outline: none !important;
                border-color: transparent !important;
        }
        .radio {
                margin-left: 20px;
        }
        .radio > label{
                padding-left: 0px;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 50%;
        }
        .radio > label > input{
                position: relative;
                width: fit-content;
                margin-left: 0px;
                margin-top: 0px;
        }
        
        @media (min-width: 576px) {
                .radio > label{
                        width: 30%;
                }
        }
</style>