import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { KYBService } from '../../../_services';
import { DocumentsSettingsComponent } from './documentsSettings/documentsSettings.component';
import { GeoEntitiesSettingsComponent } from './geoEntitiesSettings/geoEntitiesSettings.component';
import { LegalPersonsSettingsComponent } from './legalPersonsSettings/legalPersonsSettings.component';
import { LegalRolesSettingsComponent } from './legalRolesSettings/legalRolesSettings.component';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-legal-configuration',
    standalone: true,
    imports: [
      CommonModule,
      DocumentsSettingsComponent,
      GeoEntitiesSettingsComponent,
      LegalPersonsSettingsComponent,
      LegalRolesSettingsComponent,
      TabsModule
    ],
    templateUrl: 'legalConfiguration.component.html',
    styleUrls: [ './legalConfiguration.component.css' ]
})

export class LegalConfigurationComponent implements OnInit {
  v4RoleTypesResponse: any = {};
  v4RoleTypes: any = [];
  documentTypesResponse: any = {};
  documentTypes: any = [];
  entityTypesResponse: any = {};
  entityTypes: any = [];
  geoEntitiesResponse: any = {};
  entities: any = [];

  constructor (
    private kybService: KYBService,
  ) {}

  ngOnInit() {
    this.kybService.getV4RoleTypes().subscribe(
      data => {
        this.v4RoleTypesResponse = data;
        this.v4RoleTypes = this.v4RoleTypesResponse.data;
      }
    );

    this.kybService.getDocumentTypes().subscribe(
      data => {
        this.documentTypesResponse = data;
        this.documentTypes = this.documentTypesResponse.data;
      }
    );

    this.kybService.getEntityTypes().subscribe(
      data => {
        this.entityTypesResponse = data;
        this.entityTypes = this.entityTypesResponse.data;
      }
    );

    this.kybService.getAllGeoEntities().subscribe(
      data => {
        this.geoEntitiesResponse = data;
        this.entities = this.geoEntitiesResponse.data;
      }
    );
  }

  reloadGeoEntitiesList(reload: boolean) {
    if (reload) {
      this.kybService.getAllGeoEntities().subscribe(
        data => {
          this.geoEntitiesResponse = data;
          this.entities = this.geoEntitiesResponse.data;
        }
      );
    }
  }
}
