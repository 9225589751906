import { ModuleWithProviders, NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateParser'})
export class DateParserPipe implements PipeTransform {
  transform(value: any): string {
    if (value !== null && value !== undefined) {
      const date = value.split('T')[0];
      const time = value.split('T')[1].split('.')[0];

      return date + ' ' + time;
    }
  }
}

@NgModule({
  declarations: [
    DateParserPipe
  ],
  exports: [
    DateParserPipe
  ]
})

export class DateParserImportModule {
  static forRoot(): ModuleWithProviders<DateParserImportModule> {
    return {
        ngModule: DateParserImportModule,
        providers: [],
    };
}

}

