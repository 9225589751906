
<div class="row">
    <div class="col-md-4">
    
    </div>
    <div class="col-md-8">
        <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" autocomplete="off" novalidate>

            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !batchFileFilled }">
                    <label for="batchFile">Batch file:</label><span class="required-info">*</span><br>
                    <div class="col-sm-4">
                    <input type="button" style="background-color: #355B89;color: white;" class="choose_file" value="Choose file" (click)="openBatchFileInput()">
                    </div>
                    <input id="batchFile" class="hide-it" type="file" (change)="showBatchFile($event)" required>
                    <br>
                    <div id="filenameBatchFile" style="color: white"></div><br><br><br>
            </div>
    
            <div class="form-group">
                <button [disabled]="loading" style="padding: 10px;" class="col-md-4 btn-success" (click)="process(f)"><i class="fa fa-upload" aria-hidden="true"></i>&#160;&#160;&#160;Start Upload</button>            <img [hidden]="!loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /><br>
            </div> 
        </form>

    </div>
    <div class="col-md-4">
    </div>
</div>