<div class="col-md-12">
    <div style="height: 20px"></div>
    <button class="bbtn btn-success btn-add-new" (click)="addNew(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>
    <div style="height: 15px"></div>
    <table class="table-entities table table-responsive table-hover" style="text-align: left; width: 100%;">
        <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>ISO-2 Code</th>
                <th>ISO-3 Code</th>
                <th>Risk</th>
                <th>Flag</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entity of geoEntities;let i = index">
                <td>{{entity.name}}</td>
                <td>{{entity.type}}</td>
                <td>{{entity.ISO2Code}}</td>
                <td>{{entity.ISO3Code}}</td>
                <td>{{entity.overallRisk}}</td>
                <td><img src="{{entity.flagUrl}}" style="max-width: 34px; margin: 1em;"></td>
                <td><button class="btn btn-warning btn-yellow" style="padding: 6px;" (click)="edit(editTemplate,entity.name,entity.id)">Edit &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>  
</div>

<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit GEO Entity</h4>
        <div id="arrayIndex"></div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-document-editor [documentId]="editDocumentId" [entityId]="editEntityId" [mode]="'edit-geo-entity'" [roleId]="editRoleId" [e1Id]="editE1Id" [e2Id]="editE2Id" [isGeoEntity]="geoEntityFlag" [entityTypeId]="editEntityTypeId" [documentFieldId]="documentFieldId" (reloadGeoEntitiesList)="emitCallForParent($event)"></app-document-editor>     
    </div>
    <div class="modal-footer" style="border-top: none;">
    </div>
</ng-template>

<ng-template #addTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add GEO Entity</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>
            Name:
        </label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentGEOEntity.name" />
        <div style="height: 10px;"></div>
        <label>
            Type:
        </label><br>
        <div style="height: 10px;"></div>
        <select style="color: black;" class="ng-control" [(ngModel)]="currentGEOEntity.type">
            <option value="international">international</option>
            <option value="federal">federal</option>
            <option value="state">state</option>
            <option value="local">local</option>
            <option value="ad-hoc">ad-hoc</option>
            <option value="country">country</option>
        </select>
        <div style="height: 10px;"></div>
        <label>
            ISO-2 Code:
        </label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentGEOEntity.ISO2Code" />
        <div style="height: 10px;"></div>
        <label>
            ISO-3 Code:
        </label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentGEOEntity.ISO3Code" />
        <div style="height: 10px;"></div>
        <label>
            Custom risk:
        </label>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentGEOEntity.overallRisk">
        <div style="height: 10px;"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information"  [disabled]="(!currentGEOEntity.overallRisk || !currentGEOEntity.ISO2Code || !currentGEOEntity.type || !currentGEOEntity.name)" class="btn btn-primary" (click)="add()">Add</button>
    </div>
</ng-template>