import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'prettyTimestamp', standalone: true})
export class PrettyTimestampPipe implements PipeTransform {
  transform(value: any): string {
    const tmp = value.split('.')[0];
    const result = tmp.split('T')[0] + ' ' + tmp.split('T')[1];
    return result;
  }
}
