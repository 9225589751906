<div *ngIf="isveryfing" class="verify-loader">
  <div class="lds-ripple">
    <div></div>
    <div></div>
  </div> <br />
  <p>Please wait</p>
</div>
<div>
  <mat-tab-group (selectedTabChange)="tabClick($event)">
    <mat-tab label="Dashboard&nbsp;&nbsp;"><ng-container *ngTemplateOutlet="dashboard"></ng-container></mat-tab>
    <mat-tab label="Personal&nbsp;&nbsp;"><ng-container *ngTemplateOutlet="personal"></ng-container></mat-tab>
    <mat-tab label="ACME Inc&nbsp;&nbsp;"><ng-container *ngTemplateOutlet="acme"></ng-container></mat-tab>
    <mat-tab label="Beta LLC&nbsp;&nbsp;"><ng-container *ngTemplateOutlet="beta"></ng-container></mat-tab>
  </mat-tab-group>
</div>

<ng-template #dashboard>
  <div style="display: flex;">
    <img src="../../../../assets/images/onboarding_dashboard.png" class="dashboard-img" alt="" style="object-fit: contain; margin: 0 auto;">
  </div>
  <div class="document-manager-section-1" style="display: flex; margin: 20px auto 0px;">
    <fieldset class="document-manager-section-3"
    style="min-width: unset;padding: .35em .625em .75em;margin: unset;border: 2px groove rgb(192, 192, 192); border-image: initial; padding: 15px; margin-top: 20px; margin: 0 auto; display: flex; width: 100%;">
      <legend style="margin-top: 0px; margin-bottom: 0px; border-width: initial; border-style: none; border-color: initial; border-image: initial; display: block; width: unset; padding: unset; color: #000; border-bottom: unset; font-size: 14px; font-weight: bold;">Disclosure / Report</legend>
      <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center; width: 100%; grid-row-gap: 10px; grid-column-gap: 10px;">
        <h5 style="width: 100%; display: block;"></h5>
        <h5 style="margin-top: 0px;" class="whyNeedThis">Why we need this</h5>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="downloadPdf1.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Prospectus WorldFund II</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="dashboard-contact-support" title="All legal and information disclosures for the World Fund II">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="downloadPdf2.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Quarterly Report WF II</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="dashboard-contact-support" title="Performance report for World Fund II last quarter.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="downloadPdf3.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">FundWorld Annual Report</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="dashboard-contact-support" title="Our Last Annual Report">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="downloadZip4.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">FundWorld Disclosure Package</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="dashboard-contact-support" title="Our KYB package for your records">contact_support</mat-icon>
        <a #downloadPdf1 style="display: none;" download="pdf1" href="../../../../assets/downloadable-pdfs/pdf1.pdf"></a>
        <a #downloadPdf2 style="display: none;" download="pdf2" href="../../../../assets/downloadable-pdfs/pdf2.pdf"></a>
        <a #downloadPdf3 style="display: none;" download="pdf3" href="../../../../assets/downloadable-pdfs/pdf3.pdf"></a>
        <a #downloadZip4 style="display: none;" download="zip1" href="../../../../assets/downloadable-pdfs/pdf4.zip"></a>
      </div>
    </fieldset>
  </div>
  <div class="document-manager-section-1" style="display: flex; margin: 0 auto;">
    <fieldset class="document-manager-section-3"
    style="min-width: unset;padding: .35em .625em .75em;margin: unset;border: 2px groove rgb(192, 192, 192); border-image: initial; padding: 15px; margin-top: 20px; margin: 0 auto; display: flex; width: 100%;">
      <legend style="margin-top: 0px; margin-bottom: 0px; border-width: initial; border-style: none; border-color: initial; border-image: initial; display: block; width: unset; padding: unset; color: #000; border-bottom: unset; font-size: 14px; font-weight: bold;">GDPR Management</legend>

      <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center; width: 100%; grid-row-gap: 10px; grid-column-gap: 10px;">

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="downloadPdf5.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Record of Consent</h5>
        </div>

        <mat-icon style="cursor: pointer; margin-top: 0px;" class="contact-support-dashboard" title="This is the record of consent provided for our privacy policy.">contact_support</mat-icon>

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <mat-checkbox style="margin-right: 10px;" class="example-margin"></mat-checkbox>
          <h5 style="margin: 0px !important; font-weight: normal;">GDPR Disclosure Request</h5>
        </div>

      <mat-icon style="cursor: pointer; margin-top: 0px;" class="contact-support-dashboard" title="If you would like to request your GDPR data from us, check this box and click confirm.">contact_support</mat-icon>

      <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
        <button (click)="downloadPdf6.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
        <h5 style="margin: 0px !important; font-weight: normal;">Complaints and Conflicts Policy</h5>
      </div>

      <mat-icon style="cursor: pointer; margin-top: 0px;" class="contact-support-dashboard" title="How to let us know if you are not satisfied">contact_support</mat-icon>

       
      <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
        <button (click)="downloadPdf7.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;">Download File&nbsp; <mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">download</mat-icon></button>
        <h5 style="margin: 0px !important; font-weight: normal;">Privacy Policy</h5>
      </div>

      <mat-icon style="cursor: pointer; margin-top: 0px;" class="contact-support-dashboard" title="How we protect and handle your privacy">contact_support</mat-icon>
      <a #downloadPdf5 style="display: none;" download="pdf5" href="../../../../assets/downloadable-pdfs/pdf5.pdf"></a>
      <a #downloadPdf6 style="display: none;" download="pdf6" href="../../../../assets/downloadable-pdfs/pdf6.pdf"></a>
      <a #downloadPdf7 style="display: none;" download="pdf7" href="../../../../assets/downloadable-pdfs/pdf7.pdf"></a>
    </div>
    </fieldset>
  </div>
</ng-template>

<ng-template #personal>
  <div>
    <h5 style="font-size: 16px; text-align: center;">
      Please upload the following documents to complete this dossier
    </h5>
    <div class="document-manager-section-1" style="display: flex; padding: 15px; margin: 0 auto;">
      <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center; width: 100%; grid-row-gap: 10px; grid-column-gap: 10px;">
        <h5 style="width: 100%; display: block;"></h5>
        <h5 style="margin-top: 0px;" class="whyNeedThis">Why we need this</h5>

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput1.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Proof of Employment or appointment from the Institution</h5>
        </div>

        <mat-icon style="cursor: pointer;" class="personal-contact-support" title="To confirm your authority to act on behalf of the institutions.">contact_support</mat-icon>

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput2.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Professional Business Card or Title</h5>
        </div>

        <mat-icon style="cursor: pointer;" class="personal-contact-support" title="To verify your personal capacity.">contact_support</mat-icon>

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput3.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Professional Profile or Resume</h5>
        </div>

        <mat-icon style="cursor: pointer;" class="personal-contact-support" title="To understand your role and experience in the institution.">contact_support</mat-icon>

        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput4.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Employer/Parent Institution AML Policies</h5>
        </div>

        <mat-icon style="cursor: pointer;" class="personal-contact-support" title="To assess the institution's compliance framework.">contact_support</mat-icon>

        <input type="file" style="display: none;" #triggerFileInput1/>
        <input type="file" style="display: none;" #triggerFileInput2/>
        <input type="file" style="display: none;" #triggerFileInput3/>
        <input type="file" style="display: none;" #triggerFileInput4/>
      </div>
    </div>
  </div>
  <div style="margin-top: 20px;">
    <h5 style="font-size: 16px; text-align: center;">Please complete these questions</h5>
    <fieldset class="document-manager-section-2"
    style="min-width: unset;padding: .35em .625em .75em;margin: unset;border: 2px groove rgb(192, 192, 192); border-image: initial; padding: 15px; margin-top: 20px; margin: 0 auto;">
      <legend style="margin-top: 0px; margin-bottom: 0px; border-width: initial; border-style: none; border-color: initial; border-image: initial; display: block; width: unset; padding: unset; color: #000; border-bottom: unset; font-size: 14px; font-weight: bold;">Questions</legend>
      <div style="display: flex; align-items: center;" ng-controller='ctrl'>
        <mat-form-field class="example-full-width">
          <mat-label>Alternate Contact Information (Phone, Email)</mat-label>
          <input type="text" matInput [formControl]="alternateContactFormControl" required />
          <mat-error *ngIf="alternateContactFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="personal-contact-support-section-2" title="To maintain communication regarding the institution's affairs.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Relationship to the Institution</mat-label>
          <input type="text" matInput [formControl]="relationToIntituteFormControl" required />
          <mat-error *ngIf="relationToIntituteFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="personal-contact-support-section-2" title="To clarify your position and responsibilities within the institution.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Overview of Institution's Business Activities</mat-label>
          <input type="text" matInput [formControl]="overviewOfRelationFormControl" required />
          <mat-error *ngIf="overviewOfRelationFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="personal-contact-support-section-2" title="To understand the operational context and risk factors.">contact_support</mat-icon>
      </div>
    </fieldset>
  </div>
  <div style="margin-top: 20px; padding: 15px; display: flex; justify-content: center;">
    <button class="btn btn-outline-rounded btn-info no-margin-bottom document-manager-buttons" (click)="saveDataFromForm($event)">
      Save
    </button>
  </div>
</ng-template>

<ng-template #acme>
  <div>
    <h5 style="font-size: 16px; text-align: center;">
      Please upload the following documents to complete this dossier
    </h5>
    <div class="document-manager-section-1" style="display: flex; padding: 15px; margin: 0 auto;">
      <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center; width: 100%; grid-row-gap: 10px; grid-column-gap: 10px;">
        <h5 style="width: 100%; display: block;"></h5>
        <h5 style="margin-top: 0px;" class="whyNeedThis">Why we need this</h5>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput1.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Certificate of Incorporation</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="acme-contact-support" title="To verify the legal existence of the institution.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput2.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Articles of Association or Bylaws</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="acme-contact-support" title="To understand the governance and structure.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput3.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Latest Financial Statement</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="acme-contact-support" title="To assess financial health and source of funds.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput4.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Company AML Policies</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="acme-contact-support" title="To assess the institution's compliance framework.">contact_support</mat-icon>
        <input type="file" style="display: none;" #triggerFileInput1/>
        <input type="file" style="display: none;" #triggerFileInput2/>
        <input type="file" style="display: none;" #triggerFileInput3/>
        <input type="file" style="display: none;" #triggerFileInput4/>
      </div>
    </div>
  </div>
  <div style="margin-top: 20px;">
    <h5 style="font-size: 16px; text-align: center;">Please complete these questions</h5>
    <fieldset class="document-manager-section-2"
    style="min-width: unset;padding: .35em .625em .75em;margin: unset;border: 2px groove rgb(192, 192, 192); border-image: initial; padding: 15px; margin-top: 20px; margin: 0 auto;">
      <legend style="margin-top: 0px; margin-bottom: 0px; border-width: initial; border-style: none; border-color: initial; border-image: initial; display: block; width: unset; padding: unset; color: #000; border-bottom: unset; font-size: 14px; font-weight: bold;">Questions</legend>
      <div style="display: flex; align-items: center;" ng-controller='ctrl'>
        <mat-form-field class="example-full-width">
          <mat-label>Names and positions of Directors/Officers</mat-label>
          <input type="text" matInput [formControl]="nameAndPositionFormControl" required />
          <mat-error *ngIf="nameAndPositionFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="acme-contact-support-section-2" title="To identify individuals with significant control.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Ownership Structure Details</mat-label>
          <input type="text" matInput [formControl]="ownershipStructureFormControl" required />
          <mat-error *ngIf="ownershipStructureFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="acme-contact-support-section-2" title="To identify beneficial owners and stakeholders.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Overview of Institution's Business Activities</mat-label>
          <input type="text" matInput [formControl]="overviewOfInstitutionFormControl" required />
          <mat-error *ngIf="overviewOfInstitutionFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="acme-contact-support-section-2" title="To understand the operational context and risk factors.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Anticipated Transaction Volume</mat-label>
          <input type="text" matInput [formControl]="anticipatedTransactionFormControl" required />
          <mat-error *ngIf="anticipatedTransactionFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="acme-contact-support-section-2" title="To gauge the scale and frequency of transactions.">contact_support</mat-icon>
      </div>
    </fieldset>
  </div>
  <div style="margin-top: 20px; padding: 15px; display: flex; justify-content: center;">
    <button class="btn btn-outline-rounded btn-info no-margin-bottom document-manager-buttons" (click)="saveDataFromForm($event)">
      Save
    </button>
  </div>
</ng-template>

<ng-template #beta>
  <div>
    <h5 style="font-size: 16px; text-align: center;">
      Please upload the following documents to complete this dossier
    </h5>
    <div class="document-manager-section-1" style="display: flex; padding: 15px; margin: 0 auto;">
      <div style="display: grid; grid-template-columns: auto auto; justify-content: space-between; align-items: center; width: 100%; grid-row-gap: 10px; grid-column-gap: 10px;">
        <h5 style="width: 100%; display: block;"></h5>
        <h5 style="margin-top: 0px;" class="whyNeedThis">Why we need this</h5>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput1.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Certificate of Formation</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="beta-contact-support" title="To confirm the legal establishment of the LLC.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput2.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">Operating Agreement</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="beta-contact-support" title="To understand the LLC's governance and operating rules.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput3.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">List of Members and their Percentages</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="beta-contact-support" title="To identify all members and their ownership stakes.">contact_support</mat-icon>
        <div style="display: flex; align-items: center; margin-top: 0px; padding: 4px;">
          <button (click)="triggerFileInput4.click()" style="border: 0px; margin-right: 10px; display: flex; padding: 6px;"><mat-icon style="font-size: 20px; width: fit-content; height: fit-content;">attach_file</mat-icon></button>
          <h5 style="margin: 0px !important; font-weight: normal;">EIN Documentation (IRS)</h5>
        </div>
        <mat-icon style="cursor: pointer;" class="beta-contact-support" title="To verify the LLC's tax identification number.">contact_support</mat-icon>
        <input type="file" style="display: none;" #triggerFileInput1/>
        <input type="file" style="display: none;" #triggerFileInput2/>
        <input type="file" style="display: none;" #triggerFileInput3/>
        <input type="file" style="display: none;" #triggerFileInput4/>
      </div>
    </div>
  </div>
  <div style="margin-top: 20px;">
    <h5 style="font-size: 16px; text-align: center;">Please complete these questions</h5>
    <fieldset class="document-manager-section-2"
    style="min-width: unset;padding: .35em .625em .75em;margin: unset;border: 2px groove rgb(192, 192, 192); border-image: initial; padding: 15px; margin-top: 20px; margin: 0 auto;">
      <legend style="margin-top: 0px; margin-bottom: 0px; border-width: initial; border-style: none; border-color: initial; border-image: initial; display: block; width: unset; padding: unset; color: #000; border-bottom: unset; font-size: 14px; font-weight: bold;">Questions</legend>
      <div style="display: flex; align-items: center;" ng-controller='ctrl'>
        <mat-form-field class="example-full-width">
          <mat-label>Nature of Business</mat-label>
          <input type="text" matInput [formControl]="naturBusinessFormControl" required />
          <mat-error *ngIf="naturBusinessFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="beta-contact-support-section-2" title="To assess the LLC's primary activities and industry.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Anticipated Annual Revenue</mat-label>
          <input type="text" matInput [formControl]="anticipatedAnnualRevenueFormControl" required />
          <mat-error *ngIf="anticipatedAnnualRevenueFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="beta-contact-support-section-2" title="To understand financial scale and transactional needs.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Source of Initial Capital</mat-label>
          <input type="text" matInput [formControl]="sourceOfCapitalFormControl" required />
          <mat-error *ngIf="sourceOfCapitalFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="beta-contact-support-section-2" title="To identify the origin of the LLC's funding.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Expected Transaction Types</mat-label>
          <input type="text" matInput [formControl]="expectedTransactionTypeFormControl" required />
          <mat-error *ngIf="expectedTransactionTypeFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="beta-contact-support-section-2" title="To anticipate the kinds of financial activities.">contact_support</mat-icon>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px;">
        <mat-form-field class="example-full-width">
        <mat-label>Beneficial Ownership Information</mat-label>
          <input type="text" matInput [formControl]="beneficalOwnershipFormControl" required />
          <mat-error *ngIf="beneficalOwnershipFormControl.hasError('required')">Field is required</mat-error>
        </mat-form-field>
        <mat-icon style="cursor: pointer; padding-bottom: 40px;" class="beta-contact-support-section-2" title="To identify individuals with significant control or benefit.">contact_support</mat-icon>
      </div>
    </fieldset>
  </div>
  <div style="margin-top: 20px; padding: 15px; display: flex; justify-content: center;">
    <button class="btn btn-outline-rounded btn-info no-margin-bottom document-manager-buttons" (click)="saveDataFromForm($event)">
      Save
    </button>
  </div>
</ng-template>