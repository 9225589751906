import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SearchService, WebDataService } from '../../../../_services/index';
import { config } from '../../../../../assets/config/configuration';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabsetComponent, TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { TruncateTextPipe } from '../../../../truncate-text.pipe';
import { ReplaceSpacePipe } from '../../../../replaceSpace.pipe';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'results',
    standalone: true,
    imports: [ AccordionModule, CommonModule, DatePipe, FormsModule, ReplaceSpacePipe, TabsModule, TruncateTextPipe ],
    templateUrl: 'results.component.html',
    styleUrls: [ './results.component.css' ]
})

export class ResultsComponent implements OnInit, OnDestroy {

  tabs = [];
  loadingImg = config.loadingGif;
  loading_search = false;
  searchQuery: string;
  results: any = {};
  reportResults: any = {};
  count = 0;
  moneyLaunderingCount = 0;
  pepRiskCount = 0;
  reputationalRiskCount = 0;
  terrorismCount = 0;
  otherCount = 0;
  corruptionRiskCount = 0;
  loading = true;
  noResults = false;
  message = '';
  detailModal: BsModalRef;
  dateFrom = '';
  dateUntil = '';
  dates: any;
  pepHits: any = {};
  articlesFromTopic: any = [];
  topicNote = '';
  masterTopics: any;
  moneyLaundering: any = [];
  reputationalRisk: any = [];
  terrorism: any = [];
  corruptionRisk: any = [];
  PEPRisk: any = [];
  other: any = [];
  topicCounts: any;
  firstName = '';
  lastName = '';
  country = '';
  dateOfBirth = '';
  encodedQuery = '';
  noResultsLeft = false;

  allTopics: any = [];
  topics: any = [];

  private currentResultsSub: Subscription;
  private currentQuerySub: Subscription;
  private routeSub: Subscription;
  private topicsSub: Subscription;

  @Output() redrawEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() calculateRiskEvent = new EventEmitter();
  @Output() runChecks = new EventEmitter();
  @Output() runFullChecks = new EventEmitter();
  @Output() reportResultsEvent = new EventEmitter();

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  constructor(
    private searchService: SearchService,
    private dataService: WebDataService,
    private route: ActivatedRoute
  ) {
    this.reportResults.articles = [];
    this.reportResults.pepHits = [];
    this.reportResults.sanctionHits = [];
  }

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe(params => {
      // tslint:disable-next-line:max-line-length
      if (params['firstName'] === undefined && params['lastName'] === undefined && params['dateOfBirth'] === undefined && params['country'] === undefined) {
        this.loading = false;
      }
    });

    this.topicCounts = '';
    this.corruptionRiskCount = 0;
    this.terrorismCount = 0;
    this.reputationalRiskCount = 0;
    this.pepRiskCount = 0;
    this.moneyLaunderingCount = 0;
    this.otherCount = 0;
    this.results = {};

    this.topicsSub = this.searchService.getTopicsJSON().subscribe(
      data => {
        this.masterTopics = data;

        for (let i = 0; i < this.masterTopics.length; i++) {
          if (this.masterTopics[i].AssociatedRisk === 'Money Laundering') {
            this.moneyLaundering.push(this.masterTopics[i].Subject);
          }

          if (this.masterTopics[i].AssociatedRisk === 'Reputational Risk') {
            this.reputationalRisk.push(this.masterTopics[i].Subject);
          }

          if (this.masterTopics[i].AssociatedRisk === 'Terrorism') {
            this.terrorism.push(this.masterTopics[i].Subject);
          }

          if (this.masterTopics[i].AssociatedRisk === 'Corruption Risk') {
            this.corruptionRisk.push(this.masterTopics[i].Subject);
          }

          if (this.masterTopics[i].AssociatedRisk === 'PEP Risk') {
            this.PEPRisk.push(this.masterTopics[i].Subject);
          }

          if (this.masterTopics[i].AssociatedRisk === 'Other') {
            this.other.push(this.masterTopics[i].Subject);
          }
        }

        this.moneyLaundering = this.moneyLaundering.filter(this.onlyUnique);
        this.reputationalRisk = this.reputationalRisk.filter(this.onlyUnique);
        this.terrorism = this.terrorism.filter(this.onlyUnique);
        this.corruptionRisk = this.corruptionRisk.filter(this.onlyUnique);
        this.PEPRisk = this.PEPRisk.filter(this.onlyUnique);
        this.other = this.other.filter(this.onlyUnique);
      },
      error => {}
    );

    this.currentResultsSub = this.dataService.currentResults.subscribe(
      message => {
        this.results = message;
      }
    );
  }

  openClose(i: any) {
    if (this.tabs[i] === false) {
      this.tabs[i] = true;
    } else {
      this.tabs[i] = false;
    }
  }

  receiveEvents($event) {
    this.loading = false;
    this.getTopics();
  }

  receiveNewSearchEvent($event) {
    this.reset();
    this.loading = true;
  }

  receiveControlEvents($event) {
    if (/^l/.test($event) === true) {
      this.results = this.dataService.results.getValue();
      this.ngOnInit();
      const searchType = $event.split('-');

      if (searchType[1] === 'FS') {
        this.runFullChecks.emit({firstName: searchType[2], lastName: searchType[3], dob: searchType[4], country: searchType[5]});
      } else {
        this.runChecks.emit({firstName: searchType[2], lastName: searchType[3]});
      }
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getTopics() {
    this.topics = [];
    this.allTopics = [];
    this.topicCounts = '';
    this.corruptionRiskCount = 0;
    this.terrorismCount = 0;
    this.reputationalRiskCount = 0;
    this.pepRiskCount = 0;
    this.moneyLaunderingCount = 0;
    this.otherCount = 0;
    this.noResultsLeft = false;

    this.currentQuerySub = this.dataService.currentQuery.subscribe(message => this.searchQuery = message);

    if (this.searchQuery) {
      this.results = this.dataService.results.getValue();

      for (let i = 0; i < this.results.length; i++) {
        this.tabs.push(true);
      }

      if (this.results != null) {
        for (let i = 0, len = this.results.length; i < len; i++) {
          this.results[i].mediaCount = '';
          this.results[i].mediaCount = this.results[i].media.length;
          this.allTopics.push(this.results[i].topicLabel);

          if (this.moneyLaundering.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'Money Laundering';
            this.moneyLaunderingCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'Money Laundering';
            }
          }

          if (this.reputationalRisk.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'Reputational Risk';
            this.reputationalRiskCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'Reputational Risk';
            }
          }

          if (this.corruptionRisk.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'Corruption Risk';
            this.corruptionRiskCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'Corruption Risk';
            }
          }

          if (this.terrorism.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'Terrorism';
            this.terrorismCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'Terrorism';
            }
          }

          if (this.PEPRisk.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'PEP Risk';
            this.pepRiskCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'PEP Risk';
            }
          }

          if (this.other.includes(this.results[i].topicLabel) === true) {
            this.results[i].masterTopicLabel = 'Other';
            this.otherCount += this.results[i].count;

            for (let j = 0; j < this.results[i].media.length; j++) {
              this.results[i].media[j].masterTopicLabel = 'Other';
            }
          }
        }

        this.topics = this.allTopics.filter(this.onlyUnique);

        if (this.reputationalRiskCount === 0) {
          this.staticTabs.tabs[0].disabled = true;
          const test1 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test1[0].setAttribute('style', 'display: none;');
        }

        if (this.corruptionRiskCount === 0) {
          this.staticTabs.tabs[1].disabled = true;
          const test2 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test2[1].setAttribute('style', 'display: none;');
        }

        if (this.pepRiskCount === 0) {
          this.staticTabs.tabs[2].disabled = true;
          const test3 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test3[2].setAttribute('style', 'display: none;');
        }

        if (this.moneyLaunderingCount === 0) {
          this.staticTabs.tabs[3].disabled = true;
          const test4 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test4[3].setAttribute('style', 'display: none;');
        }

        if (this.terrorismCount === 0) {
          this.staticTabs.tabs[4].disabled = true;
          const test5 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test5[4].setAttribute('style', 'display: none;');
        }

        if (this.otherCount === 0) {
          this.staticTabs.tabs[5].disabled = true;
          const test6 = document.getElementsByClassName('nav-item ng-star-inserted') as HTMLCollectionOf<Element>;
          test6[5].setAttribute('style', 'display: none;');
        }

        // tslint:disable-next-line:max-line-length
        this.topicCounts = { 'reputationalRisk': this.reputationalRiskCount, 'corruptionRisk': this.corruptionRiskCount, 'pepRisk': this.pepRiskCount, 'moneyLaundering': this.moneyLaunderingCount, 'terrorism': this.terrorismCount, 'other': this.otherCount };
        this.dataService.setTopicCounts(this.topicCounts);

        // tslint:disable-next-line:max-line-length
        if (this.reputationalRiskCount === 0 && this.corruptionRiskCount === 0 && this.pepRiskCount === 0 && this.moneyLaunderingCount === 0 && this.terrorismCount === 0 && this.otherCount === 0) {
          this.noResults = true;
        }
      }
    }
  }

  addNote(item, note: any) {
    this.results = this.dataService.results.getValue();

    for (let i = 0; i < this.results.length; i++) {
      const index = this.results[i].media.indexOf(item);

      if (index !== -1) {
        this.results[i].media.note = note;
      }
    }

    this.dataService.setCurrentResults(this.results);
    this.ngOnInit();
  }

  addTopicNote(topic, topicNote: any) {
    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].topicLabel === topic) {
        for (let j = 0; j < this.results[i].media.length; j++) {
          this.results[i].media[j].topicNote = topicNote;
        }
      }
    }

    this.dataService.setCurrentResults(this.results);
  }

  discardResult(id: any, topic: any) {
    for (let i = 0; i < this.results.length; i++) {
      for (let j = 0; j < this.results[i].media.length; j++) {
        if (this.results[i].media[j].id === id) {
          this.results[i].media.splice(j, 1);

          if (this.results[i].media.length === 0) {
            this.results.splice(i, 1);
          }
        }
      }
    }

    this.dataService.setCurrentResults(this.results);
  }

  discardTopic(topicLabel: any) {
    for (let i = 0; i < this.results[i].length; i++) {
      if (this.results[i].topicLabel === topicLabel) {
        this.results.splice(i, 1);
      }
    }
  }

  addToReport(item, topicLabel: any) {
    item.topic = topicLabel;
    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = [];
      this.reportResults.articles = [];
    }

    if (this.reportResults.articles === undefined) {
      this.reportResults.articles = [];
    }

    this.reportResults.articles.push(item);
    this.discardResult(item.id, item.topic);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.reportResultsEvent.emit();
  }

  allFromTopicToReport(topic: any) {
    this.articlesFromTopic = [];

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].topicLabel === topic) {
        for (let j = 0; j < this.results[i].media.length; j++) {
          this.results[i].media[j].topic = topic;
          this.articlesFromTopic.push(this.results[i].media[j]);
        }
      }
    }

    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = {};
    }

    if (this.reportResults.articles === undefined) {
      this.reportResults.articles = [];
    }

    this.reportResults.articles = this.reportResults.articles.concat(this.articlesFromTopic);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.reportResultsEvent.emit();

    const filtered = this.results.filter(item => item.topicLabel !== topic);
    this.dataService.setCurrentResults(filtered);
  }

  ignoreAllFromTopic(topic: any) {
    this.articlesFromTopic = [];

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].topicLabel === topic) {
        for (let j = 0; j < this.results[i].media.length; j++) {
          this.results[i].media[j].topic = topic;
          this.articlesFromTopic.push(this.results[i].media[j]);
        }
      }
    }

    const filtered = this.results.filter(item => item.topicLabel !== topic);

    if (filtered.length === 0) {
      this.noResultsLeft = true;
    }

    this.dataService.setCurrentResults(filtered);
  }

  reset() {
    this.results = [];
    this.noResultsLeft = true;
    this.tabs = [];
    this.dataService.setCurrentResults(this.results);
  }

  ngOnDestroy() {
    if (this.currentResultsSub) {
      this.currentResultsSub.unsubscribe();
    }

    if (this.currentQuerySub) {
      this.currentQuerySub.unsubscribe();
    }

    if (this.topicsSub) {
      this.topicsSub.unsubscribe();
    }

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
