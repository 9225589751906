import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallsService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from '../../../assets/configuration';
import { FormDataService } from '../../user_management/register/data/formData.service';
import { PasswordChangeComponent } from '../settings/passwordChange/passwordChange.component';
import { HiddenTagsComponent } from '../hiddenTags/hiddenTags.component';
import { Setup2FAComponent } from '../settings/userManagement/setup2FA/setup2FA.component';
import { CommonModule } from '@angular/common';

/**
 * Implements the user account page
*/
@Component({
  standalone: true,
  imports: [ CommonModule, HiddenTagsComponent, PasswordChangeComponent, Setup2FAComponent ],
  templateUrl: 'myAccount.component.html',
  styleUrls: [ './myAccount.component.css' ]
})

export class MyAccountComponent implements OnInit {
  twofactorDisplay = false;

  passwordDisplay = true;
  hiddenTagsDisplay = false;
  mainColor: any = config.mainColor;

  constructor (
    private callsService: CallsService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private formDataService: FormDataService
  ) {}

  ngOnInit() {}

  menuClick(selectedItem: string): void {
    this.passwordDisplay = selectedItem === 'passwordChange';
    this.hiddenTagsDisplay = selectedItem === 'hiddenTags';
    this.twofactorDisplay = selectedItem === 'twofactorDisplay';

  }
}
