import { Component, OnInit, Input } from '@angular/core';
import { DataService, KYBService } from '../../../_services';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Document } from '../../../_models';
import { config } from '../../../../assets/configuration';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-edit-basic-information',
    standalone: true,
    imports: [ BsDatepickerModule, CommonModule, NgSelectModule, ReactiveFormsModule ],
    templateUrl: 'editBasicInformation.component.html',
    styleUrls: [ './editBasicInformation.component.css' ]
})

export class EditBasicInformationComponent implements OnInit {

    constructor(
        private dataService: DataService,
        private kybService: KYBService
    ) {}
    @Input() basicInformation: any;
    @Input() entityResponseData: any;
    @Input() documentData: any;

    nationalities: any = config.nationalities;

    public myForm: UntypedFormGroup;

    document: Document;
    documentResponse: any = {};
    file: File;

    ngOnInit() {
        if (this.entityResponseData.entityType.entityType === 'PERSON') {
            this.myForm = new UntypedFormGroup({
                primaryName: new UntypedFormControl(this.entityResponseData.primaryName),
                phoneNumber: new UntypedFormControl(this.entityResponseData.phoneNumber),
                email: new UntypedFormControl(this.entityResponseData.email)
            });

            if (this.basicInformation && this.basicInformation.address) {
                this.myForm.addControl('address', new UntypedFormControl(this.basicInformation.address));
            }

            if (this.basicInformation && this.basicInformation.dateOfBirth) {
                const dateOfBirth = this.basicInformation.dateOfBirth.substr(8, 2) + '/'
                                + this.basicInformation.dateOfBirth.substr(5, 2) + '/'
                                + this.basicInformation.dateOfBirth.substr(0, 4);

                this.myForm.addControl('dateOfBirth', new UntypedFormControl(dateOfBirth));
            }

            if (this.basicInformation && this.basicInformation.nationality) {
                this.myForm.addControl('nationality', new UntypedFormControl(this.basicInformation.nationality));
            }
        } else {
            this.myForm = new UntypedFormGroup({
                primaryName: new UntypedFormControl(this.entityResponseData.primaryName)
            });

            if (this.basicInformation && this.basicInformation.address) {
                this.myForm.addControl('address', new UntypedFormControl(this.basicInformation.address));
            }

            if (this.basicInformation && this.basicInformation.contactEmail) {
                this.myForm.addControl('contactEmail', new UntypedFormControl(this.basicInformation.contactEmail));
            }

            if (this.basicInformation && this.basicInformation.contactName) {
                this.myForm.addControl('contactName', new UntypedFormControl(this.basicInformation.contactName));
            }

            if (this.basicInformation && this.basicInformation.contactPhone) {
                this.myForm.addControl('contactPhone', new UntypedFormControl(this.basicInformation.contactPhone));
            }

            if (this.basicInformation && this.basicInformation.taxCountry) {
                this.myForm.addControl('taxCountry', new UntypedFormControl(this.basicInformation.taxCountry));
            }

            if (this.basicInformation && this.basicInformation.vat) {
                this.myForm.addControl('vat', new UntypedFormControl(this.basicInformation.vat));
            }
        }
    }
    onSubmit() {
        this.dataService.triggerCloseModalEvent(this.myForm.value);
        if (this.entityResponseData.entityType) {
            if (this.entityResponseData.entityType.entityType !== 'PERSON') {
                this.kybService.updateDocumentMetadata(this.documentData.documentId, 'Document of type ' +
                this.documentData.documentTypeId + ' was edited.', 'REPLACED', null, null, null).subscribe(
                    data => {
                        this.document = new Document();
                        this.document.docTypeId = this.documentData.documentTypeId;
                        this.document.mimeType = 'application/json';
                        this.document.retainTo = '2030-10-20T11:52:05.381';
                        this.document.validTo = '2030-10-20T11:52:05.381';
                        this.document.totalFiles = 1;
                        this.kybService.createDocument(this.document, this.documentData.entityId,
                            this.documentData.roleId, this.documentData.e1Id, this.documentData.e2Id).subscribe(
                            documentResponse => {
                                this.documentResponse = documentResponse;
                                const file = new Blob([JSON.stringify(this.myForm.value)], { type: 'application/json' });
                                this.file = this.blobToFile(file, this.documentData.documentTypeName);
                                this.kybService.saveFile(this.file, this.documentResponse.data.documentId,
                                    this.documentResponse.data.fileIds[0]).subscribe();
                            }
                        );
                    }
                );
            }
        }
    }

    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
}
