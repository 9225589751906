import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AlertService, CallsService, KYBService, DataService} from '../../../../_services';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Entity, Role } from '../../../../_models/kyb';
import { CommonModule } from '@angular/common';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'user-uploader',
    standalone: true,
    imports: [ CommonModule, FormsModule ],
    templateUrl: 'userUploader.component.html',
    styleUrls: ['./userUploader.component.css']
})

export class UserUploaderComponent implements OnInit {

  error: string;

  model: any = {};
  batchFileFilled = false;
  batchFileName: string;
  filenameBatchFile: string;
  batchFile: File = null;
  csv = '';
  loading = false;
  temparray: any;
  currentUser = 0;
  userObject: any = {};
  row: any;

  rows: any = [];

  csvRecords: any = [];
  header = false;
  newEntity: any;
  entityResponse: any = {};
  selfRole: any;
  roleResponse: any = {};
  selfRoleId: any;

  constructor(
    private alertService: AlertService,
    private kybService: KYBService,
    private callService: CallsService,
    private ngxCsvParser: NgxCsvParser,
    private dataService: DataService
  ) {}

  ngOnInit() {}

  /**
  * Function that detects if batch file is filled
  */
  isBatchFileFilled() {
    this.batchFileFilled = true;
  }

  /**
  * Function that opens batch file input window
  */
  openBatchFileInput() {
    (document.getElementById('batchFile') as HTMLInputElement).click();
  }

  /**
  * Function that shows batch file name
  */
  showBatchFile(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.batchFile = file;
    }

    (document.getElementById('filenameBatchFile') as HTMLDivElement).innerHTML = this.batchFile.name;
  }

  /**
  * Function that sends parsed information to database
  */
  async process(form: NgForm) {
    this.loading = true;
    this.ngxCsvParser.parse(this.batchFile, { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        this.csvRecords = result;
        for (let i = 0; i < this.csvRecords.length; i++) {
          this.newEntity = new Entity();
          this.newEntity.entityTypeId = 80;
          this.newEntity.primaryName = this.csvRecords[i][0];
          this.newEntity.roles = [];
          this.newEntity.delegateEmail = null;
          this.newEntity.entityStatus = this.csvRecords[i][3];
          this.newEntity.ownerId = localStorage.getItem('identifier');
          this.kybService.createEntity(this.newEntity).subscribe(
            entityResponse => {
                this.entityResponse = entityResponse;
                this.selfRole = new Role();
                this.selfRole.e1Id = this.entityResponse.data.entityId;
                this.selfRole.e2Id = null;
                this.selfRole.documentIds = [];
                this.selfRole.roleTypeId = 65;
                this.kybService.createRole(this.selfRole).subscribe(
                  role => {
                      this.roleResponse = role;
                  },
                  error => {
                    this.loading = false;
                    this.alertService.showError('Something went wrong.');
                  }
                );
              },
              error => {
                this.loading = false;
                this.alertService.showError('Something went wrong.');
              }
          );
        }
        this.loading = false;
      }, (error: NgxCSVParserError) => {
        this.loading = false;
      });
      }
}
