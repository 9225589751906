<div *ngIf="!isOnMobile && isReady">
    <form #personalForm="ngForm" class="editForm" novalidate>
        <div class="video-component" style="border: 1px solid black;">
            <h4 class="head text-center">{{"VIDEO_RECORDING" | translate}}</h4>
            <br />
            <div class="row">
                <div class="offset-1 col-10">
                    <div class="text-center" style="font-size: 16px;">
                        <p>{{ "VIDEO_MAIN_DESC" | translate }}</p>
                        <a *ngIf="!customOnboarding" tooltip="{{ 'WHY_VIDEO_TOOLTIP' | translate }}">{{"WHY_VIDEO" | translate}}</a>
                        <div style="height: 25px;"></div>
                        <ol style="text-align: justify;font-size: 16px;padding-left: 15px;">
                            <li>{{"VIDEO_DESC_1" | translate}}</li>
                            <li>{{"VIDEO_DESC_2" | translate}}</li>
                            <li><b>{{"VIDEO_DESC_3" | translate}}</b></li>
                            <li>{{"VIDEO_DESC_4" | translate}}</li>
                        </ol>
                        <div style="height: 25px;"></div>
                        <button id="startButton" class="btn btn-outline-rounded btn-info" (click)="startRecording()"
                            style="font-size: 16px;background-color: #355B89;border:none;">{{ "START" | translate }}</button>
                        <div style="height: 70px"></div>
                    </div>
                    <div style="height: 710px; margin-top: -375px;"></div>
                    <div class="row text-center">
                        <div class="words" style="display: none;"></div>
                        <div class="overlay-desc" style="margin-top: 400px;color: black;">
                            <video id="videoElement" width="80%" style="position: absolute;z-index: 1; 
                            transform: rotateY(180deg);
                            -webkit-transform:rotateY(180deg); /* Safari and Chrome */
                            -moz-transform:rotateY(180deg); /* Firefox */" autoplay></video>
                            <img *ngIf="!videoStarted" src="../../../../assets/images/video_placeholder.png"
                                style="position: absolute;z-index: 1;" width="80%" class="video-placeholder" />
                        </div>
                        <video id="final-video" width="100%" style="display: none;"></video>
                        <canvas #canvas id="canvas" width="600" height="450" style="display: none;"></canvas>
                    </div>
                </div>
            </div>
            <div class="form-group text-center">
                <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
            </div>
        </div>
    </form>
</div>
<div *ngIf="isOnMobile && isReady">
    <app-mobile-video-onboarding></app-mobile-video-onboarding>
</div>
<style>
    .video-component{
        padding: 15px;
    }
    #startButton:focus{
        background-color: #000 !important;
        border-color: #000 !important;
        outline: 1px solid #000 !important;
    }
    #startButton:active{
        background-color: #000 !important;
        border-color: #000 !important;
        outline: 1px solid #000 !important;
    }
    #startButton{
        background-color: #000 !important;
        border-color: #000 !important;
    }
    @media (min-width: 768px){
        .video-placeholder{
            width: 620px;
            height: 412px;
        }
    }
    @media (min-width: 992px){
        .video-component{
            padding: 15px 15px 84px 15px;
        }
    }
</style>