<div class="col-md-4 offset-md-4">
    <div style="height: 10px"></div>
    <div class="text-center">
        <img src="./assets/images/user.png" height="110" width="110">
    </div>
    <div style="height: 20px"></div>
    <div *ngIf="!isTfaEnabled">

    <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !userName.valid }">
            <label for="userName">Email:</label>
            <input id="userName" type="text" class="form-control" name="userName" [(ngModel)]="model.userName" #userName="ngModel" required />
            <div *ngIf="f.submitted && !userName.valid" class="help-block">Please enter a valid email address.</div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <label for="password">Password:</label>
            <input id="ppassword" type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
            <div *ngIf="f.submitted && !password.valid" class="help-block">Please enter a valid password.</div>
        </div>
        <div style="height:40px"></div>
        <div class="form-group">
        <button type="submit" (click)="login()" class="btn btn-primary col-lg-12" style="background-color: #355B89;color: white;">Login</button>
        </div>
    <div style="height:20px"></div>
    <div style="height:75px"></div>
    </form>   </div> 
    <div  *ngIf="isTfaEnabled && !isRecoverEnabled" class="card">
        <div class="form-group" >
            <label for="userName">Code:</label>
            <input type="number" id="mfaCode" [(ngModel)]="mfaCode" class="form-control" />
        </div>
        <div class="form-group"  >
            <button type="submit" (click)="loginUser()" class="btn btn-primary col-lg-12" style="background-color: #355B89;color: white;">Login</button>
        </div>
        <div class="form-group"  >
            <button type="submit"  (click)="recoverEnable()" class="btn btn-primary col-lg-12" style="margin-top: 20px;background-color: #355B89;color: white;">Recover Code</button>
        </div>
    </div>
    <div class="card" *ngIf="isRecoverEnabled && isTfaEnabled" >
        <div class="form-group" >
            <label for="userName">Recovery code 1:</label>
            <input type="string" id="mfaCode" [(ngModel)]="rec1" class="form-control" />
        </div>
        <div class="form-group" >
            <label for="userName">Recovery code 2:</label>
            <input type="string" id="mfaCode" [(ngModel)]="rec2" class="form-control" />
        </div>
        <div class="form-group" >
            <label for="userName">Recovery code 3:</label>
            <input type="string" id="mfaCode" [(ngModel)]="rec3" class="form-control" />
        </div>
        <div class="form-group" >
            <label for="userName">Recovery code 4:</label>
            <input type="string" id="mfaCode" [(ngModel)]="rec4" class="form-control" />
        </div>
           <div class="form-group" >
            <label for="userName">Recovery code 5:</label>
            <input type="string" id="mfaCode" [(ngModel)]="rec5" class="form-control" />
        </div>
        <button type="submit" (click)="recover()" class="btn btn-primary col-lg-12" style="background-color: #355B89;color: white;">Recover Account</button>
    </div>
</div>