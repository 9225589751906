<form #personalForm="ngForm" class="editForm" novalidate>
    <div style="padding: 15px 15px 25px; border: 1px solid #bbb;">
        <h4 class="head text-center">{{"VIDEO_RECORDING" | translate}}</h4>
        <br />
        <div class='row'>
            <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="text-center" style="font-size: 16px;">
                    <p>{{ "VIDEO_MAIN_DESC" | translate }}</p>
                    <a tooltip="{{ 'WHY_VIDEO_TOOLTIP' | translate }}">{{"WHY_VIDEO" | translate}}</a>
                </div>

                    <ol >
                        <li>{{"VIDEO_DESC_1" | translate}}</li>
                        <li>{{"VIDEO_DESC_2" | translate}}</li>
                        <li><b>{{"VIDEO_DESC_3" | translate}}</b></li>
                        <li>{{"VIDEO_DESC_4" | translate}}</li>
                    </ol>
                    <div style="height: 25px;"></div>
                    <div class="text-center" style="font-size: 16px;">

                    <button *ngIf="videoSelfie==undefined" id="startButton" class="btn btn-success" (click)="startVideo()"
                        style="font-size: 16px;background-color: #355B89;border:none;">{{ "START" | translate }}</button>

                        <button *ngIf="showRetry" id="retryButton" class="btn btn-success" (click)="goToNext()"
                        style="font-size: 16px;background-color: #355B89;border:none;">{{ "RETRY" | translate }}</button>
            </div>
        </div>
        </div>
    </div>
    <div class="form-group text-center">
        <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
    </div>
</form>
<style>
    .btn-success:active, .btn-success:focus, .btn-success:hover {
        outline: none !important;
        border-color: transparent !important;
    }
</style>