<form #passwordForm="ngForm" class="editForm" novalidate>
    <h3 class="text-center">Media Screener Settings</h3><br>
    <div style="height: 10px;"></div>
    <span style="font-size: 16px;">Current settings:</span>
        <div style="height: 5px;"></div>
        <ul>
            <li><b>Media sensitivity:</b> {{mediaConfig?.mediaSensitivity}}</li>
            <li><b>Screening Inclusiveness:</b> {{mediaConfig?.mediaScreeningInclusiveness}}</li>
        </ul>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="mediaSensitivity"><b>Media sensitivity:</b> {{mediaConfig?.mediaSensitivity}}</label><br>
        <br>
        <input type="range" min="70" max="100" step="1" style="color: black; max-width: 500px" id="sensitivity" class="ng-control" name="sensitivity" [(ngModel)]="mediaConfig.mediaSensitivity" value="sensitivity" #sensitivityy="ngModel">
    </div>
    <div class="form-group">
        <label class="control-label" for="mediaScreeningInclusiveness"><b>Media Screening Inclusiveness:</b>&nbsp;</label>  
        <select style="color: black; " id="mediaScreeningInclusiveness" class="ng-control" name="mediaScreeningInclusiveness" [(ngModel)]="mediaConfig.mediaScreeningInclusiveness" value="mediaScreeningInclusiveness" #msinclusivenesss="ngModel">
            <option value="NO_SCREENING">No media screening</option>
            <option value="COUNTERPARTIES_ONLY">Counterparties Only</option>
            <option value="COUNTERPARTIES_AND_THIRD_PARTIES">Counterparties and Third Parties</option>
        </select>
    </div>
    <div class="form-group">
    <button type="button" class="btn btn-primary btn-noBorder"[disabled]="!passwordForm.valid" [style.background-color]="mainColor" (click)="saveSettings()"> Save Settings </button>
    </div>
</form>