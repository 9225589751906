import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { WebDataService } from '../../../../_services/index';
import { config } from '../../../../../assets/config/configuration';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'risk-assessment',
    standalone: true,
    templateUrl: 'risk-assessment.component.html',
    styleUrls: [ './risk-assessment.component.css' ]
})

export class RiskAssessmentComponent implements OnInit {

    reportResults: any = {};
    riskAssessmentNote = '';
    approvedIcon: any = config.approvedIcon;
    furtherAnalysisIcon: any = config.furtherAnalysisIcon;
    rejectedIcon: any = config.rejectedIcon;
    lowRiskIcon: any = config.lowRiskIcon;
    mediumRiskIcon: any = config.mediumRiskIcon;
    highRiskIcon: any = config.highRiskIcon;

    constructor(private dataService: WebDataService) {}

    @Output() riskAssessmentEvent = new EventEmitter();

    ngOnInit() {}

    addRiskAssessment(status: any) {
        this.reportResults = this.dataService.reportResults.getValue();

        if (this.reportResults === null) {
            this.reportResults = {};
            this.reportResults.riskAssessment = '';
        }

        this.reportResults.riskAssessment = status;
        this.dataService.setCurrentReportResults(this.reportResults);
        this.riskAssessmentEvent.emit();
    }

    addRiskAssessmentNote() {
        this.reportResults = this.dataService.reportResults.getValue();

        if (this.reportResults === null) {
            this.reportResults = {};
            this.reportResults.riskAssessmentNote = '';
        }

        this.reportResults.riskAssessmentNote = this.riskAssessmentNote;
        this.dataService.setCurrentReportResults(this.reportResults);
        this.riskAssessmentEvent.emit();
    }
}
