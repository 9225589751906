<div class="col-lg-12 box notification-list" xmlns:height="http://www.w3.org/1999/xhtml" style="padding-left: 0px; padding-right: 0px;">
  <div style="height: 20px"></div>
  <p class="text-center" style="color: black; font-family: 'Open Sans', sans-serif; font-size: 22px; font-weight: 400;">Notification List</p> 
  <div style="height: 20px"></div>
</div>
<div class="row">
  <div class="col-lg-12" style="padding-right: 0px;">
      <button class="btn btn-primary btn-filter" (click)="toggleFilters()">{{ "FILTERS" | translate }}&nbsp;<mat-icon>filter_list</mat-icon></button>
      <ul *ngIf="displayFilters" class="list-unstyled list-inline" style="width: 100%;">
        <li class="list-inline-item" style="padding-left: 0px; width: 100%;">
          <div style="height: 20px;"></div>
          <b>Entity Owner</b>
          <div style="height: 10px;"></div>
          <ng-select class="custom-owner" [items]="notificationOwners"
              bindLabel="name"
              bindValue="name"
              [(ngModel)]="notificationOwner"
              (change)="filterOwner($event)"
          >
          </ng-select>
        </li>
      </ul>
      <ul *ngIf="displayFilters" class="list-unstyled list-inline" style="width: 100%;">
        <li class="list-inline-item" style="padding-left: 0px; width: 100%;">
          <div style="height: 20px;"></div>
          <b>Notification Type</b>
          <div style="height: 10px;"></div>
          <ng-select class="custom-owner" [items]="notificationTypes"
              bindLabel="name"
              bindValue="name"
              [(ngModel)]="notificationType"
              (change)="filterType($event)"
          >
          </ng-select>
        </li>
      </ul>
      <ul *ngIf="displayFilters" class="list-unstyled list-inline" style="width: 100%;">
        <li class="list-inline-item" style="padding-left: 0px; width: 100%;">
          <div style="height: 20px;"></div>
          <b>Entity Name</b>
          <div style="height: 10px;"></div>
          <ng-select class="custom-owner" [items]="notificationNames"
              bindLabel="name"
              bindValue="name"
              [(ngModel)]="notificationName"
              (change)="filterName($event)"
          >
          </ng-select>
        </li>
      </ul>
  </div>
</div>
<div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
  <div style="height: 40px"></div> 
  <div class="row">
    <div class="col-sm-6" style="text-align: left;">
      Show
      <select [(ngModel)]="pageSize" (ngModelChange)="pageSizeChanged()">
        <option [value]="30">30</option>
        <option [value]="50">50</option>
        <option [value]="100">100</option> 
        <option [value]="totalItems">All</option>
      </select> notifications
    </div>
  </div>   
  <div style="height: 20px"></div>  
  <table id="notification-table" class="table role-detail-table__table table-responsive table-hover" style="color: black;"> 
      <thead>
        <tr>
          <th>Notification Type&nbsp;<i id="type-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('type','asc')"></i>&nbsp;<i id="type-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('type','desc')"></i></th>
          <th>Description&nbsp;<i id="description-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('description','asc')"></i>&nbsp;<i id="description-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('description','desc')"></i></th>
          <th>Entity Name&nbsp;<i id="entityName-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityName','asc')"></i>&nbsp;<i id="entityName-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityName','desc')"></i></th>
          <th>Entity Owner&nbsp;<i id="ownerName-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('ownerName','asc')"></i>&nbsp;<i id="ownerName-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('ownerName','desc')"></i></th>
          <th>Date/Time Of Notification Creation&nbsp;<i id="createdAt-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('createdAt','asc')"></i>&nbsp;<i id="createdAt-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('createdAt','desc')"></i></th>
          <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="isEmpty" aria-colspan="9">
          <td colspan="9" style="text-align: center;">
            <div style="height: 20px;"></div>
            <p style="text-align: center;font-family: Arial,sans-serif;font-size: 14px;">No notifications found.</p>
            <div style="height: 20px;"></div>
          </td>
        </tr>
        <tr class="main-rows" *ngFor="let notification of notifications; let i = index">
            <td *ngIf="notification.type === 'DossierExpirationNotification'">Entity Refresh Date Expired</td>
            <td *ngIf="notification.type === 'DocumentNotificationDto'">Document Expired</td>
            <td *ngIf="notification.type !== 'DossierExpirationNotification' && notification.type !== 'DocumentNotificationDto'">{{notification.type}}</td>
            <td>{{notification.description}}</td>
            <td><a style='color:black' [routerLink]="['/detail', notification.entityId]"><u>{{notification.entityName}}</u></a></td>
            <td>{{notification.ownerName}}</td>
            <td>{{notification.createdAt | dateParser}}</td>
            <td style="text-align: center;">
              <button (click)="closeNotification(notification)" style="border: none;background-color: white;"><i  class="fa fa-lg fa-close" style="color: #355B89;"></i></button>
            </td>
        </tr>
    </tbody>
    <tfoot style="border: none;">
      <tr aria-colspan="9" style="border: none;">
        <td colspan="9" style="border: none;">
          <div style="height: 5px;"></div>
          <p style="font-family: Arial,sans-serif;font-size: 14px;color: #333;margin-bottom: -15px;margin-left: -10px;">Showing <b>{{numberOfElements}}</b> of <b>{{totalItems}}</b> notifications</p>
        </td>
      </tr>
    </tfoot>
  </table>  
  <pagination [totalItems]="totalItems" [itemsPerPage]="pageSize" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"></pagination>
</div>
