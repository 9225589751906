import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'requiredDocumentsPipe', standalone: true})
export class RequiredDocumentsPipe implements PipeTransform {
  transform(value: any): string {
    let result = '';
    let resultArr: any;
    let newValues: any;

    newValues = Object.values(value);

    for (let i = 0; i < newValues.length; i++) {
      for (let j = 0; j < newValues[i].documents.length; j++) {
        result += newValues[i].documents[j].documentTypeName + '/n/';
      }
    }

    resultArr = result.split('/n/');
    resultArr.splice(-1);

    return resultArr;
  }
}
