<div class="container-fluid no-padding" style="padding: 0 18px;">
    <div class="row new-search-box">
        <div class="col-lg-12">
            <new-search #newSearch (newSearchEvent)="$any(risk).receiveNewSearchEvent($event)" (newSearchEvent)="$any(results).receiveNewSearchEvent($event)" (newSearchEvent)="$any(fullSearchRisk).reset($event)" (newSearchEvent)="$any(information).receiveEvents($event)" (newFullSearchEvent)="$any(information).receiveEvents($event)" (newFullSearchEvent)="$any(fullSearchRisk).runFullCheck($event)" (newFullSearchEvent)="$any(results).receiveNewSearchEvent($event)" (newFullSearchEvent)="$any(risk).reset($event)"></new-search>
        </div>
    </div>
    <div style="height: 10px"></div>
    <div class="row">
        <div style="height: 20px"></div>
        <div class="col-lg-6" style="background-color: #F8F8F8;">
            <h4 style="margin-top: 25px;">Risk Assessment</h4>
        </div>
        <div class="col-lg-6" style="background-color: #E6E6E6;">
            <div class="row"></div>
            <div style="height: 40px;"></div>
        </div>
    </div>
    <div class="row" style="background-color: #E6E6E6;">
        <div class="col-lg-6" style="background-color: #F8F8F8; margin-top: -5px;">
            <div>
                <div class="row">
                    <div class="col-lg-12" style="padding-right: 30px;">
                        <div style="height: 15px;"></div>
                        <risk-assessment #riskAssessment></risk-assessment>
                    </div>
                </div>
                <div style="height: 15px;"></div>
                <div class="row">
                    <div class="col-lg-12" style="padding-right: 30px;">
                        <risk-level #riskLevel></risk-level>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12" style="padding-left: 30px;">
                        <div style="height: 35px;"></div>
                        <comment #comment></comment>
                    </div>
                </div>
            </div>
            <div style="height: 15px"></div>
            <div class="row">
                <div class="col-lg-12" style="padding-left: 30px;">
                    <full-search-risk #fullSearchRisk (searchQueryChangeEvent)="newSearch.receiveEvents($event)" (fullRiskEvent)= "reportPreview.receiveRiskEvent($event)"></full-search-risk>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12" style="padding-left: 30px;">
                    <risk #risk (searchQueryChangeEvent)="newSearch.receiveEvents($event)" (riskEvent)="reportPreview.receiveRiskEvent($event)" (newSearchEvent)="$any(fullSearchRisk).reset($event)"></risk>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12" style="padding-left: 30px;">
                    <h4>Main Topics</h4>
                </div>
                <div class="col-lg-12" style="padding-left: 30px;">
                    <results #results (runChecks)="risk.receiveLoadEvent($event)" (runFullChecks)="fullSearchRisk.runFullCheck($event)" (reportResultsEvent)="reportPreview.receiveReportResultsEvent($event)"></results>
                    <information style="display: none;" #information (gotResultsEvent)="results.receiveEvents($event)" (gotResultsEvent)="reportPreview.receiveEvents($event)"></information>
                </div>
            </div>
            <div class="row">
                <div style="height: 35px"></div>
            </div>
        </div>
        <div class="col-lg-6" style="background-color: #E6E6E6;">
            <div class="row" style="padding-top: 0;">
                <div class="col-lg-6">
                    <controls (loadEvent)="results.receiveControlEvents($event)" (loadEvent)="reportPreview.receiveReportResultsEvent($event)"></controls>
                </div>
                <div class="col-lg-6">
                    <report #report></report>
                </div>
            </div>
            <div style="height: 15px;"></div>
            <div class="row">
                <div class="col-lg-12">
                    <report-preview #reportPreview></report-preview>
                </div>
            </div>
        </div>
    </div>
</div>
