import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';
import { BulkOwnershipComponent } from './bulkOwnership/bulkOwnership.component';
import { ManageUsersComponent } from './manageUsers/manageUsers.component';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-user-management',
    standalone: true,
    imports: [ BulkOwnershipComponent, CommonModule, ManageUsersComponent, TabsModule ],
    templateUrl: 'userManagement.component.html',
    styleUrls: [ './userManagement.component.css' ]
})

export class UserManagementComponent implements OnInit {

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}
}
