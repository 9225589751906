<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
    <div style="height: 30px;"></div>
    Transfer From User:
    <ng-select
        [items]="users"
        bindLabel="email"
        bindValue="identifier"
        [(ngModel)]="transferFrom"
        (change)="displayToggle()">
    </ng-select>
    
    &nbsp;&nbsp;&nbsp;<br>
    
    <div style="height: 10px;"></div>
    Transfer To User:
    <ng-select
        [items]="usersFiltered"
        bindLabel="email"
        bindValue="identifier"
        [(ngModel)]="transferTo">
    </ng-select>
    <div class="alert alert-danger" [hidden]="usersFiltered.length > 0 && transferFrom">Please choose Transfer From User.</div>
    <div style="height: 20px;"></div>
    <ng-container *ngIf="displayTable">
        Transferring ownership of the following entities:<br>
        <div style="height: 20px;"></div>
        <table class="table-users" width="100%" style="text-align: left;">
            <thead>
                <tr>
                    <th>Primary name</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entity of entities">
                    <td>{{entity.primaryName}}</td>
                </tr>
            </tbody>
        </table>  
        <div style="height: 30px"></div>
        <button [disabled]="users.length === 0 || usersFiltered.length === 0 || entities.length === 0 || !transferTo" class="btn btn-success" (click)="transfer()">Transfer</button>
    </ng-container>
</div>