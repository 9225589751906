import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '../../../_models/kyb';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';

/**
 * Implements the enterprise page
*/
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'add-role-simple',
    standalone: true,
    imports: [ CommonModule, NgSelectModule, ReactiveFormsModule ],
    templateUrl: 'addRoleSimple.component.html',
    styleUrls: [ './addRoleSimple.component.css' ]
})

export class AddRoleSimpleComponent implements OnInit {

  @Input() entityId: any;
  @Input() primaryName: any;
  @Input() entities: any;
  @Input() roleTypeId: any;

  e1Instance = '';
  e2Instance = '';
  entitiesResponse: any = {};
  roleTypeSelect = false;

  entityType = '';

  newEntities = [];
  GEOentities = [];

  roleTypeResponse: any = {};
  roleTypes: any;
  roleType: any;
  role: Role;
  currentStack: any;
  roleTypesLoading = false;
  entitiesLoading = false;
  showGEOentities = false;

  roleResponse: any = {};
  disableAddRoleBtn: any;
  roleName = '';
  e1Name = '';
  e2Name = '';
  getEntityResponse: any = {};
  GEOResponse: any = {};

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService,
    private chRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.disableAddRoleBtn = new UntypedFormGroup({
      companyFrom: new UntypedFormControl(null, Validators.required),
      companyIs: new UntypedFormControl(null, Validators.required),
      companyOf: new UntypedFormControl(null, Validators.required),
    });
    this.roleTypesLoading = true;
    this.entitiesLoading = true;
    this.entities = [];
    this.roleTypes = [];
    this.dataService.stack.subscribe(message => this.currentStack = message);

    this.kybService.getAllEntities({}).subscribe(
      data => {
        this.entitiesResponse = data;
        this.entities = this.entitiesResponse.data;

        if (this.entityId !== undefined) {
          for (let k = 0; k < this.entities.length; k++) {
            if (this.entities[k].id === this.entityId) {
              this.e1Instance = this.entityId;
              this.entityType = this.entities[k].entityType;
            }
          }
        }
        if (this.entityId !== undefined) {
          this.e1InstanceChanged({id: this.e1Instance});
          }
        this.entitiesLoading = false;
      },
      error => {}
    );

    this.kybService.getV4RoleTypes().subscribe(
      data => {
        this.roleTypeResponse = data;
        for (let i = 0; i < this.roleTypeResponse.data.length; i++) {
          if (this.roleTypeResponse.data[i].self === false) {
            this.roleTypeResponse.data[i].allowableE1EntityTypes.forEach(type => {
              if (this.entityType === type.entityType) {
                this.roleTypes = [...this.roleTypes, this.roleTypeResponse.data[i]];
              }
            });
          }
        }

        const newRoleTypes = [];
        this.roleTypes.forEach(roleType => {
          if (!newRoleTypes.includes(roleType)) {
            newRoleTypes.push(roleType);
          }
        });

        this.roleTypes = newRoleTypes;

        if (this.roleTypeId !== undefined) {
          for (let j = 0; j < this.roleTypes.length; j++) {
            if (this.roleTypes[j].id === this.roleTypeId) {
              this.roleType = this.roleTypeId;
              // Logic for detecting roles including a GEO entity as E2
              const allowableE2EntityTypesTemp = [];
              for (let k = 0; k < this.roleTypes[j].allowableE2EntityTypes.length; k++) {
                allowableE2EntityTypesTemp.push(this.roleTypes[j].allowableE2EntityTypes[k].entityType);
              }
              if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
                this.showGEOentities = true;
                this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(
                  entityData => {
                    this.GEOResponse = entityData;
                    this.GEOentities = this.GEOResponse.data.content;
                  }
                );
              }
            }
          }
        }

        this.roleTypesLoading = false;
      }
    );
  }

  e1InstanceChanged(event: any) {
    this.newEntities = [...this.entities];

    for (let i = 0; i < this.newEntities.length; i++) {
      if (event.id === this.newEntities[i].id) {
        this.newEntities.splice(i, 1);
      }
    }

    this.roleTypesLoading = true;
    this.entitiesLoading = true;
    this.entities = [];
    this.roleTypes = [];
    this.dataService.stack.subscribe(message => this.currentStack = message);

    this.kybService.getAllEntities({}).subscribe(
      data => {
        this.entitiesResponse = data;
        this.entities = this.entitiesResponse.data;

        if (event.id !== undefined) {
          for (let k = 0; k < this.entities.length; k++) {
            if (this.entities[k].id === event.id) {
              this.e1Instance = event.id;
              this.entityType = this.entities[k].entityType;
            }
          }
        }

        this.entitiesLoading = false;
      },
      error => {}
    );

    this.kybService.getV4RoleTypes().subscribe(
      data => {
        this.roleTypeResponse = data;
        for (let i = 0; i < this.roleTypeResponse.data.length; i++) {
          if (this.roleTypeResponse.data[i].self === false) {
            this.roleTypeResponse.data[i].allowableE1EntityTypes.forEach(type => {
              if (this.entityType === type.entityType) {
                this.roleTypes = [...this.roleTypes, this.roleTypeResponse.data[i]];
              }
            });
          }
        }

        const newRoleTypes = [];
        this.roleTypes.forEach(roleType => {
          if (!newRoleTypes.includes(roleType)) {
            newRoleTypes.push(roleType);
          }
        });

        this.roleTypes = newRoleTypes;

        if (this.roleTypeId !== undefined) {
          for (let j = 0; j < this.roleTypes.length; j++) {
            if (this.roleTypes[j].id === this.roleTypeId) {
              this.roleType = this.roleTypeId;
              // Logic for detecting roles including a GEO entity as E2
              const allowableE2EntityTypesTemp = [];
              for (let k = 0; k < this.roleTypes[j].allowableE2EntityTypes.length; k++) {
                allowableE2EntityTypesTemp.push(this.roleTypes[j].allowableE2EntityTypes[k].entityType);
              }
              if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
                this.showGEOentities = true;
                this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(
                  entityData => {
                    this.GEOResponse = entityData;
                    this.GEOentities = this.GEOResponse.data.content;
                  }
                );
              }
            }
          }
        }

        this.roleTypesLoading = false;
      }
    );
  }

  roleSelected() {
    this.roleTypeSelect = true;
  }

  roleTypeChanged() {
    for (let j = 0; j < this.roleTypes.length; j++) {
      if (this.roleTypes[j].id === this.roleType) {
        // Logic for detecting roles including a GEO entity as E2
        const allowableE2EntityTypesTemp = [];
        for (let k = 0; k < this.roleTypes[j].allowableE2EntityTypes.length; k++) {
          allowableE2EntityTypesTemp.push(this.roleTypes[j].allowableE2EntityTypes[k].entityType);
        }
        if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
          this.showGEOentities = true;
          this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(
            entityData => {
              this.GEOResponse = entityData;
              this.GEOentities = this.GEOResponse.data.content;
            }
          );
        } else {
          this.showGEOentities = false;
        }
      }
    }
  }

  addRole() {
    this.kybService.getEntity(this.e2Instance).subscribe(
      data => {
        this.getEntityResponse = data;
        this.role = new Role();

        if (this.getEntityResponse.data.entityType.entityType === 'GEO') {
          this.role.e1Id = this.e1Instance;
          this.role.e2Id = this.e2Instance;
        } else {
          this.role.e1Id = this.e2Instance;
          this.role.e2Id = this.e1Instance;
        }

        this.role.roleTypeId = this.roleType;
        this.role.documents = [];
        this.role.documentIds = [];

        this.kybService.createRole(this.role).subscribe(
          roleResponse => {
            this.roleResponse = roleResponse;

            for (let i = 0; i < this.roleTypes.length; i++) {
              if (this.roleTypes[i].id === this.roleType) {
                this.roleName = this.roleTypes[i].defaultRoleName;
              }
            }

            for (let j = 0; j < this.entities.length; j++) {
              if (this.entities[j].id === this.e1Instance) {
                this.e1Name = this.entities[j].primaryName;
              }

              if (this.entities[j].id === this.e2Instance) {
                this.e2Name = this.entities[j].primaryName;
              }
            }

            if (this.currentStack !== undefined) {
              if (this.currentStack.roles === undefined) {
                this.currentStack.roles = [];
              }

              this.currentStack.roles.push({'entity1': this.e1Name, 'entity2': this.e2Name,
              'type': this.roleName, 'delegated': false, 'id': this.roleResponse.data.id});
              this.dataService.setCurrentStack(this.currentStack);
            }

            this.dataService.triggerRefreshEvent(2);
            this.role = new Role();
            this.dataService.triggerCloseModalEvent(2);
            this.alertService.showSuccess('Role created successfully.');
          },
          error => {
            this.alertService.showError(error.error.fieldErrors[0].message);
          }
        );
      }
    );
  }
}
