import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { AlertService, KYBService } from '../../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AllowedEntitiesPipe } from '../../../../allowedEntities.pipe';
import { RequiredDocumentsPipe } from '../../../../requiredDocuments.pipe';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { SecondsToDaysPipe } from '../../../../secondsToDays.pipe';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-legal-roles-settings',
    standalone: true,
    imports: [
      AllowedEntitiesPipe,
      CommonModule,
      FormsModule,
      MatIconModule,
      NgSelectModule,
      ReactiveFormsModule,
      RequiredDocumentsPipe,
      SecondsToDaysPipe
    ],
    templateUrl: 'legalRolesSettings.component.html',
    styleUrls: [ './legalRolesSettings.component.scss' ]
})

export class LegalRolesSettingsComponent implements OnInit {

  toggleHiddenEntries = false;
  toggleEntriesId = -1;
  roleTypesResponse: any = {};
  requiredDocuments: any = [];
  v4RoleType: any = {};
  v4RoleTypeResponse: any = {};
  v4RoleTypesResponse: any = {};
  dataTable: any;
  documentDataTable: any;
  editRoleModalRef: BsModalRef;
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  removeModalRef: BsModalRef;
  handleMultiAddModalRef: BsModalRef[] = [];
  removeId = 0;
  modalCount = 0;
  currentRoleType: any = {};
  entityTypes2: any = [];
  documentTypesResponse: any = {};
  requiredDocumentGroups: any = [];
  entityTypesResponse: any = {};
  entityTypesResponse2: any = {};

  isUnique = true;

  newCurrentRoleType: any = {};
  newV4RoleType: any = {};

  legalRoleType: any = {};
  roleTypeId = 0;

  currentDocumentType: any = {};
  dropdownList = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'custom/form',
    'text/plain',
    'video/mpeg',
    'video/webm'
  ];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  formats = ['binary'];
  selectedItems = [];

  row: any;

  switchedDocuments: any[] = [];

  selectedDocumentType: any = {};

  riskLevels = ['LOW', 'MEDIUM', 'HIGH'];

  currentRiskLevel = 'LOW';

  isAND = false;
  isOR = false;
  idOR = 0;

  public editLegalRoleTypeForm: UntypedFormGroup;
  public addLegalRoleTypeForm: UntypedFormGroup;

  @Input() v4RoleTypes: any = [];
  @Input() documentTypes: any = [];
  @Input() entityTypes: any = [];

  constructor (
    private alertService: AlertService,
    private kybService: KYBService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.initTable();

    for (let i = 0; i < this.v4RoleTypes.length; i++) {
      this.requiredDocumentGroups.push(this.v4RoleTypes[i].requiredDocumentGroups);
    }
  }

  upClicked(index: number): void {
    const temp = this.requiredDocuments[index];
    this.requiredDocuments[index] = this.requiredDocuments[index - 1];
    this.requiredDocuments[index - 1] = temp;

    this.switchedDocuments = this.requiredDocuments;
  }

  downClicked(index: number): void {
    const temp = this.requiredDocuments[index];
    this.requiredDocuments[index] = this.requiredDocuments[index + 1];
    this.requiredDocuments[index + 1] = temp;

    this.switchedDocuments = this.requiredDocuments;
  }

  changeRoleName(): void {
    this.isUnique = true;

    this.kybService.getAllRoles().subscribe((roles: any) => {
      const rolesData = roles.data;

      rolesData.forEach(role => {
        if (this.currentRoleType.defaultRoleName === role.roleName) {
          this.isUnique = false;
        }
      });
    });
  }

  reducingTheLength(length: number, recievedString: any) {
    let newString = '';
    for (let i = 0; i < length; i++) {
      newString = newString + recievedString[i];
    }
    return newString + '...';
  }

  showRemaingInformation(index) {
    this.toggleHiddenEntries = true;
    this.toggleEntriesId = index;
  }
  hideRemaingInformation() {
    this.toggleHiddenEntries = false;
    this.toggleEntriesId = -1;
  }

  initTable() {
    if (this.v4RoleTypes.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      if (empty.length > 0) {
        empty[0].setAttribute('style', 'display: none;');
      }
    }
  }

  initDocumentTable() {
    if (this.requiredDocuments.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      for (let i = 0; i < empty.length; i++) {
        empty[i].setAttribute('style', 'display: none;');
      }
    }
  }

  edit(editTemplate: TemplateRef<any>, legalPersonType: any) {
    this.legalRoleType = this.countRoleType(legalPersonType);

    this.editLegalRoleTypeForm = new UntypedFormGroup({
      defaultRoleName: new UntypedFormControl(this.legalRoleType.defaultRoleName, Validators.required),
      defaultRisk: new UntypedFormControl(this.legalRoleType.defaultRisk, Validators.required),
      self: new UntypedFormControl(this.legalRoleType.self, Validators.required),
      relationType: new UntypedFormControl(this.legalRoleType.relationType, Validators.required),
      allowableE1EntityTypes: new UntypedFormControl(this.legalRoleType.allowableE1EntitiesIds, Validators.required),
      allowableE2EntityTypes: new UntypedFormControl(this.legalRoleType.allowableE2EntitiesIds)
    });
    
    this.kybService.getV4RoleTypes().subscribe(
      roleData => {
        this.v4RoleTypesResponse = roleData;
        this.v4RoleTypes = this.v4RoleTypesResponse.data;
        this.ngOnInit();
      },
      error => {}
    );

    this.editLegalRoleTypeForm.get('self').disable();

    if (this.editLegalRoleTypeForm.get('self').value) {
      this.editLegalRoleTypeForm.get('defaultRoleName').disable();
      this.editLegalRoleTypeForm.get('relationType').disable();
      this.editLegalRoleTypeForm.get('allowableE1EntityTypes').disable();
    } else {
      this.editLegalRoleTypeForm.get('defaultRoleName').enable();
      this.editLegalRoleTypeForm.get('relationType').enable();
      this.editLegalRoleTypeForm.get('allowableE1EntityTypes').enable();
    }

    this.newCurrentRoleType = this.countRoleType(legalPersonType);

    this.kybService.getDocumentTypes().subscribe(
      documentType => {
        this.documentTypesResponse = documentType;
        this.documentTypes = this.documentTypesResponse.data;
        this.kybService.getEntityTypes().subscribe(
          entityTypesResponse => {
            this.entityTypesResponse = entityTypesResponse;
            this.entityTypes = this.entityTypesResponse.data;
            this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
          },
          error => {}
        );
      },
      error => {}
    );
  }

  editRole(editRoleTemplate: TemplateRef<any>, id: number) {
    if (this.roleTypeId === id) {
      this.kybService.getV4RoleTypesById(id).subscribe(
        roleType => {
          this.v4RoleTypeResponse = roleType;
          this.v4RoleType = this.v4RoleTypeResponse.data;
          if (this.switchedDocuments && this.switchedDocuments.length > 0) {
            this.requiredDocuments = this.switchedDocuments;
          } else {
            this.requiredDocuments = Object.values(this.v4RoleType.requiredDocumentGroups);
          }
          this.editRoleModalRef = this.modalService.show(editRoleTemplate, { backdrop: 'static', class: 'edit-role' });
          this.initDocumentTable();
        },
        error => {}
      );
    } else {
      this.roleTypeId = id;

      this.kybService.getV4RoleTypesById(id).subscribe(
        roleType => {
          this.v4RoleTypeResponse = roleType;
          this.v4RoleType = this.v4RoleTypeResponse.data;
          this.requiredDocuments = Object.values(this.v4RoleType.requiredDocumentGroups);
          this.editRoleModalRef = this.modalService.show(editRoleTemplate, { backdrop: 'static', class: 'edit-role' });
          this.initDocumentTable();
        },
        error => {}
      );
    }
  }

  save() {
    this.kybService.getV4RoleTypesById(this.legalRoleType.id).subscribe(
      roleType => {
        this.v4RoleTypeResponse = roleType;
        this.v4RoleType = this.v4RoleTypeResponse.data;
        this.newV4RoleType = this.countRoleType(this.v4RoleType);

        let updateModel: any = {};

        if (this.newV4RoleType.self) {
          updateModel = {
            defaultRisk: this.editLegalRoleTypeForm.get('defaultRisk').value,
            id: this.newV4RoleType.id,
            requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
            requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
          };
        } else {
          updateModel = {
            allowableE1EntityTypes: this.editLegalRoleTypeForm.get('allowableE1EntityTypes').value,
            allowableE2EntityTypes: this.editLegalRoleTypeForm.get('allowableE2EntityTypes').value,
            defaultRisk: this.editLegalRoleTypeForm.get('defaultRisk').value,
            defaultRoleName: this.editLegalRoleTypeForm.get('defaultRoleName').value,
            id: this.newV4RoleType.id,
            relationType: this.editLegalRoleTypeForm.get('relationType').value,
            requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
            requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
          };
        }

        this.kybService.updateV4RoleType(updateModel).subscribe(
          data => {
            this.editModalRef.hide();
            this.alertService.showSuccess('Type edited successfully.');

            this.kybService.getV4RoleTypes().subscribe(
              roleData => {
                this.v4RoleTypesResponse = roleData;
                this.v4RoleTypes = this.v4RoleTypesResponse.data;
                this.ngOnInit();
              },
              error => {}
            );
          },
          error => {
            this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
          }
        );
      },
      error => {}
    );
  }

  countRoleType(roleTypeTemp: any) {
    const allowableE1EntitiesIds = [];
    const allowableE2EntitiesIds = [];
    const roleType = roleTypeTemp;
    let count = 1;
    let name = '';
    const requiredDocumentGroupsIds = [];

    for (let i = 0; i < roleTypeTemp.allowableE1EntityTypes.length; i++) {
      allowableE1EntitiesIds.push(roleTypeTemp.allowableE1EntityTypes[i].id);
    }

    for (let j = 0; j < roleTypeTemp.allowableE2EntityTypes.length; j++) {
      allowableE2EntitiesIds.push(roleTypeTemp.allowableE2EntityTypes[j].id);
    }

    Object.keys(roleType).forEach(key => {
      if (key === 'auditData') {
        delete roleType[key];
      } else if (key === 'requiredDocumentGroups') {
        for (let k = 0; k < this.requiredDocuments.length; k++) {
          const temporaryIds = [];

          for (let l = 0; l < this.requiredDocuments[k].documents.length; l++) {
            temporaryIds.push(this.requiredDocuments[k].documents[l].id);
          }

          requiredDocumentGroupsIds.push(temporaryIds);
        }
      } else if (key === 'requiredDocumentTypes') {
        for (let k = 0; k < roleType.requiredDocumentTypes.length; k++) {
          const temporaryIds = [];

          temporaryIds.push(roleType.requiredDocumentTypes[k].id);
        }
      }
    });

    roleType.allowableE1EntitiesIds = allowableE1EntitiesIds;
    roleType.allowableE2EntitiesIds = allowableE2EntitiesIds;
    roleType.requiredDocumentGroups = [];

    requiredDocumentGroupsIds.forEach(element => {
      const newDocuments: any = {};

      newDocuments.documents = element;
      newDocuments.riskLevel = 'LOW';

      this.requiredDocuments.forEach(requiredDocument => {
        requiredDocument.documents.forEach(document => {
          if (newDocuments.documents.includes(document.id)) {
            newDocuments.riskLevel = requiredDocument.riskLevel;
          }
        });
      });

      name = 'document' + count;
      roleType.requiredDocumentGroups[name] = newDocuments;
      count++;
    });

    roleType.requiredDocumentGroups = { ...roleType.requiredDocumentGroups };

    return roleType;
  }

  saveRole() {
    this.newV4RoleType = this.countRoleType(this.v4RoleType);
    this.editRoleModalRef.hide();
    this.alertService.showSuccess('Document groups edited successfully.');

    let updateModel: any = {};

    this.kybService.getV4RoleTypes().subscribe(
      data => {
        this.v4RoleTypesResponse = data;
        this.v4RoleTypes = this.v4RoleTypesResponse.data;
      },
      error => {}
    );

    if (this.newV4RoleType.self) {
      updateModel = {
        defaultRisk: this.newV4RoleType.defaultRisk,
        id: this.newV4RoleType.id,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    } else {
      updateModel = {
        allowableE1EntityTypes: this.newV4RoleType.allowableE1EntitiesIds,
        allowableE2EntityTypes: this.newV4RoleType.allowableE2EntitiesIds,
        defaultRisk: this.newV4RoleType.defaultRisk,
        defaultRoleName: this.newV4RoleType.defaultRoleName,
        id: this.newV4RoleType.id,
        relationType: this.newV4RoleType.relationType,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    }

    this.kybService.updateV4RoleType(updateModel).subscribe(
      roleType => {
        this.kybService.getV4RoleTypesById(this.newV4RoleType.id).subscribe(
          roleTypeById => {
            this.v4RoleTypeResponse = roleTypeById;
            this.v4RoleType = this.v4RoleTypeResponse.data;
            this.ngOnInit();
            if (this.switchedDocuments && this.switchedDocuments.length > 0) {
              this.requiredDocuments = this.switchedDocuments;
            } else {
              this.requiredDocuments = Object.values(this.v4RoleType.requiredDocumentGroups);
            }
          },
          error => {}
        );
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }

  addAND(addDocumentTypeTemplate: TemplateRef<any>): void {
    this.currentDocumentType = {};
    this.isAND = true;
    this.isOR = false;
    this.addModalRef = this.modalService.show(addDocumentTypeTemplate, { backdrop: 'static' });
  }

  addOR(addDocumentTypeTemplate: TemplateRef<any>, id: number): void {
    this.currentDocumentType = {};
    this.idOR = id;
    this.isAND = false;
    this.isOR = true;
    this.addModalRef = this.modalService.show(addDocumentTypeTemplate, { backdrop: 'static' });
  }

  addDocument(): void {
    this.documentTypes.forEach(document => {
      if (document.id === this.currentDocumentType) {
        this.selectedDocumentType = document;
      }
    });

    this.newV4RoleType = this.countRoleType(this.v4RoleType);

    if (this.isAND) {
      let newDocumentId = 0;
      let newDocumentName = '';

      if (Object.keys(this.newV4RoleType.requiredDocumentGroups).length > 0) {
        // tslint:disable-next-line:max-line-length
        newDocumentId = +Object.keys(this.newV4RoleType.requiredDocumentGroups).splice(-1)[0].charAt(Object.keys(this.newV4RoleType.requiredDocumentGroups).splice(-1)[0].length - 1) + 1;
        newDocumentName = 'document' + newDocumentId;
      } else {
        newDocumentName = 'document1';
      }

      const newDocuments: any = {};

      newDocuments.documents = [ this.selectedDocumentType.id ];
      newDocuments.riskLevel = this.currentRiskLevel;

      this.newV4RoleType.requiredDocumentGroups[newDocumentName] = newDocuments;
    } else if (this.isOR) {
      Object.values(this.newV4RoleType.requiredDocumentGroups).forEach((requiredDocumentGroup: any) => {
        if (requiredDocumentGroup.documents.indexOf(this.idOR) !== -1) {
          requiredDocumentGroup.documents.push(this.selectedDocumentType.id);
        }
      });
    }

    this.newV4RoleType.allowableE1EntityTypes = this.newV4RoleType.allowableE1EntitiesIds;
    this.newV4RoleType.allowableE2EntityTypes = this.newV4RoleType.allowableE2EntitiesIds;

    let updateModel: any = {};

    if (this.newV4RoleType.self) {
      updateModel = {
        defaultRisk: this.newV4RoleType.defaultRisk,
        id: this.newV4RoleType.id,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    } else {
      updateModel = {
        allowableE1EntityTypes: this.newV4RoleType.allowableE1EntitiesIds,
        allowableE2EntityTypes: this.newV4RoleType.allowableE2EntitiesIds,
        defaultRisk: this.newV4RoleType.defaultRisk,
        defaultRoleName: this.newV4RoleType.defaultRoleName,
        id: this.newV4RoleType.id,
        relationType: this.newV4RoleType.relationType,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    }

    this.kybService.updateV4RoleType(updateModel).subscribe(
      roleType => {
        this.kybService.getV4RoleTypesById(this.newV4RoleType.id).subscribe(
          roleTypeById => {
            this.v4RoleTypeResponse = roleTypeById;
            this.v4RoleType = this.v4RoleTypeResponse.data;
            this.requiredDocuments = Object.values(this.v4RoleType.requiredDocumentGroups);
          },
          error => {}
        );
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );

    this.addModalRef.hide();
  }

  removeDocument(removeRoleTemplate: TemplateRef<any>, id: number): void {
    this.removeId = id;
    this.removeModalRef = this.modalService.show(removeRoleTemplate, { backdrop: 'static' });
  }

  remove(): void {
    this.removeModalRef.hide();

    this.newV4RoleType = this.countRoleType(this.v4RoleType);

    Object.entries(this.newV4RoleType.requiredDocumentGroups).forEach((requiredDocumentGroup: any) => {
      if (requiredDocumentGroup[1].documents.indexOf(this.removeId) !== -1) {
        requiredDocumentGroup[1].documents.splice(requiredDocumentGroup[1].documents.indexOf(this.removeId), 1);

        if (requiredDocumentGroup[1].documents.length === 0) {
          delete this.newV4RoleType.requiredDocumentGroups[requiredDocumentGroup[0]];
        }
      }
    });

    this.newV4RoleType.allowableE1EntityTypes = this.newV4RoleType.allowableE1EntitiesIds;
    this.newV4RoleType.allowableE2EntityTypes = this.newV4RoleType.allowableE2EntitiesIds;

    let updateModel: any = {};

    if (this.newV4RoleType.self) {
      updateModel = {
        defaultRisk: this.newV4RoleType.defaultRisk,
        id: this.newV4RoleType.id,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    } else {
      updateModel = {
        allowableE1EntityTypes: this.newV4RoleType.allowableE1EntitiesIds,
        allowableE2EntityTypes: this.newV4RoleType.allowableE2EntitiesIds,
        defaultRisk: this.newV4RoleType.defaultRisk,
        defaultRoleName: this.newV4RoleType.defaultRoleName,
        id: this.newV4RoleType.id,
        relationType: this.newV4RoleType.relationType,
        requiredDocumentGroups: this.newV4RoleType.requiredDocumentGroups,
        requiredDocumentTypes: this.newV4RoleType.requiredDocumentTypes
      };
    }

    this.kybService.updateV4RoleType(updateModel).subscribe(
      roleType => {
        this.kybService.getV4RoleTypesById(this.newV4RoleType.id).subscribe(
          roleTypeById => {
            this.v4RoleTypeResponse = roleTypeById;
            this.v4RoleType = this.v4RoleTypeResponse.data;
            this.requiredDocuments = Object.values(this.v4RoleType.requiredDocumentGroups);
          },
          error => {}
        );
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.newCurrentRoleType.allowableE1EntityTypes = [];
    this.newCurrentRoleType.allowableE2EntityTypes = [];
    this.newCurrentRoleType.allowableE1EntitiesIds = [];
    this.newCurrentRoleType.allowableE2EntitiesIds = [];
    this.currentRoleType = {};
    this.currentRoleType.self = false;

    this.addLegalRoleTypeForm = new UntypedFormGroup({
      defaultRoleName: new UntypedFormControl(null, Validators.required),
      defaultRisk: new UntypedFormControl(null, Validators.required),
      relationType: new UntypedFormControl(null, Validators.required),
      allowableE1EntityTypes: new UntypedFormControl([], Validators.required),
      allowableE2EntityTypes: new UntypedFormControl([])
    });

    if (!(this.modalCount)) {
      this.kybService.getDocumentTypes().subscribe(
        data => {
          this.documentTypesResponse = data;
          this.documentTypes = this.documentTypesResponse.data;
          this.kybService.getEntityTypes().subscribe(
            entityTypesResponse => {
              this.entityTypesResponse = entityTypesResponse;
              this.entityTypes = this.entityTypesResponse.data;
              this.handleMultiAddModalRef.push(this.modalService.show(addTemplate, { backdrop: 'static' }));
            },
            error => {}
          );
        },
        error => {}
      );
      this.modalCount += 1;
    }

  }

  handleMultipleCloseButton() {
    if (this.handleMultiAddModalRef.length === 1) {
      this.handleMultiAddModalRef[0].hide();
    } else {
      this.handleMultiAddModalRef[this.handleMultiAddModalRef.length - 1].hide();
    }
    this.handleMultiAddModalRef.pop();
    if (this.modalCount) {
      this.modalCount = 0;
    }
  }

  selectAllE1(addOrEdit: string) {
    this.newCurrentRoleType.allowableE1EntitiesIds = [];

    for (let i = 0; i < this.entityTypes.length; i++) {
      this.newCurrentRoleType.allowableE1EntitiesIds.push(this.entityTypes[i].id);
    }

    if (addOrEdit === 'add') {
      this.addLegalRoleTypeForm.get('allowableE1EntityTypes').setValue(this.newCurrentRoleType.allowableE1EntitiesIds);
    } else {
      this.editLegalRoleTypeForm.get('allowableE1EntityTypes').setValue(this.newCurrentRoleType.allowableE1EntitiesIds);
    }
  }

  selectAllE2(addOrEdit: string) {
    this.newCurrentRoleType.allowableE2EntitiesIds = [];

    for (let i = 0; i < this.entityTypes.length; i++) {
      this.newCurrentRoleType.allowableE2EntitiesIds.push(this.entityTypes[i].id);
    }

    if (addOrEdit === 'add') {
      this.addLegalRoleTypeForm.get('allowableE2EntityTypes').setValue(this.newCurrentRoleType.allowableE2EntitiesIds);
    } else {
      this.editLegalRoleTypeForm.get('allowableE2EntityTypes').setValue(this.newCurrentRoleType.allowableE2EntitiesIds);
    }
  }

  add() {
    const updateModel = {
      allowableE1EntityTypes: this.addLegalRoleTypeForm.get('allowableE1EntityTypes').value,
      allowableE2EntityTypes: this.addLegalRoleTypeForm.get('allowableE2EntityTypes').value,
      defaultRisk: this.addLegalRoleTypeForm.get('defaultRisk').value,
      defaultRoleName: this.addLegalRoleTypeForm.get('defaultRoleName').value,
      relationType: this.addLegalRoleTypeForm.get('relationType').value
    };

    if (this.newCurrentRoleType.allowableE1EntityTypes) {
      this.currentRoleType.allowableE1EntityTypes = this.newCurrentRoleType.allowableE1EntityTypes;
    }

    if (this.newCurrentRoleType.allowableE2EntityTypes) {
      this.currentRoleType.allowableE2EntityTypes = this.newCurrentRoleType.allowableE2EntityTypes;
    }

    this.kybService.createRoleType(updateModel).subscribe(
      data => {
        this.handleMultipleCloseButton();
        this.alertService.showSuccess('Type added successfully.');
        this.currentRoleType = {};
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }
}
