import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VerifiedEntity } from './verifiedEntity';
import { Observable } from 'rxjs';
import { ApiService } from './../../api.service';

/**
 * Implements API verificatiom calls to the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private httpOptions = {};

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  verifyToken(token: string): Observable<VerifiedEntity> {
    return this.apiService.get('auth/onboard/' + token);
  }

  deactivateToken(token: string): Observable<any> {
    return this.apiService.delete('auth/onboard/' + token);
  }
}
