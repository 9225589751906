<div class="container-fluid no-padding" style="padding: 0 18px;">
    <div class="row">
      <div class="col-lg-2 box">
        <div style="height: 20px"></div>
        <div class="sort">
          <div class="row">
            <div class="col-lg-12">
              Sort<br>
              <div style="height: 20px;"></div>
              <span (click)="sort('rel')"><a class="rel">Most relevant</a></span><br>
              <span (click)="sort('desc')"><a class="desc">Newest</a></span><br>
              <span (click)="sort('asc')"><a class="asc">Oldest</a></span><br>
              <div style="height: 20px;"></div>
            </div>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="filters">
          <div class="row">
            <div class="col-lg-12">
              Filter by date<br>
              <div style="height: 20px;"></div>
              <div class="source-list">
                <div class="col-lg-12" style="padding-left: 0px;">
                  <input type="text"
                         placeholder="Enter date range..."
                         class="filter-input"
                         bsDaterangepicker
                         placement = "right"
                         [bsConfig]="dpConfig"
                         style="width: 100%; padding: .6em;"
                         [(ngModel)]="dateRange"
                         (ngModelChange)="dateRangeChanged()"
                         >
                </div>
                <br>
                <br>
                <button (click)="resetFilter()" style="font-size: 12px; padding: 6px 25px; border-radius: 20px;">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 25px"></div>
        <div class="filters">
          <div class="row">
            <div class="col-lg-12">
              Filter by source<br>
              <div style="height: 20px;"></div>
              <div>
                <div class="filter-wrap">
                  <input style="padding: .6em;" [(ngModel)]="filterSearch" placeholder=" Type a source name..." class="filter-input">
                  <div style="height: 10px;"></div>
                  <div class="clear-selection" tooltip="Click to Clear Selections" (click)="clearSelection()" *ngIf="selected_count">Clear All</div>
                </div>
              </div>
              <div class="source-list">
                <div *ngFor="let source of sources | filter: filterSearch" class="source-item" style="display: flex; align-items: flex-start; margin-top: 20px;"> 
                  <input (change)="getSelected()" type="checkbox"
                           name="sources"
                           value="{{source.id}}"
                           [(ngModel)]="source.selected" (ngModelChange)="sourceFilter()" style="margin-right: 10px;"> 
                  <span class="source-text" tooltip="{{source.fullName}}"><b>{{source.name}}</b> ({{source.docCount}})</span>
                  <div style="height: 13px;"></div>
                </div>
                <button (click)="clearSelection()" style="font-size: 12px; padding: 6px 25px; margin-top: 28px; border-radius: 20px;">Reset</button>
              </div>
              <div style="height: 15px;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10" style="padding-right: 0px;" >
        <div class="row" style="padding-left: 0px; margin: 0px;">
            <form name="form" autocomplete="off" style="display: flex; align-items: center;" novalidate>
              <div style="padding-right: 0px; padding-left: 0px; width: 100%;">
                <div class="form-group" style="text-align: center; margin-bottom: 0px;">
                      <div class="form-group" style="margin-bottom: 0px;">
                        <input id="searchQuery" type="text" class="form-control" name="searchQuery" [(ngModel)]="searchQuery" placeholder="Type a new search query..." required />
                      </div>
                </div>
              </div>
              <div style="padding-left: 0;">
                <button class="btn" type="submit" style="padding: 3;text-align: center;font-size: 20px;background-color: #F8CC20;border-radius: 0px;" (click)="search()">
                  <i class="fa fa-search" aria-hidden="true" style="padding-left: 5px;padding-top: 2px;text-align: center;color: white;"></i>
                </button>
              </div>
            </form>
        </div>
        <div style="height: 15px"></div>
        <div class="text-center">
          <pagination [totalItems]="totalItems" [itemsPerPage]="pageSize" [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"></pagination>
        </div>
        <div *ngIf="pep && !filtered" class="pep-hits" >
          <div class="btn btn-link btn-block clearfix" style="background-color: #F8CC20;color: white;font-weight: bold;cursor: default;border-radius: 0px;" accordion-heading>
              <div class="pull-left float-left" style="font-family: 'Open Sans', sans-serif;"><img src="../../../../../assets/images/icons/PNG/hits.png" width="50" />&nbsp;&nbsp;&nbsp;&nbsp;WARNING! Some results are Politically Exposed</div>
              <div class="pull-right float-right"><button class="show-only-hits" style="border-radius: 20px;" (click)="showPEP()">Show only PEP results</button></div>
          </div>
        </div>
        <div style="height: 20px;"></div>
        <div *ngIf="sanction && !filtered" class="pep-hits" >
          <div class="btn btn-link btn-block clearfix" style="background-color: #cc0000;color: white;font-weight: bold;cursor: default;border-radius: 0px;" accordion-heading>
            <div class="pull-left float-left" style="font-family: 'Open Sans', sans-serif;"><img src="../../../../../assets/images/icons/PNG/hits.png" width="50" />&nbsp;&nbsp;&nbsp;&nbsp;WARNING! Some results are Sanctioned</div>
            <div class="pull-right float-right"><button style="border-radius: 20px;" class="show-only-hits" (click)="showSanction()">Show only Sanctioned results</button></div>
          </div>
        </div>
        <div *ngIf="filtered" class="pep-hits" >
          <div class="btn btn-link btn-block clearfix" style="background-color: #cc0000;color: white;font-weight: bold;cursor: default;border-radius: 0px;" accordion-heading>
            <div class="pull-left float-left" style="font-family: 'Open Sans', sans-serif;"><img src="../../../../../assets/images/icons/PNG/hits.png" width="50" />&nbsp;&nbsp;&nbsp;&nbsp;WARNING! You are showing only PEP/Sanctioned results</div>
            <div class="pull-right float-right"><button style="border-radius: 20px;" class="show-only-hits" (click)="showAll()">Show All results</button></div>
          </div>
        </div>
      <div style="height: 20px"></div>
      <div class="text-center" *ngIf="loading">
        <img src={{loadingImg}} width="10%"/>
      </div>
            <div class="results-panel">
              <ng-container *ngFor="let result of results.results; index as i">
                <ng-container *ngIf="result?.fields?.type?.value == 'news_article' || result?.fields?.type?.value == 'news-article' || result?.type == 'news_article'">
                  <div class="result">
                    <div class="row">
                      <div class="col-md-3 text-center" style="padding-right: 0px;">
                        <br/>
                        <img src="../../../assets/images/icons/PNG/report.png" tooltip="News article" width="40%"/>
                      </div>
                      <div class="col-md-9" style="padding-left: 0px;">
                          <h4><a href="{{result?.sourceUrl}}" target="_blank">{{result?.fields?.headline?.value | truncatetext:90}}</a></h4>
                          <p style="padding-top: 0px;"><b>Publication date:</b> {{result?.fields?.timestamp?.value}}<br/>
                          </p>
                          <div class="snippet">
                              {{result?.fields?.snippet?.snippet}}...
                          </div>
                      </div>
                        <br>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="result?.type == 'Entity'">
                  <ng-container *ngIf="result?.entities[0]?.fields?.pep == 'true'">
                    <div class="result">
                        <div class="row">
                          <div class="col-md-3 text-center" style="padding-right: 0px;">
                            <br/>
                            <img src="../../../assets/images/icons/PNG/person.png" tooltip="This person is a PEP" width="40%"/>
                          </div>
                          <div class="col-md-9" style="padding-left: 0px;">
                              <h4 *ngIf="result?.entities['0'].fields?.entityName != undefined && result?.fields['source-display-name'] != undefined">{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                              <h4 *ngIf="result?.fields['source-display-name'] == undefined && result.entities['0'].fields['source-url'] != undefined"><a [href]="result.entities['0'].fields['source-url']" target="_blank">{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</a></h4>
                              <h4 *ngIf="result?.fields['source-display-name'] == undefined && result.entities['0'].fields['source-url'] == undefined">{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</h4>
                              <p *ngIf="result?.entities['0']?.fields?.description" style="padding-top: 0px;">{{result?.entities["0"].fields?.description}}<br/>
                              </p>
                              <div class="snippet">
                                <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['status']"><b>Status:</b> {{result?.entities[0].fields["status"]}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Date of birth:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['date-of-death']"><b>Date of death:</b> {{result?.entities[0].fields["date-of-death"]}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['nationality']"><b>Nationality:</b> {{result?.entities[0].fields["nationality"]}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>PEP programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['type'] && result?.entities[0]?.fields['type'] != 'source_entity'"><b>Type of PEP:</b> {{result?.entities[0].fields["type"]}}<br></span>
                                <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                              </div>
                          </div>
                            <br>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="result?.entities[0]?.fields?.pep == 'false' && result?.entities[0]?.fields?.sanctioned == 'false'">
                        <div class="result">
                            <div class="row">
                              <div class="col-md-3 text-center" style="padding-right: 0px;">
                                <br/>
                                <img src="../../../assets/images/icons/PNG/person_ok.png" tooltip="This person is not a PEP" width="40%"/>
                              </div>
                              <div class="col-md-9" style="padding-left: 0px;">
                                  <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                                  <p *ngIf="result?.entities['0']?.fields?.description" style="padding-top: 0px;">{{result?.entities["0"].fields?.description}}<br></p>
                                  <div class="snippet">
                                    <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                    <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                    <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                    <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                    <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                    <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                  </div>
                              </div>
                                <br>
                            </div>
                          </div>
                    </ng-container>
                    <ng-container *ngIf="result?.entities[0]?.fields?.sanctioned == 'true'">
                      <ng-container *ngIf="result?.entities[0]?.entityType == 'PersonEntity' || result?.entities[0]?.entityType == 'Person:Entity'">
                          <ng-container *ngIf="(result?.fields | json) != '{}'">
                              <div class="result">
                                  <div class="row">
                                    <div class="col-md-3 text-center" style="padding-right: 0px;">
                                      <br/>
                                      <img src="../../../assets/images/icons/PNG/person_sanctioned.png" tooltip="This person is Sanctioned" width="40%"/>
                                    </div>
                                    <div class="col-md-9" style="padding-left: 0px;">
                                        <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                                        <p *ngIf="result?.entities[0]?.fields?.remark" style="padding-top: 0px;">{{result?.entities[0].fields?.remark}}<br/>
                                        </p>
                                        <div class="snippet">
                                          <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['title']"><b>Title:</b> {{result?.entities[0].fields?.title}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['place-of-birth']"><b>Place of birth:</b> {{result?.entities[0].fields["place-of-birth"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['country-of-birth']"><b>Country of birth:</b> {{result?.entities[0].fields["country-of-birth"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['spoken-languages']"><b>Spoken languages:</b> {{result?.entities[0].fields["spoken-languages"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['charges']"><b>Charges:</b> {{result?.entities[0].fields["charges"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents'] && result?.entities[0]?.fields['identity-documents'] != ''"><b>Identity documents:</b> 
                                            <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                              <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                            </ul>
                                          </span>  
                                          <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>            
                                          <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>                      
                                          <span *ngIf="result?.entities[0]?.fields['distinguishing-marks']"><b>Distinguishing marks:</b> {{result?.entities[0].fields['distinguishing-marks']}}<br></span>
                                        </div>
                                    </div>
                                      <br>
                                  </div>
                                </div>
                          </ng-container>
                          <ng-container *ngIf="(result?.fields | json) == '{}'">
                              <div class="result">
                                  <div class="row">
                                    <div class="col-md-3 text-center" style="padding-right: 0px;">
                                      <br/>
                                      <img src="../../../assets/images/icons/PNG/person_sanctioned.png" tooltip="This person is Sanctioned" width="40%"/>
                                    </div>
                                    <div class="col-md-9" style="padding-left: 0px;">
                                        <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</h4>
                                        <p *ngIf="result?.entities[0]?.fields?.remark" style="padding-top: 0px;">{{result?.entities[0].fields?.remark}}<br/>
                                        </p>
                                        <div class="snippet">
                                          <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['title']"><b>Title:</b> {{result?.entities[0].fields?.title}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['place-of-birth']"><b>Place of birth:</b> {{result?.entities[0].fields["place-of-birth"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['country-of-birth']"><b>Country of birth:</b> {{result?.entities[0].fields["country-of-birth"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['spoken-languages']"><b>Spoken languages:</b> {{result?.entities[0].fields["spoken-languages"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['charges']"><b>Charges:</b> {{result?.entities[0].fields["charges"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents'] && result?.entities[0]?.fields['identity-documents'] != ''"><b>Identity documents:</b> 
                                            <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                              <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                            </ul>
                                          </span>  
                                          <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>            
                                          <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>                      
                                          <span *ngIf="result?.entities[0]?.fields['distinguishing-marks']"><b>Distinguishing marks:</b> {{result?.entities[0].fields['distinguishing-marks']}}<br></span>
                                        </div>
                                    </div>
                                      <br>
                                  </div>
                                </div>
                          </ng-container>
                      </ng-container>
                      <ng-container *ngIf="result?.entities[0]?.entityType == 'OrganizationEntity' || result?.entities[0]?.entityType == 'Organization:Entity'">
                          <ng-container *ngIf="(result?.fields | json) != '{}'">
                            <div class="result">
                                <div class="row">
                                  <div class="col-md-3 text-center" style="padding-right: 0px;">
                                    <br>
                                    <img src="../../../assets/images/icons/PNG/organization_sanctioned.png" tooltip="This organization is Sanctioned" width="40%"/>
                                  </div>
                                  <div class="col-md-9" style="padding-left: 0px;">
                                      <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"]?.value}})</h4>
                                      <p *ngIf="result?.entities[0]?.fields?.description && result?.fields['source-display-name']?.value != 'US Treasury Consolidated Sanctions'" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>
                                      <div class="snippet">
                                          <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>     
                                          <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents']"><b>Identity documents:</b> 
                                            <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                              <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                            </ul>
                                          </span>  
                                          <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>     
                                          <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>                                                               
                                      </div>
                                  </div>
                                    <br>
                                </div>
                              </div>
                          </ng-container>
                          <ng-container *ngIf="(result?.fields | json) == '{}'">
                              <div class="result">
                                  <div class="row">
                                    <div class="col-md-3 text-center" style="padding-right: 0px;">
                                      <br>
                                      <img src="../../../assets/images/icons/PNG/organization_sanctioned.png" tooltip="This organization is Sanctioned" width="40%"/>
                                    </div>
                                    <div class="col-md-9" style="padding-left: 0px;">
                                        <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</h4>
                                        <p *ngIf="result?.entities[0]?.fields?.description && result?.fields['source-display-name']?.value != 'US Treasury Consolidated Sanctions'" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>
                                        <div class="snippet">
                                            <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>     
                                            <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['identity-documents']"><b>Identity documents:</b> 
                                              <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                                <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                              </ul>
                                            </span>  
                                            <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>     
                                            <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>                                                               
                                        </div>
                                    </div>
                                    <br>
                                  </div>
                                </div>
                            </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="result?.entities[0]?.fields?.sanctioned == 'false'">
                        <ng-container *ngIf="result?.entities[0]?.entityType == 'PersonEntity' || result?.entities[0]?.entityType == 'Person:Entity'">
                            <div class="result">
                                <div class="row">
                                  <div class="col-md-3 text-center" style="padding-right: 0px;">
                                    <br>
                                    <img src="../../../assets/images/icons/PNG/person_ok.png" tooltip="This person is not Sanctioned" width="40%"/>
                                  </div>
                                  <div class="col-md-9" style="padding-left: 0px;">
                                      <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                                      <p *ngIf="result?.entities[0]?.fields?.description" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>                                      
                                      <div class="snippet">
                                        <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                      </div>
                                  </div>
                                  <br>
                                </div>
                              </div>
                        </ng-container>
                        <ng-container *ngIf="result?.entities[0]?.entityType == 'OrganizationEntity' || result?.entities[0]?.entityType == 'Organization:Entity'">
                            <div class="result">
                                <div class="row">
                                  <div class="col-md-3 text-center" style="padding-right: 0px;">
                                    <br>
                                    <img src="../../../assets/images/icons/PNG/organization_ok.png" tooltip="This organization is not Sanctioned" width="40%"/>
                                  </div>
                                  <div class="col-md-9" style="padding-left: 0px;">
                                      <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                                      <p *ngIf="result?.entities[0]?.fields?.description" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>
                                      <div class="snippet">
                                        <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"]}}<br></span>
                                        <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                      </div>
                                  </div>
                                  <br>
                                </div>
                              </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="result?.entities[0]?.fields?.sanctioned == undefined && result?.entities[0]?.fields?.pep == undefined">
                        <ng-container *ngIf="result?.entities[0]?.entityType == 'PersonEntity' || result?.entities[0]?.entityType == 'Person:Entity'">
                            <ng-container *ngIf="(result?.fields | json) != '{}'">
                              <div class="result">
                                  <div class="row">
                                    <div class="col-md-3 text-center" style="padding-right: 0px;">
                                      <br>
                                      <img src="../../../assets/images/icons/PNG/person_ok.png" tooltip="This person is not Sanctioned or Politically Exposed" width="40%"/>
                                    </div>
                                    <div class="col-md-9" style="padding-left: 0px;">
                                        <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4>
                                        <p *ngIf="result?.entities[0]?.fields?.description" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>                                      
                                        <div class="snippet">
                                          <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['id-number']"><b>ID number:</b> {{result?.entities[0].fields["id-number"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>     
                                          <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['identity-documents']"><b>Identity documents:</b> 
                                            <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                              <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                            </ul>
                                          </span>  
                                          <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>     
                                          <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>   
                                        </div>
                                    </div>
                                    <br>
                                  </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(result?.fields | json) == '{}'">
                                <div class="result">
                                    <div class="row">
                                      <div class="col-md-3 text-center" style="padding-right: 0px;">
                                        <br>
                                        <img src="../../../assets/images/icons/PNG/person_ok.png" tooltip="This person is not Sanctioned or Politically Exposed" width="40%"/>
                                      </div>
                                      <div class="col-md-9" style="padding-left: 0px;">
                                          <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</h4>
                                          <p *ngIf="result?.entities[0]?.fields?.remark" style="padding-top: 0px;">{{result?.entities[0].fields?.remark}}<br></p>                                      
                                          <div class="snippet">
                                            <span *ngIf="result?.entities[0]?.fields?.gender"><b>Gender:</b> {{result?.entities[0].fields?.gender}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['birth-dates']"><b>Birth date:</b> {{result?.entities[0].fields["birth-dates"] | json}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['nationalities']"><b>Nationalities:</b> {{result?.entities[0].fields["nationalities"] | json}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"] | json}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['citizenship']"><b>Citizenship:</b> {{result?.entities[0].fields["citizenship"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['id-number']"><b>ID number:</b> {{result?.entities[0].fields["id-number"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['publication-date']"><b>Publication date:</b> {{result?.entities[0].fields["publication-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['listed-on']"><b>Listed on:</b> {{result?.entities[0].fields["listed-on"]}}<br></span>     
                                            <span *ngIf="result?.entities[0]?.fields['entry-into-force-date']"><b>Entry into force date:</b> {{result?.entities[0].fields["entry-into-force-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['identity-documents-details']"><b>Identity documents details:</b> {{result?.entities[0].fields['identity-documents-details']}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['identity-documents']"><b>Identity documents:</b> 
                                            <span *ngIf="result?.entities[0]?.fields['occupation']"><b>Occupation:</b> {{result?.entities[0].fields["occupation"]}}<br></span>   
                                            <span *ngIf="result?.entities[0]?.fields['country-of-residence']"><b>Country of residence:</b> {{result?.entities[0].fields["country-of-residence"]}}<br></span>   
                                              <ul *ngFor="let document of result?.entities[0]?.fields['identity-documents']">
                                                <li>{{document['Type']}}<br>Number: {{document['Number']}}<br>Name on document: {{document['Name on document']}}<br>Country: {{document['Country']}}<br>Issued by: {{document['Issued by']}}<br>Is diplomatic: {{document['Is diplomatic']}}<br>Is known false: {{document['Is known false']}}<br>Has been revoked by issuer: {{document['Revoked by issuer']}}<br>Remark: {{document['Remark']}}</li>
                                              </ul>
                                            </span>  
                                            <span *ngIf="result?.entities[0]?.fields['more-information']"><b>More information:</b> {{result?.entities[0].fields["more-information"]}}<br></span>     
                                            <span *ngIf="result?.entities[0]?.fields['comment']"><b>Comment:</b> {{result?.entities[0].fields["comment"]}}<br></span>   
                                          </div>
                                      </div>
                                      <br>
                                    </div>
                                  </div>
                              </ng-container>
                        </ng-container>
                        <ng-container *ngIf="result?.entities[0]?.entityType == 'OrganizationEntity' || result?.entities[0]?.entityType == 'Organization:Entity'">
                            <ng-container *ngIf="(result?.fields | json) != '{}'">
                              <div class="result">
                                  <div class="row">
                                    <div class="col-md-3 text-center" style="padding-right: 0px;">
                                      <br>
                                      <img src="../../../assets/images/icons/PNG/organization_ok.png" tooltip="This organization is not Sanctioned" width="40%"/>
                                    </div>
                                    <div class="col-md-9" style="padding-left: 0px;">
                                        <span *ngIf="result?.fields['source-display-name'].value == 'Netherlands company list'"><h4>{{result?.entities["0"].fields?.entityName}} (Netherlands Company Registry)</h4></span>
                                        <span *ngIf="result?.fields['source-display-name'].value != 'Netherlands company list'"><h4>{{result?.entities["0"].fields?.entityName}} ({{result?.fields["source-display-name"].value}})</h4></span>
                                        <p *ngIf="result?.entities[0]?.fields?.description" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br></p>
                                        <div class="snippet">
                                          <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"]}}<br></span>
                                          <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                        </div>
                                    </div>
                                    <br>
                                  </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="(result?.fields | json) == '{}'">
                                <div class="result">
                                    <div class="row">
                                      <div class="col-md-3 text-center" style="padding-right: 0px;">
                                        <br>
                                        <img src="../../../assets/images/icons/PNG/organization_ok.png" tooltip="This organization is not Sanctioned" width="40%"/>
                                      </div>
                                      <div class="col-md-9" style="padding-left: 0px;">
                                          <h4>{{result?.entities["0"].fields?.entityName}} ({{result?.entities["0"].id | parseSource}})</h4>                                          
                                          <p *ngIf="result?.entities[0]?.fields?.description" style="padding-top: 0px;">{{result?.entities[0].fields?.description}}<br/></p>
                                          <p *ngIf="result?.entities[0]?.fields['resa-description']" [innerHTML]="result.entities[0].fields['resa-description']" style="padding-top: 0px;"><br/></p>
                                          <div class="snippet">
                                            <span *ngIf="result?.entities[0]?.fields['first-seen']"><b>First seen:</b> {{result?.entities[0].fields["first-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['last-seen']"><b>Last seen:</b> {{result?.entities[0].fields["last-seen"] | date}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['addresses'] && result?.entities[0]?.fields['addresses'] != '[]'"><b>Addresses:</b> {{result?.entities[0].fields["addresses"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['entity-aka-names']"><b>AKA names:</b> {{result?.entities[0].fields["entity-aka-names"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['sanction-programs']"><b>Sanction programs:</b> {{result?.entities[0].fields["sanction-programs"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['category']"><b>Category:</b> {{result?.entities[0].fields["category"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['status']"><b>Status:</b> {{result?.entities[0].fields["status"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['country-of-origin']"><b>Country of origin:</b> {{result?.entities[0].fields["country-of-origin"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['dissolution-date']"><b>Dissolution Date:</b> {{result?.entities[0].fields["dissolution-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['incorporation-date']"><b>Incorporation Date:</b> {{result?.entities[0].fields["incorporation-date"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['accounts-category']"><b>Accounts Category:</b> {{result?.entities[0].fields["accounts-category"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['outstanding-mortgages'] && result?.entities[0]?.fields['outstanding-mortgages'] > 0"><b>Number of Outstanding Mortgages:</b> {{result?.entities[0].fields["outstanding-mortgages"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['general-partnerships'] && result?.entities[0]?.fields['general-partnerships'] > 0"><b>Number of General Partnerships:</b> {{result?.entities[0].fields["general-partnerships"]}}<br></span>
                                            <span *ngIf="result?.entities[0]?.fields['limited-partnerships'] && result?.entities[0]?.fields['limited-partnerships'] > 0"><b>Number of Limited Partnerships:</b> {{result?.entities[0].fields["limited-partnerships"]}}<br></span>
                                          </div>
                                      </div>
                                      <br>
                                    </div>
                                </div>
                              </ng-container>
                        </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="result?.fields?.type?.value == 'crextract'">
                    <div class="result">
                      <div class="row">
                        <div class="col-md-3 text-center" style="padding-right: 0px;">
                          <br>
                          <img src="../../../assets/images/icons/PNG/documents.png" tooltip="Company records extract" width="40%"/>
                        </div>
                        <div class="col-md-9" style="padding-left: 0px;">
                            <h4 (click)="openDetail(i)"><span tooltip="View full text"><a>{{result?.fields?.company_name?.value}}</a>&nbsp;&nbsp;<img src="../../../../../assets/images/icons/PNG/more.png" width="14px" style="padding-bottom: 5px;cursor: pointer;"/></span></h4>
                            <p style="padding-top: 0px;"><span *ngIf="result?.fields?.crn?.value"><b>CRN:</b> {{result?.fields?.crn?.value}} | </span><span *ngIf="result?.fields?.address?.value"><b>Adress:</b> {{result?.fields?.address?.value}} | </span><span *ngIf="result?.fields?.timestamp?.value"><b>Date:</b> {{result?.fields?.timestamp?.value}}</span><br></p>
                            <div class="snippet">
                                {{result?.fields?.snippet?.snippet}}...
                            </div>
                        </div>                          
                      </div>
                    </div>
                  </ng-container>
                    <div style="height: 20px"></div>
                </ng-container>
            </div>
        <div class="text-center" *ngIf="noResults" style="padding-right: 15px;">
            <div style="height: 50px;"></div>
            <div class="results-message text-center">
                No results found.<br>
            </div>
            <div style="height: 200px;"></div>
        </div>
        <div class="text-center">
            <pagination [totalItems]="totalItems" [itemsPerPage]="pageSize" [maxSize]="maxSize" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"></pagination>
        </div>
      </div>
    </div>
</div>
