import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

/**
 * Implements API calls to the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class CallsService {

  private httpOptions = {};
  private admins = new BehaviorSubject<any>(null);
  adminsList = this.admins.asObservable();
  admin = new BehaviorSubject<any>(null);
  isAdmin = this.admin.asObservable();
  formdata: FormData;
  response: any = {};
  evalFormdata: FormData;
  passwordFormdata: FormData;
  exportFormData: FormData;
  deleteFormdata: FormData;
  editFormData: FormData;
  createFormdata: FormData;
  remoteBatchData: FormData;
  whitelistedFormData: FormData;
  blacklistedFormData: FormData;
  moreInfoFormData: FormData;
  templateObject: any = {};

  constructor(private http: HttpClient) {}

  /**
   * Function to get all users currently in database
    * @param {any} role - The user role that we want to fetch users for (e.g. ADMIN, STANDARD_USER)
    * @returns {any} - All users who have the specified role
  */
  getAllUser(role: any) {
    this.httpOptions = {
      params: new HttpParams().append('role', role),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/users', this.httpOptions);
  }

  getKYCStatusCount(status: any) {
    this.httpOptions = {
      params: new HttpParams().append('status', status),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getKYCStatusCount', this.httpOptions);
  }

  getProofOfResidenceStatusCount(status: any) {
    this.httpOptions = {
      params: new HttpParams().append('status', status),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getProofOfResidenceStatusCount', this.httpOptions);
  }

  getIdentityDocumentStatusCount(status: any) {
    this.httpOptions = {
      params: new HttpParams().append('status', status),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getIdentityDocumentStatusCount', this.httpOptions);
  }

  getProbableMatchesCount() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getProbableMatchesCount', this.httpOptions);
  }

  getUsersCount(role: any) {
    this.httpOptions = {
      params: new HttpParams().append('role', role),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getUsersCount', this.httpOptions);
  }

  /**
   * Function to get details for a specific user - including the picture files.
   * @param {string} email - username of the user we want details for
   * @returns {boolean} - Details of the user you requested
  */
  getUserDetails(email: string) {
    this.httpOptions = {
      params: new HttpParams().append('email', email),
      headers: new HttpHeaders({ 'X-Auth-Token': localStorage.getItem('auth_token') })
    };

    return this.http.get('/api/v2/admin/userDetails', this.httpOptions);
  }

  /**
   * Function to get all user details currently in database
    * @param {any} role - The user role that we want to fetch users for (e.g. ADMIN, STANDARD_USER)
    * @returns {any} - Details of all users who have the specified role
  */
  getAllUserDetails(role: any) {
    this.httpOptions = {
      params: new HttpParams().append('role', role),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/users', this.httpOptions);
  }

  getIncompleteEntitiesForDelegate(delegate: any) {
    return this.http.get('/api/v2/user/incompleteForDelegate?delegate=' + delegate);
  }

  createEntity(entity: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-type': 'application/json' })
    };

    return this.http.post('/api/v2/user/createEntity', { firstName: entity.firstName, lastName: entity.lastName,
      entityType: entity.entityType, legalPersonType: entity.legalPersonType, owner: entity.owner,
      complete: entity.complete, delegate: entity.delegate }, this.httpOptions);
  }

  /**
   * Function for adding a new user.
   * @param {any} user - user information such as firstname, lastname, username .... See register.component.html for details
   * @param {File} passportFront - file with the front side of an identity document
   * @param {File} passportBack - file with the back side of an identity document
   * @param {File} selfie - file with a selfie
   * @param {File} proofOfResidence - file with the proof of residence
  */
  createUser(user: any, captcha: any) {
    this.createFormdata = new FormData();

    this.createFormdata.append('email', user.email);
    this.createFormdata.append('password', user.password);
    this.createFormdata.append('captcha', captcha);

    return this.http.post('/api/v2/user', this.createFormdata);
  }

  /**
   * Function for adding a new user without captcha - for development purposes.
  */
  createUserAdmin(email: any, password: any) {
    this.createFormdata = new FormData();

    this.createFormdata.append('email', email);
    this.createFormdata.append('password', password);

    return this.http.post('/api/v2/user/adminAdd', this.createFormdata);
  }

  /**
   * Function for adding user details for a user
  */
  addUserDetails(email: any, user: any) {
    this.formdata = new FormData();
    const address = user.addressStreet + ' ' + user.addressNumber + ', ' + user.addressCity + ', '
    + user.addressZIP + ', ' + user.addressCountry;
    this.formdata.append('email', email);
    this.formdata.append('firstName', user.firstName);
    this.formdata.append('lastName', user.lastName);
    this.formdata.append('taxCountry', user.taxCountry);
    this.formdata.append('nationality', user.nationality);
    this.formdata.append('dateOfBirth', user.dateOfBirth);
    this.formdata.append('address', address);

    if (user.telegramName) {
      this.formdata.append('telegramName', user.telegramName);
    }

    if (user.twitterName) {
      this.formdata.append('twitterName', user.twitterName);
    }

    if (user.linkedinProfile) {
      this.formdata.append('linkedinProfile', user.linkedinProfile);
    }

    if (user.facebookProfile) {
      this.formdata.append('facebookProfile', user.facebookProfile);
    }

    let urlString = '/api/v2/user/details?email=' + email + '&firstName=' + user.firstName +
      '&lastName=' + user.lastName + '&taxCountry=' + user.taxCountry;
      urlString += '&nationality=' + user.nationality + '&dateOfBirth=' + user.dateOfBirth + '&address=' + address + '&amount=' + 0;

    if (user.telegramName) {
      urlString += '&telegramName=' + user.telegramName;
    }

    if (user.twitterName) {
      urlString += '&twitterName=' + user.twitterName;
    }

    if (user.linkedinProfile) {
      urlString += '&linkedinProfile=' + user.linkedinProfile;
    }

    if (user.facebookProfile) {
      urlString += '&facebookProfile=' + user.facebookProfile;
    }

    const body = JSON.stringify(this.formdata);
    this.formdata.append('proofOfResidence', user.proofOfResidence);
    this.formdata.append('selfie', user.selfie);
    this.formdata.append('passportFront', user.passportFront);

    if (user.passportBack) {
      this.formdata.append('passportBack', user.passportBack);
    }

    return this.http.put(urlString, body);
  }

  /**
   * Adding user details through the batch uploader - for development purposes only
  */
  adminAddUser(email: any, password: any, user: any) {
    this.formdata = new FormData();
    const address = user.addressStreet + ' ' + user.addressNumber + ', ' + user.addressCity + ', '
    + user.addressZIP + ', ' + user.addressCountry;
    this.formdata.append('email', email);
    this.formdata.append('password', password);
    this.formdata.append('firstName', user.firstName);
    this.formdata.append('lastName', user.lastName);
    this.formdata.append('taxCountry', user.taxCountry);
    this.formdata.append('nationality', user.nationality);
    this.formdata.append('dateOfBirth', user.dateOfBirth);
    this.formdata.append('address', address);
    this.formdata.append('walletAddress', user.walletAddress);
    this.formdata.append('amount', user.amount);
    this.formdata.append('currencyType', user.currencyType);
    this.formdata.append('sourceOfFunds', user.sourceOfFunds);

    if (user.telegramName) {
      this.formdata.append('telegramName', user.telegramName);
    }

    if (user.twitterName) {
      this.formdata.append('twitterName', user.twitterName);
    }

    if (user.linkedinProfile) {
      this.formdata.append('linkedinProfile', user.linkedinProfile);
    }

    if (user.facebookProfile) {
      this.formdata.append('facebookProfile', user.facebookProfile);
    }

    let urlString = '/api/v2/admin/addUser?email=' + email + '&password=' + password + '&firstName='
      + user.firstName + '&lastName=' + user.lastName + '&taxCountry=' + user.taxCountry;
      urlString += '&nationality=' + user.nationality + '&dateOfBirth=' + user.dateOfBirth + '&address='
      + address + '&walletAddress=' + user.walletAddress + '&amount=' + user.amount;
      urlString += '&currencyType=' + user.currencyType + '&sourceOfFunds=' + user.sourceOfFunds;

    if (user.telegramName) {
      urlString += '&telegramName=' + user.telegramName;
    }

    if (user.twitterName) {
      urlString += '&twitterName=' + user.twitterName;
    }

    if (user.linkedinProfile) {
      urlString += '&linkedinProfile=' + user.linkedinProfile;
    }

    if (user.facebookProfile) {
      urlString += '&facebookProfile=' + user.facebookProfile;
    }

    const body = JSON.stringify(this.formdata);

    this.formdata.append('proofOfResidence', user.proofOfResidence);
    this.formdata.append('selfie', user.selfie);
    this.formdata.append('passportFront', user.passportFront);

    if (user.passportBack) {
      this.formdata.append('passportBack', user.passportBack);
    }

    return this.http.put(urlString, body);
  }

  /*
  * Adding a proof of residence document for a user
  */
  addProofOfResidence(email: any, proofOfResidence: File) {
    this.formdata = new FormData();

    this.formdata.append('email', email);
    this.formdata.append('prrofOfResidence', proofOfResidence); // verify the typo?

    return this.http.post('/api/v2/document/proofOfResidence', this.formdata);
  }

  addDocument(documentType: any, fileContent: File) {
    this.formdata = new FormData();

    this.formdata.append('fileContent', fileContent);

    return this.http.post('/api/v2/document?documentType=' + documentType, this.formdata);
  }

  setEntityStatus(id: any, status: any) {
    this.formdata = new FormData();
    this.formdata.append('entityStatus', status);

    return this.http.put('/api/v2/user/' + id + '/entityStatus', this.formdata);
  }

  setDossierStatus(id: any, status: any) {
    this.formdata = new FormData();

    this.formdata.append('dossierStatus', status);

    return this.http.put('/api/v2/user/' + id + '/dossierStatus', this.formdata);
  }

  setDossierRiskLevel(id: any, status: any) {
    this.formdata = new FormData();
    this.formdata.append('dossierRiskLevel', status);

    return this.http.put('/api/v2/user/' + id + '/dossierRiskLevel', this.formdata);
  }

  /*
  * Adding a passport document for a user
  */
  addPassport(email: any, passFront: File, passBack: File) {
    this.formdata = new FormData();

    this.formdata.append('email', email);
    this.formdata.append('passportFront', passFront);

    if (passBack) {
      this.formdata.append('passportBack', passBack);
    }

    return this.http.post('/api/v2/document/passport', this.formdata);
  }

  /*
   * Adding ID card document for a user
   */
  addIdCard(email: any, passFront: File, passBack: File) {
    this.formdata = new FormData();

    this.formdata.append('email', email);
    this.formdata.append('passportFront', passFront);
    this.formdata.append('passportBack', passBack);

    return this.http.post('/api/v2/document/id', this.formdata);
  }

  /**
   * Add video authentication video for a user
   * @param {any} email - username/email of the user we want to add video to
   * @param {File} selfieVideo - video file with the video authentication
   * @param {any} wordList - list of words that the user is supposed to say in the video
  */
  addVideoAuth(email: any, selfieVideo: File, wordList: any) {
    this.formdata = new FormData();

    this.formdata.append('email', email);
    this.formdata.append('selfieVideo', selfieVideo);
    this.formdata.append('wordList', wordList);

    return this.http.post('/api/v2/document/video', this.formdata);
  }

  /**
   * Helper function to access the video
  */
  getSelfieBlob(blobUrl: any) {
    return this.http.get(blobUrl, { responseType: 'blob' });
  }

  /**
   * Get a list of random words from the NATO alphabet
   * @param {any} email - username/email of the user we want to generate words for
   * @param {any} numberOfWords - the number of random words that you want to generate (generally 5)
  */
  getWordList(email: any, numberOfWords: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append('X-Auth-Token',
      JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v2/user/worldList?' + 'email=' + email + '&numberOfWords=' + numberOfWords, this.httpOptions);
  }

  /**
   * Function for editing a user.
   * @param {any} user - user information such as firstname, lastname, username .... See register.component.html for details
   * @param {File} passportFront - file with the front side of an identity document
   * @param {File} passportBack - file with the back side of an identity document
   * @param {File} selfie - file with a selfie
   * @param {File} proofOfResidence - file with the proof of residence
  */
  editUser(user: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.editFormData = new FormData();

    this.editFormData.append('firstName', user.firstName);
    this.editFormData.append('lastName', user.lastName);
    this.editFormData.append('userName', user.userName);
    this.editFormData.append('email', user.email);
    this.editFormData.append('password', user.password);
    this.editFormData.append('taxCountry', user.taxCountry);
    this.editFormData.append('nationality', user.nationality);
    this.editFormData.append('dateOfBirth', user.dateOfBirth);
    this.editFormData.append('address', user.address);
    this.editFormData.append('walletAddress', user.walletAddress);
    this.editFormData.append('amount', user.amount);
    this.editFormData.append('currencyType', user.currencyType);
    this.editFormData.append('sourceOfFunds', user.sourceOfFunds);

    if (user.telegramName) {
      this.editFormData.append('telegramName', user.telegramName);
    }

    if (user.twitterName) {
      this.editFormData.append('twitterName', user.twitterName);
    }

    if (user.linkedinProfile) {
      this.editFormData.append('linkedinProfile', user.linkedinProfile);
    }

    if (user.facebookProfile) {
      this.editFormData.append('facebookProfile', user.facebookProfile);
    }

    return this.http.post('/api/v2/admin/userData', this.editFormData, this.httpOptions);
  }

  /**
  * Function for running checks on users.
  * @param {any} userNames - array of usernames to run checks on
  */
  evalList(userNames: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('emails', userNames);

    return this.http.post('/api/v2/risk/eval', this.evalFormdata, this.httpOptions);
  }

  /**
  * Function for running checks on one user.
  * @param {any} userName - username of the person to run checks on
  */
  evalUser(userName: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('emails', userName);

    return this.http.post('/api/v2/risk/eval', this.evalFormdata, this.httpOptions);
  }

  /**
  * Function for changing passwords
  * @param {any} oldPassword - old password
  * @param {any} newPassword - new password
  */
  changePassword(oldPassword: any, newPassword: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.passwordFormdata = new FormData();

    this.passwordFormdata.append('oldPassword', oldPassword);
    this.passwordFormdata.append('newPassword', newPassword);

    return this.http.post('/api/v2/user/changePassword', this.passwordFormdata, this.httpOptions);
  }

  /**
   * Function to save a comment in to the database
   * @param {string} comment - comment text
   * @param {string} userName - username which we want to add comment to
  */
  saveComment(email: string, content: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('email', email);
    this.evalFormdata.append('content', content);

    return this.http.post('/api/v2/admin/comment', this.evalFormdata, this.httpOptions);
  }

  /**
   * Function to set a KYC status for a user
   * @param {string} userName - username which we want to set KYC status for
   * @param {string} kycStatus - value which we want to set (1 - Low, 2 - Medium, 3 - High)
  */
  setKYCStatus(email: string, kycStatus: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('email', email);
    this.evalFormdata.append('status', kycStatus);

    return this.http.post('/api/v2/admin/kycStatus', this.evalFormdata, this.httpOptions);
  }

  /**
   * Function to set a identity document status for a user
   * @param {string} userName - username which we want to set status for
   * @param {string} status - value which we want to set (green,red,yellow)
  */
  setIdentityDocumentStatus(email: string, status: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('email', email);
    this.evalFormdata.append('status', status);

    return this.http.post('/api/v2/admin/identityDocumentStatus', this.evalFormdata, this.httpOptions);
  }

  /**
   * Function to set a proof of residence status for a user
     * @param {string} userName - username which we want to set status for
     * @param {string} status - value which we want to set (green,red,yellow)
  */
  setProofOfResidenceStatus(email: string, status: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.evalFormdata = new FormData();

    this.evalFormdata.append('email', email);
    this.evalFormdata.append('status', status);

    return this.http.post('/api/v2/admin/proofOfResidenceStatus', this.evalFormdata, this.httpOptions);
  }

  /**
  * Function for deleting a user
  * @param {any} userName - username of user to delete
  */
  deleteUser(userName: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.deleteFormdata = new FormData();

    this.deleteFormdata.append('email', userName);

    return this.http.delete('/api/v2/admin/user?email=' + userName, this.httpOptions);
  }

  /**
  * Function for adding an admin
  */
  createAdmin(email: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.createFormdata = new FormData();

    this.createFormdata.append('email', email);

    return this.http.post('/api/v2/admin', this.createFormdata, this.httpOptions);
  }

  /**
  * Function for getting all users with specified roles. Takes special parameters generated by the Datatables JQuery plugin
  * @param {any} role - name of the role that we want to fetch (e.g. STANDARD_USER, ADMIN...)
  * @param {any} dataTablesParameters - parameters from dataTables see https://datatables.net/manual/server-side for details
  */
  getUsers(role: any, dataTablesParameters: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/v2/admin/usersForDatatables?role=' + role,
      {
        draw: dataTablesParameters.draw, start: dataTablesParameters.start, length: dataTablesParameters.length
      }, this.httpOptions);
  }


  /**
   * Function for getting a list of admins
   */
  getAdmins() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/getAdmins', null, this.httpOptions);
  }

  /**
   * Function for getting paginated probableMatches (screening hits that have not been evaluated yet)
   * Used in the Screening Results pop up in the UI
   * @param {any} limit - how many matches we want
   * @param {any} offset - from where the page should start
   *
   */
  getProbableMatches(limit: any, offset: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v3/admin/probableMatches?limit=' + limit + '&offset=' + offset, this.httpOptions);
  }

  /**
   * Function for getting paginated documents which have not been evaluated yet
   * Used in the Evaluate Documents pop up in the UI
   * @param {any} limit - how many documents we want
   * @param {any} offset - from where the page should start
   *
   */
  getUnevaluatedDocuments(limit: any, offset: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/admin/getDocuments?limit=' + limit + '&offset=' + offset, this.httpOptions);
  }

  /**
   * Function for setting the admin list observable
  */
  setAdminList(list: any) {
    this.admins.next(list);
  }

  /**
   * Function for setting the admin boolean observable
   */
  setAdmin(isAdmin: any) {
    this.admin.next(isAdmin);
  }

  /**
   * Helper function for getting an extensive list of domains used for generating fake emails
   */
  getFakeEmails() {
    return this.http.get('https://raw.githubusercontent.com/martenson/disposable-email-domains/master/disposable_email_blocklist.conf',
    { responseType: 'text' });
  }

  /**
   * Function for getting the base64 string of an image
   * @param fileId - The id of the file that we want to get (listed in the user details object)
   */
  getDocument(fileId: any) {
    this.httpOptions = {
      params: new HttpParams().append('fileId', fileId),
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/document', this.httpOptions);
  }

  /**
    * Function for saving a new email template
    */
  newTemplate(type: any, template: any) {
    this.templateObject = {};
    this.templateObject.template = template;

    if (type === 'REGISTRATION') {
      this.templateObject.type = 'REGISTRATION';
    }

    if (type === 'WHITELISTED') {
      this.templateObject.type = 'WHITELISTED';
    }

    if (type === 'BLACKLISTED') {
      this.templateObject.type = 'BLACKLISTED';
    }

    if (type === 'MORE_INFORMATION_NEEDED') {
      this.templateObject.type = 'MORE_INFORMATION_NEEDED';
    }

    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/customEmail/newTemplate', this.templateObject, this.httpOptions);
  }

  /**
    * Function for setting up a new mail server
    */
  mailServer(mailServer: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/customEmail/mailServer', mailServer, this.httpOptions);
  }

  /**
    * Function for matching faces on id document and video
    * @param {any} email - username of the person
    * @param {any} snapshot - a screenshot taken from the video authentication video
    */
  faceMatch(email: any, snapshot: any) {
    this.formdata = new FormData();
    this.formdata.append('email', email);
    this.formdata.append('snapshot', snapshot);

    return this.http.post('/api/v2/user/faceMatch', this.formdata);
  }

  /**
    * Function to move a screening result from one category to another
    * Used in the Screener Results and User Details UI
    * @param {any} email - username of the user the screening result belongs to
    * @param {any} entryId - the ID of the result you want to move
    * @param {any} moveDirection - from where to where you want to move (e.g. PROBABLE_MATCH_TO_FURTHER_RESEARCH_MATCH)
  */
  moveMatch(email: any, entryId: any, moveDirection: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('/api/v3/remoteBatch/screening/moveMatch?email=' + email + '&entryId=' +
      entryId + '&moveDirection=' + moveDirection, this.httpOptions);
  }

  /**
    * Function to update the note field of a screening result
    * @param {any} email - username of the user the screening result belongs to
    * @param {any} entryId - the ID of the result you want to move
    * @param {any} moveDirection - from where to where you want to move (e.g. PROBABLE_MATCH_TO_FURTHER_RESEARCH_MATCH)
  */
  updateMatchNote(email: any, entryId: any, note: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('/api/v3/remoteBatch/screening/updateNote?email=' + email + '&entryId='
      + entryId + '&note=' + note, this.httpOptions);
  }

  /**
    * Function for adding an event to the event log - for audit trail purposes
    * @param {any} email - which user the event was triggered on
    * @param {any} event - the text of the event
  */
  addEvent(dossier: any, analyst: any, event: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/v2/events', { dossier: dossier, analyst: analyst, event: event }, this.httpOptions);
  }

  /**
    * Function to get the event log for a user
    * @param {any} email - which user we want to get the event log for
  */
  getEvents(dossier: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/events?dossier=' + dossier, this.httpOptions);
  }

  getEventsForAnalyst(analyst: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/events/getEventsForAnalyst?analyst=' + analyst, this.httpOptions);
  }

  getEventsForDossier(dossier: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/events/getEventsForDossier?dossier=' + dossier, this.httpOptions);
  }

  getEventsBetween(from: any, to: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/v2/events/getEventsBetween?from=' + from + '&to=' + to, this.httpOptions);
  }

  getPdfReport(inputHtml: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.exportFormData = new FormData();

    this.exportFormData.append('htmlInput', inputHtml);

    return this.http.post('/api/pdf/report', this.exportFormData, this.httpOptions);
  }

  createPackage(documentPackage: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/v3/public/packages', documentPackage, this.httpOptions);
  }

  getOnboardingUrl(packageId: number) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };
    return this.http.get('/api/v3/public/packages/' + packageId + '/onboard-url', this.httpOptions);
  }
}
