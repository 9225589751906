import { Component, OnInit } from '@angular/core';
import { CallsService } from '../../_services';
import { config } from '../../../assets/configuration';
import { CreateAdminComponent } from './createAdmin/createAdmin.component';
import { EmailServerComponent } from './emailServer/emailServer.component';
import { EmailTemplateComponent } from './emailTemplate/emailTemplate.component';
import { PepSanctionScreenerSettingsComponent } from './pepSanctionScreenerSettings/pepSanctionScreenerSettings.component';
import { MediaScreenerSettingsComponent } from './mediaScreenerSettings/mediaScreenerSettings.component';
import { SearchEngineScreenerSettingsComponent } from './searchEngineScreenerSettings/searchEngineScreenerSettings.component';
import { UserManagementComponent } from './userManagement/userManagement.component';
import { TagManagementComponent } from './tagManagement/tagManagement.component';
import { LegalConfigurationComponent } from './legalConfiguration/legalConfiguration.component';
import { ScreeningRiskSettingsComponent } from './screeningRiskSettings/screeningRiskSettings.component';
import { RiskAnalysisSettingsComponent } from './riskAnalysisSettings/riskAnalysisSettings.component';
import { BrandingSettingsComponent } from './legalConfiguration/brandingSettings/brandingSettings.component';
import { CommonModule } from '@angular/common';

/**
* Implements the Settings page
*/
@Component({
  standalone: true,
  imports: [
    BrandingSettingsComponent,
    CommonModule,
    CreateAdminComponent,
    EmailServerComponent,
    EmailTemplateComponent,
    LegalConfigurationComponent,
    MediaScreenerSettingsComponent,
    PepSanctionScreenerSettingsComponent,
    RiskAnalysisSettingsComponent,
    ScreeningRiskSettingsComponent,
    SearchEngineScreenerSettingsComponent,
    TagManagementComponent,
    UserManagementComponent
  ],
  templateUrl: 'settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  oldPassword = '';
  newPassword = '';
  selectedItem = '';
  response: any = {};
  createAdminDisplay = false;
  emailServerDisplay = false;
  emailTemplateDisplay = false;
  screenerSettingsDisplay = false;
  userManagementDisplay = true;
  legalConfigurationDisplay = false;
  pepSanctionScreenerDisplay = false;
  mediaScreenerDisplay = false;
  searchEngineScreenerDisplay = false;
  screeningRiskSettingsDisplay = false;
  riskAnalysisDisplay = false;
  brandingSettings = false;
  tagManagementDisplay = false;

  mainColor: any = config.mainColor;

  constructor(
    private callsService: CallsService
  ) {}

  ngOnInit() {}

  /**
  * Function for menu navigation
  * @param {String} selectedItem - selected item
  */
  menuClick(selectedItem: string): void {
    this.createAdminDisplay = selectedItem === 'createAdmin';
    this.emailServerDisplay = selectedItem === 'emailServer';
    this.emailTemplateDisplay = selectedItem === 'emailTemplate';
    this.screenerSettingsDisplay = selectedItem === 'screenerSettingsDisplay';
    this.userManagementDisplay = selectedItem === 'userManagement';
    this.tagManagementDisplay = selectedItem === 'tagManagement';
    this.legalConfigurationDisplay = selectedItem === 'legalConfiguration';
    this.mediaScreenerDisplay = selectedItem === 'mediaScreener';
    this.pepSanctionScreenerDisplay = selectedItem === 'pepSanctionScreener';
    this.searchEngineScreenerDisplay = selectedItem === 'searchEngineScreener';
    this.screeningRiskSettingsDisplay = selectedItem === 'screeningRiskSettings';
    this.riskAnalysisDisplay = selectedItem === 'riskAnalysis';
    this.brandingSettings = selectedItem === 'brandingSettings';
    this.emailServerDisplay = selectedItem === 'emailServerSettings';
  }
}
