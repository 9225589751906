<div class="row box" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="col-lg-12">
    <form [formGroup]="disableAddRoleBtn">
      <div style="height: 10px;"></div>
      <ng-select style="width: 100%;" formControlName="companyFrom" [(ngModel)]="e1Instance" class="custom" [items]="entities"
        [loading]="entitiesLoading" bindLabel="name" bindValue="id" appendTo="body" (change)="e1InstanceChanged($event)">
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.name">{{item.name}}</div>
        </ng-template>
      </ng-select>
      <div class="alert alert-danger" [hidden]="e1Instance">This field is required</div> is <ng-select
        style="width: 100%;" formControlName="companyIs" [(ngModel)]="roleType" [items]="roleTypes" [loading]="roleTypesLoading"
        bindLabel="defaultRoleName" [dropdownPosition]="'bottom'" bindValue="id" appendTo="body" (change)="roleTypeChanged()"></ng-select>
      <div class="alert alert-danger" [hidden]="roleType">This field is required</div> <div *ngIf="!showGEOentities">of <ng-select style="width: 100%;"
        [(ngModel)]="e2Instance" class="custom" formControlName="companyOf" [items]="newEntities" [loading]="entitiesLoading" bindLabel="name"
        bindValue="id" appendTo="body"></ng-select></div>
        <div *ngIf="showGEOentities">
        <div style="height: 25px;"></div>
        <ng-select style="width: 100%;" formControlName="companyOf"
        [(ngModel)]="e2Instance" class="custom" [items]="GEOentities" [loading]="entitiesLoading" bindLabel="primaryName"
        bindValue="id" appendTo="body"></ng-select>
        </div>
      <div class="alert alert-danger" [hidden]="e2Instance">This field is required</div>
      <div style="height: 25px;"></div>
    </form>
  </div>
  <div style="height: 20px;"></div>
  <div class="col-lg-12">
    <button class="btn btn-primary" (click)="addRole()" style="background-color: #355B89;border-color: #355B89" [disabled]="!disableAddRoleBtn.valid">Add
      role</button>
  </div>
</div>