<div class="col-md-10 col-md-offset-1" xmlns:height="http://www.w3.org/1999/xhtml">
  <div style="height: 30px"></div>
  <div class="wrapper">
      <nav id="sidebar" class="col-md-4" style="background-color: white;">
          <!-- Sidebar Header -->
          <div class="sidebar-header" style="padding: 20px">
              <h3>
                  <span class="glyphicon glyphicon-cog" style="font-size: 18px"></span> My Account </h3>
          </div>
          <!-- Sidebar Links -->
          <ul class="list-unstyled components" style="padding-left: 20px; padding-right: 20px;padding-bottom: 5px">
              <li>
                <a [style.color]="mainColor" (click)="menuClick('passwordChange')">Password Change</a>
             </li>
             <li>
              <a [style.color]="mainColor" (click)="menuClick('hiddenTags')">Hidden Tags</a>
           </li>
           <li>
            <a [style.color]="mainColor" (click)="menuClick('twofactorDisplay')">2FA</a>

         </li>
          </ul>
      </nav>
      <div id="content" class="col-md-8" style="background-color: white; padding: 20px">
          <div style="height: 40px"></div>
          <app-password-change *ngIf="passwordDisplay"></app-password-change>
          <app-hidden-tags *ngIf="hiddenTagsDisplay"></app-hidden-tags>
          <app-setup-2fa *ngIf="twofactorDisplay" ></app-setup-2fa>
      </div>
  </div>      
</div>
