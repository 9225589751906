import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WebDataService } from '../../../../_services/index';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'comment',
    standalone: true,
    imports: [ FormsModule ],
    templateUrl: './comment.component.html',
    styleUrls: [ './comment.component.css' ]
})

export class CommentComponent implements OnInit {

    reportResults: any = {};
    riskAssessmentNote = '';

    comment$ = new Subject<string>();

    constructor(private dataService: WebDataService) {
        this.comment$.pipe(
            debounceTime(250),
            distinctUntilChanged()
        ).subscribe(() => this.addRiskAssessmentNote());
    }

    @Output() riskAssessmentEvent = new EventEmitter();

    ngOnInit() {}

    addRiskAssessmentNote() {
        this.reportResults = this.dataService.reportResults.getValue();

        if (this.reportResults === null) {
            this.reportResults = {};
            this.reportResults.riskAssessmentNote = '';
        }

        this.reportResults.riskAssessmentNote = this.riskAssessmentNote;
        this.dataService.setCurrentReportResults(this.reportResults);
        this.riskAssessmentEvent.emit();
    }
}
