<div class="col-md-12">
    <tabset type="pills" #staticTabs class="main-topic-tabs">
        <tab heading="Manage Users">
            <div class="row">
                <app-manage-users *ngIf="staticTabs.tabs[0]?.active"></app-manage-users>
            </div>
        </tab>
        <tab heading="Bulk Ownership">
            <div class="row">
                <app-bulk-ownership *ngIf="staticTabs.tabs[1]?.active"></app-bulk-ownership>
            </div>
        </tab>
    </tabset>
</div>
