<div class="" xmlns:height="http://www.w3.org/1999/xhtml">
    <div style="height: 30px"></div>
    <div class="wrapper">
        <nav id="sidebar" class="col-md-2" style="background-color: white; padding: 0px;">
            <!-- Sidebar Header -->
            <div class="sidebar-header" style="padding: 20px">
                <h3>
                    <span class="glyphicon glyphicon-cog" style="font-size: 18px"></span> Settings </h3>
            </div>
            <!-- Sidebar Links -->
            <ul class="list-unstyled components" style="padding-left: 20px; padding-right: 20px;padding-bottom: 5px">
                <li>Screener Settings: </li>
                <ul style="list-style: none;padding-left: 15px;">
                    <li>
                        <a [style.color]="mainColor" (click)="menuClick('pepSanctionScreener')">PEP/Sanction
                            Screener</a>
                    </li>
                    <li>
                        <a [style.color]="mainColor" (click)="menuClick('mediaScreener')">Media Screener</a>
                    </li>
                    <li>
                        <a [style.color]="mainColor" (click)="menuClick('searchEngineScreener')">Search Engine
                            Screener</a>
                    </li>
                    <li>
                        <a [style.color]="mainColor" (click)="menuClick('screeningRiskSettings')">Screening Risk
                            Settings</a>
                    </li>
                </ul>
                <li style="color: black;">User Management: </li>
                <ul style="list-style: none;padding-left: 15px;">
                    <li><a [style.color]="mainColor" (click)="menuClick('userManagement')">User Management</a></li>
                    <li><a [style.color]="mainColor" (click)="menuClick('createAdmin')">Create Admin</a></li>
                </ul>
                <li><a [style.color]="mainColor" (click)="menuClick('tagManagement')">Tag Management</a></li>
                <li><a [style.color]="mainColor" (click)="menuClick('legalConfiguration')">Legal Configuration</a></li>
                <li><a [style.color]="mainColor" (click)="menuClick('riskAnalysis')">Risk Analysis Settings</a></li>
                <li><a [style.color]="mainColor" (click)="menuClick('emailServerSettings')">Email Server Settings</a></li>
                <li><a [style.color]="mainColor" (click)="menuClick('brandingSettings')">Branding Settings</a></li>
            </ul>
        </nav>
        <div id="content" class="col-md-10" style="background-color: white; padding: 0px; border: 0px;">
            <div style="height: 40px"></div>
            <app-create-admin *ngIf="createAdminDisplay"></app-create-admin>
            <app-email-server *ngIf="emailServerDisplay"></app-email-server>
            <app-email-template *ngIf="emailTemplateDisplay"></app-email-template>
            <app-pep-sanction-screener *ngIf="pepSanctionScreenerDisplay"></app-pep-sanction-screener>
            <app-media-screener *ngIf="mediaScreenerDisplay"></app-media-screener>
            <app-search-engine-screener *ngIf="searchEngineScreenerDisplay"></app-search-engine-screener>
            <app-user-management *ngIf="userManagementDisplay"></app-user-management>
            <app-tag-management *ngIf="tagManagementDisplay"></app-tag-management>
            <app-legal-configuration *ngIf="legalConfigurationDisplay"></app-legal-configuration>
            <app-screening-risk-settings *ngIf="screeningRiskSettingsDisplay"></app-screening-risk-settings>
            <app-risk-analysis *ngIf="riskAnalysisDisplay"></app-risk-analysis>
            <app-branding-settings *ngIf="brandingSettings"></app-branding-settings>
        </div>
    </div>
</div>
