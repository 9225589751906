import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';

/**
* Implements password changing directive
*/
@Component({
    selector: 'app-email-template',
    standalone: true,
    imports: [ FormsModule ],
    templateUrl: 'emailTemplate.component.html',
    styleUrls: [ './emailTemplate.component.css' ]
})

export class EmailTemplateComponent {

  userName = '';
  email = '';
  response: any = {};
  template: any = {};
  registrationTemplate: any = null;
  whitelistTemplate: any = null;
  blacklistTemplate: any = null;
  moreInformationTemplate: any = null;
  registrationTemplateFilled: any = false;
  whitelistTemplateFilled: any = false;
  blacklistTemplateFilled: any = false;
  moreInformationTemplateFilled: any = false;

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

    /**
    * Function used for processing registration email template
    * @param {FileList} files - input file
    */
    processRegistrationTemplate(files: FileList) {
        if (files.item(0).type === 'text/html') {
            this.registrationTemplateFilled = true;
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
              this.registrationTemplate = fileReader.result;
            };

            fileReader.readAsText(files.item(0));
        } else {
          this.alertService.showError('All templates must be HTML.');
        }
    }

    /**
    * Function used for processing whitelist email template
    * @param {FileList} files - input file
    */
    processWhitelistTemplate(files: FileList) {
        if (files.item(0).type === 'text/html') {
            this.whitelistTemplateFilled = true;
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
              this.whitelistTemplate = fileReader.result;
            };

            fileReader.readAsText(files.item(0));
        } else {
          this.alertService.showError('All templates must be HTML.');
        }
    }

    /**
    * Function used for processing blacklist email template
    * @param {FileList} files - input file
    */
    processBlacklistTemplate(files: FileList) {
        if (files.item(0).type === 'text/html') {
            this.blacklistTemplateFilled = true;
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
              this.blacklistTemplate = fileReader.result;
            };

            fileReader.readAsText(files.item(0));
        } else {
          this.alertService.showError('All templates must be HTML.');
        }
    }

    /**
    * Function used for processing more information email template
    * @param {FileList} files - input file
    */
    processMoreInfoTemplate(files: FileList) {
        if (files.item(0).type === 'text/html') {
            this.moreInformationTemplateFilled = true;
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
              this.moreInformationTemplate = fileReader.result;
            };

            fileReader.readAsText(files.item(0));
        } else {
          this.alertService.showError('All templates must be HTML.');
        }
    }

  /**
  * Function used for changing the password
  */
  async saveTemplate(form: any) {
    // tslint:disable-next-line:max-line-length
    if (this.registrationTemplateFilled || this.whitelistTemplateFilled || this.blacklistTemplateFilled || this.moreInformationTemplateFilled) {
      if (this.registrationTemplateFilled) {
      }

      if (this.whitelistTemplateFilled) {}

      if (this.blacklistTemplateFilled) {}

      if (this.moreInformationTemplateFilled) {}
    } else {
      this.alertService.showError('Please upload at least one file.');
    }
  }
}
