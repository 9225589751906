import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { BrandingSetup } from '../_models/branding.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class BrandingService implements OnDestroy {
  private _data: BehaviorSubject<BrandingSetup> = new BehaviorSubject(null);
  public brandingSetup: Observable<BrandingSetup> = this._data.asObservable();

  private subs: Subscription[] = [];

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getBranding() {
    const sub = this.apiService.get('branding').subscribe((res) => {
      this._data.next(res.data);
      this.processBranding(res.data);
    });
    this.subs.push(sub);
  }

  setBranding(brandingSetup: BrandingSetup) {
    return this.apiService.put('branding', brandingSetup);
  }

  processBranding(b: BrandingSetup): void {
    document.documentElement.style
      .setProperty('--mainColor', b.mainColor);
  }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }
}
