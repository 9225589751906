import { Component, OnInit } from '@angular/core';
import { WebAlertService, WebCallsService } from '../../../_services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'credit-alert',
  standalone: true,
  imports: [ DecimalPipe, FormsModule ],
  templateUrl: 'creditAlert.component.html',
})

export class CreditAlertComponent implements OnInit {

  packages: any = [];
  credits: any;
  packageId: any;
  isTrialUser = false;

  username = '';

  constructor(
      public bsModalRef: BsModalRef,
      private webAlertService: WebAlertService,
      private webCallsService: WebCallsService
  ) {}

  ngOnInit() {
    this.getCreditPackages();

    this.webCallsService.isTrialUser().subscribe(
        data => {
          if (data !== null) {
            if (data === true) {
              this.isTrialUser = true;
            } else {
              this.isTrialUser = false;
            }
          }
        },
        error => {
        }
    );
  }

  endTrial() {
      if (window.confirm('By clicking "OK", you acknowledge that your trial will be stopped and your credit card will be billed according to the plan you chose upon subscription. To check your subscription, go to "My Subscriptions".')) {
          this.webCallsService.endTrial().subscribe(
              data => {
                  if (data !== null) {
                      if (data === true) {
                          this.bsModalRef.hide();
                          this.webAlertService.success('You have successfully switched to a regular subscription. You might have to refresh the page for the changes to take effect. Go to <b>My Subscriptions</b> to see which subscription you currently have as well as your amount of credits.');
                      } else {
                          this.bsModalRef.hide();
                          this.webAlertService.error('Could not switch subscription. Please review your payment details in <b>Account</b> and try again.');
                      }
                  }
              },
              error => {
              }
          );
      }
  }

  getCreditPackages() {
    this.webCallsService.getcreditPackages()
      .subscribe(
          data => {
              this.packages = [];
              this.packages = data;
          },
          error => {
          }
      );
  }

  checkout() {
      this.username = localStorage.getItem('username');
      this.webCallsService.startStripeCheckoutSessionOnetime(this.packageId, this.username).subscribe(
        data => {},
        error => {
            stripe.redirectToCheckout({
              sessionId: error.error.text
          }).then(result => {
          });
        }
      );
  }
}
