import { CommonModule, DatePipe, JsonPipe, UpperCasePipe } from '@angular/common';
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { Observable } from 'rxjs';
import { ReplaceSpacePipe } from '../../../../replaceSpace.pipe';
import { TruncateTextPipe } from '../../../../truncate-text.pipe';
import { WebDataService} from '../../../../_services/index';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'report-results',
    standalone: true,
    imports: [ AccordionModule, CommonModule, DatePipe, JsonPipe, ReplaceSpacePipe, TruncateTextPipe, UpperCasePipe ],
    templateUrl: 'reportResults.component.html',
    styleUrls: [ './reportResults.component.css' ]
})

export class ReportResultsComponent implements OnInit {

  reportResults: any = {};
  results: any = {};
  pepHits: any = {};
  sanctionHits: any = {};
  topics: any = [];
  masterTopics: any = [];
  noMore = false;
  articlesWithTopic: any = [];

  currentTopics: any = [];

  private eventsSubscription: any;
  private riskEventsSubscription: any;

  @Input() reportResultsEvent: Observable<void>;
  @Input() riskEvent: Observable<void>;
  @Output() reportChangedEvent = new EventEmitter();
  @Output() articlesChangedEvent = new EventEmitter();

  constructor(private dataService: WebDataService) {}

  ngOnInit() {
    this.eventsSubscription = this.reportResultsEvent.subscribe(() => {
      this.getResultsArticles();
    });

    this.riskEventsSubscription = this.riskEvent.subscribe(() => {
      this.getResults();
    });
  }

  getResultsArticles() {
    this.masterTopics = [];

    this.dataService.report_results.subscribe(
      message => {
        this.topics = [];
        this.reportResults = message;

        if (this.reportResults.articles === undefined) {
          this.reportResults.articles = [];
        }

        if (this.reportResults.sanctionHits === undefined) {
          this.reportResults.sanctionHits = [];
        }

        if (this.reportResults.pepHits === undefined) {
          this.reportResults.pepHits = [];
        }

        this.articlesChangedEvent.emit();

        for (let i = 0; i < this.reportResults.articles.length; i++) {
          this.topics.push({ 'topic': this.reportResults.articles[i].topic, 'topicNote': this.reportResults.articles[i].topicNote, 'masterTopicLabel': this.reportResults.articles[i].masterTopicLabel });
          this.masterTopics.push(this.reportResults.articles[i].masterTopicLabel);
        }

        this.topics = this.removeDuplicates(this.topics);
        this.masterTopics = this.masterTopics.filter(this.onlyUnique);
      });
  }

  getResults() {
    this.masterTopics = [];

    this.dataService.report_results.subscribe(
      message => {
        this.topics = [];
        this.reportResults = message;

        if (this.reportResults.articles === undefined) {
          this.reportResults.articles = [];
        }

        if (this.reportResults.sanctionHits === undefined) {
          this.reportResults.sanctionHits = [];
        }

        if (this.reportResults.pepHits === undefined) {
          this.reportResults.pepHits = [];
        }

        for (let i = 0; i < this.reportResults.articles.length; i++) {
          this.topics.push({ 'topic': this.reportResults.articles[i].topic, 'topicNote': this.reportResults.articles[i].topicNote, 'masterTopicLabel': this.reportResults.articles[i].masterTopicLabel });
          this.masterTopics.push(this.reportResults.articles[i].masterTopicLabel);
        }

        this.topics = this.removeDuplicates(this.topics);
        this.masterTopics = this.masterTopics.filter(this.onlyUnique);
      });
  }

  removeDuplicates(arr) {
    return arr.reduce((p, c) => {
      const key = [c.topic, c.topicNote].join('|');
      if (p.temp.indexOf(key) === -1) {
        p.out.push(c);
        p.temp.push(key);
      }
      return p;
    }, { temp: [], out: [] }).out;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  removeFromReport(result: any) {
    let count = 0;
    let masterCount = 0;

    this.currentTopics = [];

    // remove article from report results
    for (let j = 0; j < this.reportResults.articles.length; j++) {
      if (this.reportResults.articles[j].id === result.id) {
        this.reportResults.articles.splice(j, 1);
      }
    }

    for (let k = 0; k < this.reportResults.articles.length; k++) {
      if (this.reportResults.articles[k].topic === result.topic) {
        count += 1;
      }
    }

    if (count === 0) {
      this.topics.splice(this.topics.indexOf(result.topic), 1);
    }

    for (let l = 0; l < this.topics.length; l++) {
      if (this.topics[l].masterTopicLabel === result.masterTopicLabel) {
        masterCount += 1;
      }
    }

    if (masterCount === 0) {
      this.masterTopics.splice(this.masterTopics.indexOf(result.masterTopicLabel), 1);
    }

    this.dataService.setCurrentReportResults(this.reportResults);

    this.reportChangedEvent.emit();
    this.articlesChangedEvent.emit();

    // add article to regular results
    this.results = this.dataService.results.getValue();

    for (let j = 0; j < this.results.length; j++) {
      this.currentTopics.push(this.results[j].topicLabel);
    }

    if (this.currentTopics.indexOf(result.topic) === -1) {
      this.results.unshift({ 'topicLabel': result.topic, 'masterTopicLabel': result.masterTopicLabel, 'media': [result] });
    } else {
      for (let i = 0; i < this.results.length; i++) {
        if (this.results[i].topicLabel === result.topic) {
          this.results[i].media.unshift(result);
          this.dataService.setCurrentResults(this.results);
        }
      }
    }
  }

  removeTopicFromReport(topic: any, masterTopicLabel: any) {
    let count = 0;
    this.articlesWithTopic = [];
    this.currentTopics = [];

    for (let i = 0; i < this.reportResults.articles.length; i++) {
      if (this.reportResults.articles[i].topic === topic) {
        this.articlesWithTopic.push(this.reportResults.articles[i]);
      }
    }

    this.reportResults.articles = this.reportResults.articles.filter(item => item.topic !== topic);

    for (let j = 0; j < this.reportResults.articles.length; j++) {
      if (this.reportResults.articles[j].masterTopicLabel === masterTopicLabel) {
        count += 1;
      }
    }

    if (count === 0) {
      this.masterTopics.splice(this.masterTopics.indexOf(masterTopicLabel), 1);
    }

    this.dataService.setCurrentReportResults(this.reportResults);

    this.reportChangedEvent.emit();
    this.articlesChangedEvent.emit();

    this.results = this.dataService.results.getValue();

    for (let k = 0; k < this.results.length; k++) {
      this.currentTopics.push(this.results[k].topicLabel);
    }

    if (this.currentTopics.indexOf(topic) === -1) {
      // tslint:disable-next-line:max-line-length
      this.results.unshift({ 'topicLabel': topic, 'masterTopicLabel': this.articlesWithTopic[0].masterTopicLabel, 'media': this.articlesWithTopic });
    } else {
      for (let l = 0; l < this.results.length; l++) {
        if (this.results[l].topicLabel === topic) {
          for (let m = 0; m < this.articlesWithTopic.length; m++) {
            this.results[l].media.unshift(this.articlesWithTopic[m]);
          }
        }
      }
    }

    this.dataService.setCurrentResults(this.results);
  }


  removeFromReportPEP(result: any) {
    for (let j = 0; j < this.reportResults.pepHits.length; j++) {
      if (this.reportResults.pepHits[j].entityName === result.entityName) {
        this.reportResults.pepHits.splice(j, 1);
      }
    }

    this.dataService.setCurrentReportResults(this.reportResults);
    this.pepHits = this.dataService.pepHits.getValue();
    this.pepHits.unshift(result);
    this.dataService.setCurrentPEPHits(this.pepHits);
    this.dataService.setTruePS('PEP-true');
  }

  removeFromReportAllPEP() {
    this.pepHits = this.dataService.pepHits.getValue();

    for (let i = 0; i < this.reportResults.pepHits.length; i++) {
      this.pepHits.push(this.reportResults.pepHits[i]);
    }

    this.dataService.setCurrentPEPHits(this.pepHits);
    this.reportResults.pepHits.length = 0;
    this.dataService.setCurrentReportResults(this.reportResults);
    this.dataService.setTruePS('PEP-true');
  }

  removeFromReportSanction(result: any) {
    for (let j = 0; j < this.reportResults.sanctionHits.length; j++) {
      if (this.reportResults.sanctionHits[j].entityName === result.entityName) {
        this.reportResults.sanctionHits.splice(j, 1);
      }
    }

    this.dataService.setCurrentReportResults(this.reportResults);
    this.sanctionHits = this.dataService.sanctionHits.getValue();
    this.sanctionHits.unshift(result);
    this.dataService.setCurrentSanctionHits(this.sanctionHits);
    this.dataService.setTruePS('SANCTION-true');
  }

  removeFromReportAllSanction() {
    this.sanctionHits = this.dataService.sanctionHits.getValue();

    for (let i = 0; i < this.reportResults.sanctionHits.length; i++) {
      this.sanctionHits.push(this.reportResults.sanctionHits[i]);
    }

    this.dataService.setCurrentSanctionHits(this.sanctionHits);
    this.reportResults.sanctionHits.length = 0;
    this.dataService.setCurrentReportResults(this.reportResults);
    this.dataService.setTruePS('SANCTION-true');
  }
}
