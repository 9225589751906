import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../_services/index';

/**
 * Implements guard preventing unauthorized user access to the admin pages
*/
@Injectable({
  providedIn: 'root'
})
export class EnterpriseGuard  {

  response: any = {};

  constructor(
    private router: Router,
    private callsService: CallsService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  canActivate(routeSnapshot: ActivatedRouteSnapshot) {
    if (localStorage.getItem('isAdmin') === '1' || this.callsService.admin.getValue() === true) {
      return true;
    } else {
      return false;
    }
  }
}
