import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TruncateTextPipe } from '../../../../truncate-text.pipe';
import { SearchService, WebCallsService, WebDataService } from '../../../../_services/index';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'risk',
    standalone: true,
    imports: [ AccordionModule, CommonModule, DatePipe, FormsModule, JsonPipe, TruncateTextPipe ],
    templateUrl: 'risk.component.html',
    styleUrls: [ './risk.component.css' ]
})

export class RiskComponent implements OnInit {

  encodedQuery = '';
  searchQuery = '';
  results: any = {};
  pepResults: any = {};
  sanctionResults: any = {};
  listings: any = {};
  pep = false;
  sanction = false;
  pepHits: any = {};
  sanctionHits: any = {};
  isFullSearch = false;
  pepHitsNo = 0;
  sanctionHitsNo = 0;
  reportResults: any = {};
  pepHitsNoHelper: any = {};
  sanctionHitsNoHelper: any = {};
  sourcesMetadata: any = [];

  @Output() riskEvent = new EventEmitter();
  @Output() searchQueryChangeEvent = new EventEmitter();
  @Output() newSearchEvent = new EventEmitter();

  constructor(
    private callsService: WebCallsService,
    private dataService: WebDataService,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.pepHits = '';
    this.sanctionHits = '';
    this.listings = {};
    this.pep = false;
    this.sanction = false;
    this.dataService.currentQuery.subscribe(message => this.searchQuery = message);
    this.encodedQuery = encodeURIComponent(this.searchQuery);

    this.callsService.getSources().subscribe(
      data => this.sourcesMetadata = data,
      error => {}
    );

    this.dataService.isFullSearch.subscribe(message => this.isFullSearch = message);

    if (this.isFullSearch === false) {
      if (this.encodedQuery !== 'null' && this.encodedQuery !== '') {
          this.searchService.entityPEPDetails(this.encodedQuery).subscribe(data => {
            this.pepHits = data;
            this.pepHits = this.pepHits.sanctionedEntities;

            if (this.pepHits.length > 0) {
              this.processPEPDetails();
            } else {
              this.dataService.setPEPHitsNo(0);
            }
          });

          this.searchService.entitySanctionDetails(this.encodedQuery).subscribe(data => {
            this.sanctionHits = data;
            this.sanctionHits = this.sanctionHits.sanctionedEntities;

            if (this.sanctionHits.length > 0) {
              this.processSanctionDetails();
            } else {
              this.dataService.setSanctionHitsNo(0);
            }
          });
        }
    }

    if (this.isFullSearch === null) {
      // tslint:disable-next-line:max-line-length
      // means we got redirected here from somewhere else -> take query from params (but only if it is simple search without date of birth and country)
      this.route.queryParams.subscribe(params => {
        if (params['dateOfBirth'] === undefined && params['country'] === undefined) {
          this.searchQuery = params['firstName'] + ' ' + params['lastName'];
          this.encodedQuery = encodeURIComponent(this.searchQuery);
          this.searchService.entityPEPDetails(this.encodedQuery).subscribe(data => {
            this.pepHits = data;
            this.pepHits = this.pepHits.sanctionedEntities;

            if (this.pepHits.length > 0) {
              this.processPEPDetails();
            } else {
              this.dataService.setPEPHitsNo(0);
            }
          });

          this.searchService.entitySanctionDetails(this.encodedQuery).subscribe(data => {
            this.sanctionHits = data;
            this.sanctionHits = this.sanctionHits.sanctionedEntities;

            if (this.sanctionHits.length > 0) {
              this.processSanctionDetails();
            } else {
              this.dataService.setSanctionHitsNo(0);
            }
          });
        }
      });
    }
  }

  processPEPDetails() {
    let searchQuery = '';
    this.dataService.currentQuery.subscribe(message => searchQuery = message);
    this.pep = true;
    this.dataService.setCurrentPEPHits(this.pepHits);
    this.dataService.setPEPHitsNo(this.pepHits.length);

    for (let i = 0; i < this.pepHits.length; i++) {
      for (let j = 0; j < this.sourcesMetadata.length; j++) {
        if (this.sourcesMetadata[j].source === this.pepHits[i].source) {
          this.pepHits[i].source = this.sourcesMetadata[j].displayName;
        }
      }
    }

    this.pepHitsNo = this.pepHits.length;
    this.pepHits.sort((x, y) => x.entityName === searchQuery ? -1 : y.entityName === searchQuery ? 1 : 0);
  }

  processSanctionDetails() {
    let searchQuery = '';
    this.dataService.currentQuery.subscribe(message => searchQuery = message);
    this.sanction = true;
    this.dataService.setCurrentSanctionHits(this.sanctionHits);
    this.dataService.setSanctionHitsNo(this.sanctionHits.length);

    for (let i = 0; i < this.sanctionHits.length; i++) {
      for (let j = 0; j < this.sourcesMetadata.length; j++) {
        if (this.sourcesMetadata[j].source === this.sanctionHits[i].source) {
          this.sanctionHits[i].source = this.sourcesMetadata[j].displayName;
        }
      }
    }

    this.sanctionHitsNo = this.sanctionHits.length;
    this.sanctionHits.sort((x, y) => x.entityName === searchQuery ? -1 : y.entityName === searchQuery ? 1 : 0);
  }

  reset() {
    this.pep = false;
    this.sanction = false;
  }

  receiveNewSearchEvent() {
    this.pep = false;
    this.sanction = false;
    this.dataService.currentQuery.subscribe(message => this.searchQuery = message);
    this.encodedQuery = encodeURIComponent(this.searchQuery);
    this.searchService.entityPEPDetails(this.encodedQuery).subscribe(data => {
      this.pepHits = data;
      this.pepHits = this.pepHits.sanctionedEntities;

      if (this.pepHits.length > 0) {
        this.processPEPDetails();
      }
    });

    this.searchService.entitySanctionDetails(this.encodedQuery).subscribe(data => {
      this.sanctionHits = data;
      this.sanctionHits = this.sanctionHits.sanctionedEntities;

      if (this.sanctionHits.length > 0) {
        this.processSanctionDetails();
      }
    });
  }

  receiveLoadEvent($event) {
    this.pep = false;
    this.sanction = false;
    this.dataService.currentQuery.subscribe(message => this.searchQuery = message);
    this.dataService.setfirstName($event.firstName);
    this.dataService.setlastName($event.lastName);
    this.searchQueryChangeEvent.emit({ firstName: $event.firstName, lastName: $event.lastName });
    this.encodedQuery = encodeURIComponent(this.searchQuery);
    this.searchService.entityPEPDetails(this.encodedQuery).subscribe(data => {
      this.pepHits = data;
      this.pepHits = this.pepHits.sanctionedEntities;
      if (this.pepHits.length > 0) {
        this.processPEPDetails();
      }
    });

    this.searchService.entitySanctionDetails(this.encodedQuery).subscribe(data => {
      this.sanctionHits = data;
      this.sanctionHits = this.sanctionHits.sanctionedEntities;

      if (this.sanctionHits.length > 0) {
        this.processSanctionDetails();
      }
    });

    this.newSearchEvent.emit();
  }

  addNotePEP(item, note) {
    item.note = '';

    this.dataService.currentPepHits.subscribe(
      message => {
        this.pepHits = message;

        for (let i = 0; i < this.pepHits.length; i++) {
          if (this.pepHits[i].description === item.description) {
            this.pepHits[i].note = note;
          }
        }
      }
    );

    this.dataService.setCurrentPEPHits(this.pepHits);
  }

  addNoteSanction(item, note) {
    this.dataService.currentSanctionHits.subscribe(
      message => {
        this.sanctionHits = message;

        for (let i = 0; i < this.sanctionHits.length; i++) {
          if (this.sanctionHits[i].description === item.description) {
            this.sanctionHits[i].note = note;
          }
        }
      }
    );

    this.dataService.setCurrentSanctionHits(this.sanctionHits);
  }

  addToReportPEP(hit, status) {
    this.dataService.currentPepHits.subscribe(
      message => {
        this.pepHitsNoHelper = message;
        this.pepHitsNo = this.pepHitsNoHelper.length;
      }
    );

    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = {};
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    if (this.reportResults.pepHits === undefined) {
      this.reportResults.pepHits = [];
    }

    if (status === 'HIT') {
      hit.status = 'HIT';
    }

    if (status === 'FURTHER RESEARCH') {
      hit.status = 'FURTHER RESEARCH';
    }

    if (status === 'FALSE POSITIVE') {
      hit.status = 'FALSE POSITIVE';
    }

    this.reportResults.pepHits.push(hit);
    this.discardResultPEP(hit.entityName);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.riskEvent.emit();
  }

  addToReportSanction(hit, status) {
    this.dataService.currentSanctionHits.subscribe(
      message => {
        this.sanctionHitsNoHelper = message;
        this.sanctionHitsNo = this.sanctionHitsNoHelper.length;
      }
    );

    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = {};
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    if (this.reportResults.sanctionHits === undefined) {
      this.reportResults.sanctionHits = [];
    }

    if (status === 'HIT') {
      hit.status = 'HIT';
    }

    if (status === 'FURTHER RESEARCH') {
      hit.status = 'FURTHER RESEARCH';
    }

    if (status === 'FALSE POSITIVE') {
      hit.status = 'FALSE POSITIVE';
    }

    this.reportResults.sanctionHits.push(hit);
    this.discardResultSanction(hit.entityName);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.riskEvent.emit();
  }

  addAllToReportPEP(status) {
    this.reportResults = this.dataService.reportResults.getValue();
    if (this.reportResults === null) {
      this.reportResults = {};
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    if (status === 'HIT') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'HIT';
      }
    }

    if (status === 'FURTHER RESEARCH') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'FURTHER RESEARCH';
      }
    }

    if (status === 'FALSE POSITIVE') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'FALSE POSITIVE';
      }
    }

    if (this.reportResults.pepHits === undefined) {
      this.reportResults.pepHits = {};
    }

    for (let j = 0; j < this.pepHits.length; j++) {
      this.reportResults.pepHits[j] = this.pepHits[j];
    }

    this.dataService.setCurrentReportResults(this.reportResults);
    this.riskEvent.emit();
    this.discardallResultPEP();
  }

  addAllToReportSanction(status) {
    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = {};
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    if (status === 'HIT') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'HIT';
      }
    }

    if (status === 'FURTHER RESEARCH') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'FURTHER RESEARCH';
      }
    }

    if (status === 'FALSE POSITIVE') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'FALSE POSITIVE';
      }
    }

    if (this.reportResults.sanctionHits === undefined) {
      this.reportResults.sanctionHits = {};
    }

    for (let j = 0; j < this.sanctionHits.length; j++) {
      this.reportResults.sanctionHits[j] = this.sanctionHits[j];
    }

    this.dataService.setCurrentReportResults(this.reportResults);
    this.riskEvent.emit();
    this.discardallResultSanction();
  }

  discardallResultPEP() {
    this.pepHits.length = 0;
    this.pepHitsNo = 0;
  }

  discardallResultSanction() {
    this.sanctionHits.length = 0;
    this.sanctionHitsNo = 0;
  }

  discardResultPEP(entityName: any) {
    for (let i = 0; i < this.pepHits.length; i++) {
        if (this.pepHits[i].entityName === entityName) {
          this.pepHits.splice(i, 1);

          if (this.pepHits.length === 0) {
            this.discardallResultPEP();
          }
        }
    }

    this.dataService.setCurrentPEPHits(this.pepHits);
    this.pepHitsNo = this.pepHits.length;
  }

  discardResultSanction(entityName: any) {
    for (let i = 0; i < this.sanctionHits.length; i++) {
        if (this.sanctionHits[i].entityName === entityName) {
          this.sanctionHits.splice(i, 1);

          if (this.sanctionHits.length === 0) {
            this.discardallResultSanction();
          }
        }
    }

    this.dataService.setCurrentSanctionHits(this.sanctionHits);
    this.sanctionHitsNo = this.sanctionHits.length;
  }
}
