<div class="col-md-12">
    <div style="height: 20px"></div>
    <h3 class="text-center">Screening Risk Settings</h3><br>
    <div style="height: 15px"></div>
    <table class="table table-responsive table-hover" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Risk Type</th>
                <th>Risk Factor</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let screeningRisk of screeningRisks">
                <td>{{screeningRisk.riskType}}</td>
                <td>{{screeningRisk.riskFactor}}</td>
                <td><button class="btn btn-warning btn-yellow" style="padding: 6px;" (click)="edit(editTemplate,screeningRisk)">Edit &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>  
</div>

<ng-template #editTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Adjust Risk Factor</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <label>
            Risk Factor:
        </label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentTopic.riskFactor" />
        <div style="height: 10px;"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success btn-save-information" (click)="save()">Save</button>
    </div>
</ng-template>