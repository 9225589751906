import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../../api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentManagerService implements OnDestroy {
  private _data: BehaviorSubject<any> = new BehaviorSubject({});
  public packages: Observable<any> = this._data.asObservable();

  private subs: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  getPackages() {
    const sub = this.apiService.get('public/packages').subscribe((res) => {
      this._data.next(res);
    });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }
}
