import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { WebDataService } from '../../_services';

@Component({
  standalone: true,
  imports: [ CommonModule, FormsModule ],
  templateUrl: 'menu.component.html',
  styleUrls: [ './menu.component.css' ]
})

export class MenuComponent implements OnInit {
  country: any;
  dateOfBirth: any;
  firstName = '';
  fulltextSearchQuery = '';
  lastName = '';

  constructor(
    private webDataService: WebDataService,
    private router: Router
  ) {}

  ngOnInit() {}

  goToFullReportBuilder(firstName: any, lastName: any, dateOfBirth: any, country: any) {
    this.webDataService.setQuery(firstName + ' ' + lastName);

    if (dateOfBirth === null || country === null) {
      this.webDataService.setIsFullSearch(false);
      // tslint:disable-next-line:max-line-length
      this.router.navigate(['/fullReport'], { queryParams: { firstName: firstName, lastName: lastName, dateOfBirth: dateOfBirth, country: country } });
    } else {
      this.webDataService.setIsFullSearch(true);
      // tslint:disable-next-line:max-line-length
      this.router.navigate(['/fullReport'], { queryParams: { firstName: firstName, lastName: lastName, dateOfBirth: dateOfBirth, country: country } });
    }
  }

  goToFullTextSearch(fulltextSeachQuery: string) {
    this.router.navigate(['/search'], { queryParams: { q: fulltextSeachQuery } });
  }
}
