import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService, CallsService, KYBService, ShareUserNameService, SharedService } from './_services';
import { config } from '../assets/configuration';
import { EnterpriseGuard } from './_guards/enterprise.guard';
import 'webrtc-adapter';
import { BrandingService } from './branding/branding.service';
import { BrandingSetup } from './_models/branding.model';
import { SettingService } from './setting/setting.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from './_services/config.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  standalone: true,
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>About</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p>Version: d584c308 2024-12-01T13:21:07+00:00</p>
      <p>Release: d584c308</p>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button type='button' class='btn btn-default btn-noBorder' (click)='bsModalRef.hide()'>Close</button>
    </div>
  `
})

export class ShowAboutComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit() {}
}

/**
* Implements the main app module
*/
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app',
  standalone: true,
  imports: [ CommonModule, RouterModule, TranslateModule ],
  templateUrl: 'app.component.html',
  styleUrls: ['./mobile.css']
})

export class AppComponent implements OnInit {

  _observable: Observable<any> = null;

  subscriptions: Subscription[] = [];

  showAboutModal: BsModalRef;

  private apiUrl = '/api/all';
  data: any = {};
  response: any = {};
  admin: any = false;
  adminsList: any = {};
  footerText: string = config.footerText;
  web: string = config.web;
  supportEmail: string = config.supportEmail;
  companyName: string = config.companyName;
  logoPath: string = config.logoPath;
  facebook: string = config.facebook;
  linkedin: string = config.linkedin;
  telegram: string = config.telegram;
  twitter: string = config.twitter;
  mainColor: string = config.mainColor;
  loggedIn = false;
  showFooterText = false;
  isOnMobile: boolean;
  displayResearchTab = true;
  userName = '';

  isOnboarding = false;

  userPermissions: any = [];

  constructor(
    private authenticationService: AuthenticationService,
    private callsService: CallsService,
    private guard: EnterpriseGuard,
    private kybService: KYBService,
    private modalService: BsModalService,
    private brandingService: BrandingService,
    private settingService: SettingService,
    private translate: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private configService: ConfigService,
    private sharedService: SharedService,
    private shareUserNameService: ShareUserNameService
  ) {
      if (configService.get('onboardingLanguage') !== undefined) {
        this.translate.setDefaultLang(configService.get('onboardingLanguage'));
      } else {
        this.translate.setDefaultLang('en');
      }

      if (configService.get('displayResearchTab') !== undefined) {
        this.displayResearchTab = configService.get('displayResearchTab');
      }

      this.sharedService.changeEmitted$.subscribe(data => this.userPermissions = data);

      if (window.location.href.includes('onboard')) {
        this.isOnboarding = true;
      }
      this.brandingService.getBranding();
      this.getBranding();
  }

  /**
  * Function detecting if a user is logged in
  * @returns {boolean} is user logged in?
  */
  isLoggedIn() {
    const token = localStorage.getItem('auth_token');
    const loginToken = localStorage.getItem('login_token');

    this.admin = true;

    if (localStorage.getItem('isAdmin') === '0') {
      this.admin = false;
    }

    if (token && loginToken) {
      this.loggedIn = true;
      return true;
    } else {
      this.loggedIn = false;
      return false;
    }
  }

  /**
  * Function detecting if a user is admin
  * @returns {boolean} is user admin?
  */
  isAdmin() {
    this.callsService.isAdmin.subscribe(
      message => {
        if (message === true) {
          this.admin = true;
          return true;
        } else {
          this.admin = false;
          return false;
        }
      }
    );
  }

  ngOnInit() {
    this.userName = localStorage.getItem('username');
    this.shareUserNameService.changeEmitted$.subscribe(data => {
      this.userName = data;
    });
    this.isOnMobile = this.deviceDetectorService.isMobile();

    this.sharedService.changeEmitted$.subscribe(data => {
      if (data[0] === 'UserPermissions' && data[1] === true) {
        if (localStorage.getItem('userPermissions') !== null) {
          this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
        }
      }
    });

    this.checkLogin();
    this.isAdmin();
  }

  public getBranding(): void {

    this.brandingService.brandingSetup.subscribe((setup: BrandingSetup) => {
      if (!setup) {
        return;
      }

      this.footerText = setup.footerText;
      this.web = setup.companyUrl;
      this.supportEmail = setup.supportEmail;
      this.companyName = setup.companyName;
      this.logoPath = setup.websiteLogo;
      this.facebook = setup.facebookUrl;
      this.linkedin = setup.linkedinUrl;
      this.telegram = setup.telegramUrl;
      this.twitter = setup.twitterUrl;
      this.mainColor = setup.mainColor;
    });
  }

  /**
   * Checking whether a user is logged in
  */
  checkLogin() {
    // tslint:disable-next-line:max-line-length
    if (localStorage.getItem('login_token') && localStorage.getItem('login_token').length > 0 && localStorage.getItem('expires_at') && localStorage.getItem('expires_at').length > 0) {
      const validUntilTimestamp = JSON.parse(localStorage.getItem('expires_at'));
      const currentTimestamp = Math.floor(Date.now());

      this.loggedIn = true;

      if (currentTimestamp >= validUntilTimestamp) {
        this.loggedIn = false;
        this.authenticationService.logout();
      }

      this.getBranding();
    } else {
      this.loggedIn = false;
      this.authenticationService.logout();
    }
  }

  showAbout() {
    const initialState = {};

    this.showAboutModal = this.modalService.show(ShowAboutComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  downloadTerms() {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = './././assets/KYC3_Terms.pdf';
    hiddenElement.download = 'KYC3_Terms.pdf';
    hiddenElement.click();
  }

  downloadPolicy() {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = './././assets/KYC3_Policy.pdf';
    hiddenElement.download = 'KYC3_Policy.pdf';
    hiddenElement.click();
  }
}
