<div class="modal-header">
  <h4 class="modal-title pull-left">Save State</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
      <div style="height: 20px"></div>
      <img class="text-center" src="../../../../../assets/images/icons/PNG/save_circle.png" width="100" />
      <div style="height: 15px"></div>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-9">
        <form #saveForm="ngForm" novalidate>
            <div class="form-group text-center">
              <br>
                <input class="form-control input-md" name="fileName" #fileNamee="ngModel" type="text" [(ngModel)]="fileName" placeholder="Save as *" style="width: 60%;padding: 10px;outline: none;border: none;border-radius: 0px;background-color: #F8F8F8;margin-bottom: 5px;" required>
            </div>
          </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
        <span style="color: #c00000;text-align: center;"><b>Warning:</b> the PEP/Sanction Hits currently in the report will not be saved. The checks will be rerun once you load this save.</span><br>
        <p *ngIf="success" style="color: green">Save successful.</p>
    </div>
    <div class="col-md-2">
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-lg-12 text-center">
        <button class="btn btn-success" style="border-radius: 0px; background-color: #F8CC20; color: white;border: none;" (click)="saveState()" [disabled]="!saveForm.valid">SAVE</button><button class="btn btn-danger" style="border: none;color:white;background-color: #98999A;border-radius: 0px;" (click)="bsModalRef.hide()">CANCEL</button>
    </div>
  </div>
</div>
