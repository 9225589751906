import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { KYBService, SharedService } from '../_services';

/**
 * Implements guard preventing unauthorized user access
*/
@Injectable({
  providedIn: 'root'
})
export class UserGroupGuard  {

    constructor(
        private kybService: KYBService,
        private sharedService: SharedService,
        private router: Router
    ) {}

    userPermissions: any = {};

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.kybService.getUserPermissions().pipe(
            map(value => {
                this.userPermissions = value.data.permissions;
                localStorage.setItem('userPermissions', JSON.stringify(this.userPermissions));
                this.sharedService.emitChange(['UserPermissions', true]);
                if (route.routeConfig.path === 'dashboard') {
                    if (this.userPermissions.indexOf('DashboardAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'settings') {
                    if (this.userPermissions.indexOf('SettingsAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'enterprise') {
                    if (this.userPermissions.indexOf('EntityListAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/login']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'reporting') {
                    if (this.userPermissions.indexOf('ReportsAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'screening') {
                    if (this.userPermissions.indexOf('ScreeningAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'my-account') {
                    if (this.userPermissions.indexOf('AccountSettingsAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path === 'research') {
                    if (this.userPermissions.indexOf('ResearchAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else if (route.routeConfig.path.indexOf('detail') !== -1) {
                    if (this.userPermissions.indexOf('EntityDetailAccess') !== -1) {
                        return true;
                    } else {
                        window.alert('You are not authorized to access this URL.');
                        this.router.navigate(['/enterprise']);
                        return false;
                    }
                } else {
                    return true;
                }
            })
        );
    }
}
