import { Component, OnInit } from '@angular/core';
import { AlertService, KYBService } from '../../../../_services';
import * as $ from 'jquery';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    selector: 'app-bulk-ownership',
    standalone: true,
    imports: [ CommonModule, FormsModule, NgSelectModule ],
    templateUrl: 'bulkOwnership.component.html',
    styleUrls: ['./bulkOwnership.component.css']
})

export class BulkOwnershipComponent implements OnInit {

  entitiesDataTable: any;
  entities: any = [];
  entityResponse: any = {};
  transferFrom = '';
  transferTo = '';
  users: any = [];
  owner = '';

  displayTable = false;
  usersFiltered: any = [];

  constructor(
    private alertService: AlertService,
    private kybService: KYBService
  ) {}

  ngOnInit() {
    this.kybService.getAllUsers().subscribe(
      data => {
        this.users = data.data;
      },
      error => {}
    );

    this.initEntitiesTable();
  }

  initEntitiesTable() {
    const entitiesTable: any = $('.table-entities');

    if ($.fn.dataTable.isDataTable('.table-entities')) {
      this.entitiesDataTable = entitiesTable.DataTable();
    } else {
      this.entitiesDataTable = entitiesTable.DataTable(
        {
            'oLanguage': { 'sEmptyTable': 'No users found.' },
            'orderCellsTop': true,
            'columnDefs': [
                { 'orderable': false, 'targets': 0 },
                { 'orderable': false, 'targets': 1 },
                { 'orderable': false, 'targets': 2 }
            ],
            'order': [],
            'paging': false,
            'lengthChange': false,
            'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
            'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
            'select': { style: 'os' }
        }
      );
    }
  }

  displayToggle() {
    if (this.transferFrom) {
      this.usersFiltered = this.users;
    } else {
      this.transferTo = '';
      this.usersFiltered = [];
    }

    for (let i = 0; i < this.usersFiltered.length; i++) {
      if (this.usersFiltered[i].identifier === this.transferFrom) {
        this.owner = this.usersFiltered[i].email;
        this.usersFiltered.splice(i, 1);
      }
    }

    this.kybService.getAllEntitiesPaged(null, null, null, null, null, null, null, null, this.owner, true).subscribe(
      data => {
        this.entityResponse = data;
        this.entities = this.entityResponse.data.content;
      },
      error => {}
    );

    this.displayTable = this.usersFiltered.length ? true : false;
  }

  transfer() {
    if (window.confirm('Warning: You are about to transfer ownership of ' +
    this.entities.length + ' entities from ' + this.transferFrom + ' to ' +
    this.transferTo + '. After this ' + this.transferFrom +
    ' will have NO entities. This operation can not be easily undone. Are you sure?')) {
      this.kybService.ownershipTransfer({'currentUserId': localStorage.getItem('identifier'),
      'fromUserId': this.transferFrom, 'toUserId': this.transferTo}).subscribe(
        data => {
          this.alertService.showSuccess('Ownership transfer successful.');
          this.displayTable = false;
          this.transferFrom = '';
          this.transferTo = '';
        },
        error => {}
      );
    }
  }
}
