import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'prettyState', standalone: true})
export class PrettyStatePipe implements PipeTransform {
  transform(value: any): string {
    const name = value.split('-');
    // tslint:disable-next-line:max-line-length
    const finalName = 'Name: \"' + name[4] + '\" | Search Query: \"' + decodeURIComponent(name[1]) + '\" | Date: \"' + name[2] + ' ' + name[3] + '\"';

    return finalName;
  }
}
