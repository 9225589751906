import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '../../_services';
import { Papa } from 'ngx-papaparse';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
  standalone: true,
  imports: [ CommonModule, FormsModule, RouterModule ],
  templateUrl: 'batch.component.html',
  styleUrls: ['./batch.component.css']
})

export class BatchComponent implements OnInit {

  error: string;

  model: any = {};
  loading = false;
  batchFileFilled = false;
  batchFileName: string;
  filenameBatchFile: string;
  batchFile: File = null;
  csv = '';

  constructor(
    private alertService: AlertService,
    private papa: Papa
  ) {}

  ngOnInit() {}

  /**
  * Function that detects if batch file is filled
  */
  isBatchFileFilled() {
    this.batchFileFilled = true;
  }

  /**
  * Function that opens batch file input window
  */
  openBatchFileInput() {
    (document.getElementById('batchFile') as HTMLInputElement).click();
  }

  /**
  * Function that shows batch file name
  */
  showBatchFile(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.batchFile = file;
      const reader: FileReader = new FileReader();

      reader.readAsText(this.batchFile);

      reader.onload = (e) => {
        this.csv = reader.result as string;

        this.papa.parse(this.csv, {
          header: true,
          chunk: (result) => {
              let i, j, temparray;
              const chunk = 100000;
              for (i = 0, j = result.data.length; i < j; i += chunk) {
                  temparray = result.data.slice(i, i + chunk);
              }
          }
      });
      };
      }

      (document.getElementById('filenameBatchFile') as HTMLDivElement).innerHTML = this.batchFile.name;
  }

  /**
  * Function that parses CSV
  */
  parseCsv(fileContent: string) {
    const lines = fileContent.split('\n');

    for (let i = 0; i < lines.length; i++) {
      const columns = lines[i].split(',');

      this.model.firstName = columns[0];
      this.model.lastName = columns[1];
      this.model.dateOfBirth = columns[2];
      this.model.address = columns[3];
      this.model.something = columns[4];
      this.model.somethingElse = columns[5];
      this.model = {};
    }
  }

  /**
  * Function that sends parsed information to database
  */
  async process(form: NgForm) {
    this.loading = true;

    if (this.batchFileFilled) {
      this.parseCsv(this.csv);
      this.loading = false;
    } else {
      this.loading = false;
      this.alertService.showError('Please upload a batch file in CSV format.');
    }
  }
}
