<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
    <div style="height: 30px"></div>
    <div class="table-responsive">
    <table style="width: 100%;">
        <tr>
            <td>
                <label for="user-selector"><b>Select user:</b></label><br>
                <div style="height: 15px;"></div>
                <select style="color: black;" id="user-selector" class="ng-control" name="user-selector" [(ngModel)]="selectedUser" (change)="changeSelectedUser(selectedUser)">
                    <option *ngFor="let user of users; index as i" 
                        [value]="i">
                    {{user.email}} - {{user.formData.firstName}} {{user.formData.lastName}}
                    </option>
                </select>
            </td>
            <td>
                <i (click)="previousUser()" class="fa fa-chevron-left" aria-hidden="true"></i>&#160;&#160;&#160;
                <i (click)="nextUser()" class="fa fa-chevron-right" aria-hidden="true"></i>
            </td>
        </tr>
        <tr>
            <td></td>
            <td>
                <i (click)="setIdentityDocumentsStatus('red')" class="fa fa-ban crimson" aria-hidden="true" title="Reject"></i>&#160;&#160;
                <i (click)="setIdentityDocumentsStatus('yellow')" class="fa fa-arrow-up yellow" aria-hidden="true" title="Further Research"></i>&#160;&#160;
                <i (click)="setIdentityDocumentsStatus('green')" class="fa fa-check green" aria-hidden="true" title="Approve"></i>
            </td>
        </tr>
        <tr>
            <td>
                <div style="height: 30px;"></div>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <div *ngIf="safeExampleIdFront != null && safePassportBack != null">
                    <b>Example ID Front</b><br>
                    <div style="height: 10px;"></div>
                    <div *ngIf="idsFront.length == 1">
                            <img *ngIf="safeExampleIdFront" [src]="safeExampleIdFront" style="height:250px"> 
                        </div>
                        <div *ngIf="idsFront.length > 1">
                            <img id="example-id-front" [src]="safeExampleIdFront" style="height:250px">
                            <br>
                            <div>
                                <div style="height: 10px;"></div>
                                <i (click)="previousIdFront()" class="fa fa-chevron-left" aria-hidden="true"></i>&#160;&#160;&#160;
                                <i (click)="nextIdFront()" class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </div>
                </div>
                <div *ngIf="safePassportBack == null">
                    <b>Example Passport</b><br>
                    <div style="height: 10px;"></div>
                    <div *ngIf="passports.length == 1">
                        <img *ngIf="safeExamplePassport" [src]="safeExamplePassport" style="height:250px"> 
                    </div>
                    <div *ngIf="passports.length > 1">
                        <img id="example-passport" [src]="safeExamplePassport" style="height:250px">
                        <br>
                        <div>
                            <div style="height: 10px;"></div>
                            <i (click)="previousPassport()" class="fa fa-chevron-left" aria-hidden="true"></i>&#160;&#160;&#160;
                            <i (click)="nextPassport()" class="fa fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div [hidden]="safePassportFront == null">
                    <b>User's ID / Passport Front</b><br>
                    <div style="height: 15px;"></div>
                    <img [hidden]="safePassportFront == null" id="passport-front" [src]="safePassportFront" style="height:250px"> 
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div style="height: 60px;"></div>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <div *ngIf="safeExampleIdBack != null && safePassportBack != null">
                    <b>Example ID Back</b><br>
                    <div style="height: 15px;"></div>
                    <div *ngIf="idsBack.length == 1">
                            <img *ngIf="safeExampleIdBack" [src]="safeExampleIdBack" style="height:250px"> 
                        </div>
                        <div *ngIf="idsBack.length > 1">
                            <img id="example-id-back" [src]="safeExampleIdBack" style="height:250px">
                            <br>
                            <div>
                                <div style="height: 10px;"></div>
                                <i (click)="previousIdBack()" class="fa fa-chevron-left" aria-hidden="true"></i>&#160;&#160;&#160;
                                <i (click)="nextIdBack()" class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </div>
                </div>
            </td>
            <td>
                <div [hidden]="safePassportBack == null">
                    <b>User's ID Back</b><br>
                    <div style="height: 15px;"></div>
                    <img [hidden]="safePassportBack == null" id="passport-back" [src]="safePassportBack" style="height:250px"> 
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div style="height: 60px;"></div>
            </td>
            <td></td>
        </tr>
    </table>
      <div style="height: 40px"></div>     
    </div>
</div>

