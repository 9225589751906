import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandingSetup } from './_models/branding.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public brandingSetup: Observable<BrandingSetup>;
  private apiUrl = '/api/v3/';
  httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = this.getHttpOptions();
  }

  get(url: string): Observable<any> {

    this.httpOptions = {};

    if (localStorage.getItem('auth_token')) {
      this.httpOptions = {
        headers: new HttpHeaders()
          .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))

          .append('Content-Type', 'application/json')
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders()
          .append('Content-Type', 'application/json')
      };
    }

    return this.http.get(`${this.apiUrl}${url}`, this.httpOptions);
  }

  post(url: string, body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}${url}`, body, this.httpOptions);
  }

  put(url: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(`${this.apiUrl}${url}`, body, httpOptions);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}${url}`, this.httpOptions);
  }

  private getHttpOptions(): any {
    return {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('token'))),
    };
  }
}
